import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { environments } from "../../environments/environment";
import { getToken } from "../../firebase";

export type Me = {
    id: number;
    name: string;
};

export const meApi = createApi({
    reducerPath: "meApi",
    baseQuery: fetchBaseQuery({
        baseUrl: environments.API_URL,
        prepareHeaders: async (headers, api) => {
            const token = await getToken();

            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
        },
    }),
    tagTypes: ["me"],
    endpoints: (builder) => ({
        get: builder.query<Me, {}>({
            query: () => "/me",
            providesTags: (result) => [{ type: "me", id: "LIST" }],
            forceRefetch: () => true,
        }),
        setOnboardingDisplayed: builder.mutation<any, {}>({
            query: () => ({
                url: `commands/execute/SetOnboardingDisplayed/`,
                method: "POST",
                body: {},
            }),
            invalidatesTags: [{ type: "me", id: "LIST" }],
        }),
        updateProfile: builder.mutation<any, { displayName: string }>({
            query: ({ displayName }) => ({
                url: `commands/execute/UpdateProfile/`,
                method: "POST",
                body: { displayName },
            }),
            invalidatesTags: [{ type: "me", id: "LIST" }],
        }),
    }),
});

export const { useGetQuery: useGetMeQuery, useUpdateProfileMutation, useSetOnboardingDisplayedMutation } = meApi;
