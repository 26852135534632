import "./MenuUpdateForm.scss";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { LoadingOrb } from "../../components/LoadingOrb";
import { SomethingWentWrong } from "../../components/SomethingWentWrong";
import { useDeleteRecipeMutation, useGetRecipesForDictQuery } from "../../store/data/recipeSlice";
import { useDeleteMenuMutation, useUpdateMenuInitialQuery, useUpdateMenuMutation } from "../../store/data/menuSlice";
import { MenuForm } from "../../components/forms/MenuForm";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { IonButton, IonIcon } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { ActionWithConfirmation } from "../../components/ActionWithConfirmation";

interface ContainerProps extends RouteComponentProps<{ id: string }> {}

export const MenuUpdateForm: React.FC<ContainerProps> = ({ match }) => {
    const id = parseInt(match.params.id, 10);
    const initial = useUpdateMenuInitialQuery({ id });
    const [update, result] = useUpdateMenuMutation();
    useGetRecipesForDictQuery();

    if (result.isLoading) {
        return <LoadingOrb text="Zapisuję..." />;
    }
    if (result.error && result.error?.status !== 400) {
        return <SomethingWentWrong text="Coś poszło nie tak :(" />;
    }
    if (result.isSuccess) {
        return <Redirect to="/user/menus" />;
    }

    return (
        <DataLoadingWardenForQuery query={initial}>
            {() => (
                <MenuForm
                    data={result.originalArgs?.body || initial.data}
                    submitForm={(data) => update({ id, body: data })}
                    errors={result.error?.data || {}}
                />
            )}
        </DataLoadingWardenForQuery>
    );
};

export const MenuUpdatePageButtons: React.FC<RouteComponentProps<{ id: string }>> = ({
    match: {
        params: { id },
    },
}) => {
    const [remove, result] = useDeleteMenuMutation();

    const history = useHistory();
    useEffect(() => {
        result.isSuccess && history.replace("/user/menus");
    }, [result.isSuccess, history]);

    return (
        <ActionWithConfirmation
            message={`Czy na pewno chcesz usunąć jadłospis?`}
            header="Usuwanie jadłospisu"
            confirmedAction={() => remove({ id: id })}
        />
    );
};
