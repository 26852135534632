import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { assignQueryParameters, IQueryParameters } from "./oDataHelpers";

export const PAGE_SIZE = 50;

export const filtersSlice = createSlice({
    name: "filters",
    initialState: {
        pickerFilters: {
            data: undefined,
            queryParameters: assignQueryParameters(
                {},
                {
                    pageSize: PAGE_SIZE,
                    order: "desc",
                    orderBy: "modifiedDate",
                }
            ),
        },
        recipeListFilters: {
            data: undefined,
            queryParameters: assignQueryParameters(
                {},
                {
                    pageSize: PAGE_SIZE,
                    order: "desc",
                    orderBy: "modifiedDate",
                }
            ),
        },
    },
    reducers: {
        setPickerFilters: (state, action: PayloadAction<any>) => {
            state.pickerFilters = action.payload;
        },
        setRecipeListFilters: (state, action: PayloadAction<any>) => {
            state.recipeListFilters = action.payload;
        },
    },
});

export const { setPickerFilters, setRecipeListFilters } = filtersSlice.actions;

export const selectPickerFilters: (state: { filters: { pickerFilters: { data: any; queryParameters: any } } }) => any = (state) =>
    state.filters.pickerFilters;
export const selectRecipeListFilters: (state: { filters: { recipeListFilters: { data: any; queryParameters: any } } }) => any = (
    state
) => state.filters.recipeListFilters;

export const filtersReducer = filtersSlice.reducer;
