import { createListenerMiddleware, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { unitsApi } from "./data/unitsSlice";
import { tagsApi } from "./data/tagsSlice";
import { ingredientsApi } from "./data/ingredientsSlice";
import { ingredientCategoriesApi } from "./data/ingredientCategoriesSlice";
import { recipeCategoriesApi } from "./data/recipeCategoriesSlice";
import { recipesApi } from "./data/recipeSlice";
import { meApi } from "./data/meSlice";

export type AuthState = {
    user: User;
    isLoading: boolean;
    isDestroyed: boolean;
    isAuthorized: boolean;
};
export type UserRole = "anonymous" | "moderator" | "admin" | "user" | "all";
export type User = {
    name: string;
    role: UserRole;
    id?: number;
    onboardingDisplayed?: string;
    subscriptionDaysLeft: number;
    subscriptionDays: number;
};
const userInitial: User = {
    name: "",
    role: "anonymous",
    id: undefined,
    subscriptionDaysLeft: 0,
    subscriptionDays: 1,
};

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: { ...userInitial },
        isLoading: true,
        isDestroyed: true,
        isAuthorized: false,
    },
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.isLoading = state.isDestroyed;
            state.isAuthorized = true;
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.isLoading = false;
            state.isDestroyed = false;
        },
        destroyUser: (state) => {
            state.user = { ...userInitial };
            state.isLoading = false;
            state.isDestroyed = true;
            state.isAuthorized = true;
        },
    },
});

export const { setToken, destroyUser } = authSlice.actions;

export const selectUserId: (state: { auth: AuthState }) => number | undefined = (state) => state.auth.user.id;
export const selectUser: (state: { auth: AuthState }) => User | undefined = (state) => state.auth.user;
export const selectAuth: (state: { auth: AuthState }) => AuthState = (state) => state.auth;

export const hasOwnerPermission = (userId) => (state) =>
    userId && (state.auth.user.id === userId || state.auth.user.role === "admin" || state.auth.user.role === "moderator");
export const hasAdminPermission = (state) => state.auth.user.role === "admin" || state.auth.user.role === "moderator";
export const authReducer = authSlice.reducer;

export const authListener = createListenerMiddleware();
authListener.startListening({
    actionCreator: setToken,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch((tagsApi.endpoints as any).getTagsForDict.initiate());
        listenerApi.dispatch((ingredientsApi.endpoints as any).getIngredientsForDict.initiate());
        listenerApi.dispatch((recipesApi.endpoints as any).getRecipesForDict.initiate());
        listenerApi.dispatch((unitsApi.endpoints as any).getUnitsForDict.initiate());
        listenerApi.dispatch((recipeCategoriesApi.endpoints as any).getRecipeCategoriesForDict.initiate());
        listenerApi.dispatch((ingredientCategoriesApi.endpoints as any).getIngredientCategoriesForDict.initiate());
        listenerApi.dispatch((meApi.endpoints.get as any).initiate());
    },
});

authListener.startListening({
    actionCreator: destroyUser,
    effect: async (action, listenerApi) => {},
});

authListener.startListening({
    matcher: ((action) => action.type === "meApi/executeQuery/fulfilled") as any,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(authSlice.actions.setUser((action as any).payload));
    },
});
