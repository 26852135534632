import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useState, useEffect, useMemo } from "react";
import { IQueryParameters, toQueryString } from "./oDataHelpers";
import _ from "lodash";

const calculateMaxPages = (total: number, size: number) => {
    return Math.ceil(total / size);
};

export const isValidNotEmptyArray = (array: any[]): boolean => {
    return !!(array && array?.length && array?.length > 0);
};

export interface IListQueryResponse {
    result: any[];
    totalCount: number;
    page: number;
    size: number;
}

const useInfiniteScroll = (useGetDataListQuery: UseQuery<any>, queryParameters: IQueryParameters) => {
    const [localPage, setLocalPage] = useState(1);
    const [combinedData, setCombinedData] = useState<any>([]);
    queryParameters.page = localPage;
    const [parameters, setParameters] = useState<string>(toQueryString(queryParameters));
    const queryResponse = useGetDataListQuery(parameters);
    const { result = [], totalCount = 0 } = (queryResponse?.data as IListQueryResponse) || {};

    useEffect(() => {
        if (isValidNotEmptyArray(result)) {
            if (localPage === 1) setCombinedData(result);
            else if (localPage > 1) {
                setCombinedData((previousData) => {
                    //TODO: refactor things regarding infinite scroll
                    return _.uniq([...previousData, ...result], (r1, r2) => r1.id === r2.id);
                });
            }
        }
    }, [localPage, result]);

    const maxPages = useMemo<number>(() => {
        return calculateMaxPages(totalCount, queryParameters.pageSize!);
    }, [totalCount, queryParameters]);

    useEffect(() => {
        if (parameters !== toQueryString(queryParameters)) {
            setCombinedData([]);
            setLocalPage(1);
            queryParameters.page = localPage;
            setParameters(toQueryString(queryParameters));
        }
    }, [localPage, parameters, queryParameters]);

    const refresh = () => {
        setLocalPage(1);
        setCombinedData([]);
        queryParameters.page = localPage;
        setParameters(toQueryString(queryParameters));
    };

    const readMore = () => {
        if (localPage <= maxPages) {
            queryParameters.page = localPage + 1;
            setLocalPage((page) => page + 1);
            setParameters(toQueryString(queryParameters));
        }
    };

    return {
        combinedData,
        localPage,
        readMore,
        refresh,
        isLoading: queryResponse?.isLoading || queryResponse?.isFetching,
        isFetching: queryResponse?.isFetching,
        totalCount,
        canLoadMore: localPage < maxPages,
    };
};

export default useInfiniteScroll;
