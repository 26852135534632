import "./MenuPreview.scss";
import { RecipeCard } from "../RecipeCard";
import { SetDocumentTitle } from "../SetDocumentTitle";
import { FlexContainer } from "../FlexContainer";

type MenuDay = { name: string; date: string; recipes: any[] };
export type Menu = { id: number; name: string; days: MenuDay[] };

export const MenuPreview: React.FC<Menu & { linkFactory: (recipeId, menuI, numberOfEaters) => string }> = (menu) => {
    return (
        <div className="menu-preview-component">
            <SetDocumentTitle title={menu.name} />
            {menu.days?.map((d) => (
                <DayContainer
                    key={d.date + d.name}
                    {...d}
                    menuId={menu.id}
                    linkFactory={menu.linkFactory}
                />
            ))}
        </div>
    );
};

const DayContainer: React.FC<MenuDay & { menuId: number; linkFactory: (recipeId, menuId, numberOfEaters) => string }> = (
    props
) => (
    <div className="day-container">
        <div className="tagline">{props.name}</div>
        <FlexContainer fakeChildClassName="recipe-card-component">
            {props.recipes.map((r: any, index) => {
                const chips = [
                    { label: "Czas", value: `${r.overallTime} min` },
                    { label: "Porcje", value: `${r.numberOfEaters} os.` },
                ];

                return (
                    <RecipeCard
                        key={index}
                        {...r}
                        chips={chips}
                        routerLink={props.linkFactory(r.id, props.menuId, r.numberOfEaters)}
                        parentName={r.slotName}
                    />
                );
            })}
        </FlexContainer>
    </div>
);
