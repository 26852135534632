import "./ShoppingList.scss";
import { useParams } from "react-router-dom";
import { IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { useGetShoppingListByIdQuery } from "../../store/data/publicSlice";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { RoundAmount } from "../../components/RoundAmount";

interface ContainerProps {}

export const ShoppingList: React.FC<ContainerProps> = () => {
    const menuId = (useParams() as any).id;

    const shoppingList = useGetShoppingListByIdQuery({ menuId });

    return (
        <div className="shopping-list-component">
            <DataLoadingWardenForQuery query={shoppingList}>
                {() => {
                    const groupedIngredients = shoppingList.data.ingredients.reduce((p, c) => {
                        p[c.categoryName] = p[c.categoryName] || [];
                        p[c.categoryName].push(c);
                        return p;
                    }, {});
                    const categories = Object.keys(groupedIngredients);
                    return (
                        <IonList>
                            {categories.map((c) => (
                                <>
                                    <IonListHeader>
                                        <IonLabel>{c}</IonLabel>
                                    </IonListHeader>
                                    {groupedIngredients[c].map((i) => (
                                        <IonItem>
                                            <IonLabel>
                                                {i.name} <RoundAmount amount={i.amount} /> {i.unitName}
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </>
                            ))}
                        </IonList>
                    );
                }}
            </DataLoadingWardenForQuery>
        </div>
    );
};
