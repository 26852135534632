import { IonButton, IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonList, IonSearchbar } from "@ionic/react";
import { add, pencilOutline, trashOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { useDeleteIngredientMutation, useGetIngredientsQuery } from "../../store/data/ingredientsSlice";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { FilterParameters, IQueryParameters, toQueryString } from "../../store/oDataHelpers";
import { debounce } from "lodash";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { useDeleteUnitMutation } from "../../store/data/unitsSlice";
import { ActionWithConfirmation } from "../../components/ActionWithConfirmation";
import { RedirectActionButton } from "../../components/previews/RedirectActionButton";

class ContainerProps {}

export const IngredientList: React.FC<ContainerProps> = () => {
    const onSubmit = (data: any) => {
        let f: FilterParameters[] = [];
        if (data.name) f.push(new FilterParameters("name", "contains", data.name));

        let qp: IQueryParameters = {};
        qp.filters = f;
        setFilter(toQueryString(qp));
    };
    const debouncedSubmitHandler = debounce(onSubmit, 500);
    const [remove, result] = useDeleteIngredientMutation();

    const [filter, setFilter] = useState("");
    const ingredients = useGetIngredientsQuery(filter);
    const { handleSubmit, control } = useForm({
        defaultValues: {
            name: "",
        },
    });

    return (
        <>
            <IonItem>
                <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <IonSearchbar
                            inputMode="search"
                            value={value}
                            placeholder="Szukaj..."
                            onIonInput={(e: any) => {
                                onChange(e.target.value!);
                                handleSubmit(debouncedSubmitHandler)();
                            }}
                        />
                    )}
                />
            </IonItem>
            <DataLoadingWardenForQuery query={ingredients}>
                {() => (
                    <IonList className="menu-list-component">
                        {ingredients.data.result.map((x) => (
                            <IonItem key={x.id}>
                                <IonLabel>{x.name}</IonLabel>
                                <RedirectActionButton
                                    text="Edytuj"
                                    to={`/dictionaries/ingredients/update/${x.id}`}
                                    icon={pencilOutline}
                                />
                                <ActionWithConfirmation
                                    message={`Czy na pewno chcesz usunąć składnik ${x.name}?`}
                                    header="Usuwanie składnika"
                                    confirmedAction={() => remove({ id: x.id })}
                                />
                            </IonItem>
                        ))}
                    </IonList>
                )}
            </DataLoadingWardenForQuery>
            <IonFab
                slot="fixed"
                vertical="bottom"
                horizontal="end"
            >
                <Link to="/dictionaries/ingredients/add">
                    <IonFabButton>
                        <IonIcon icon={add}></IonIcon>
                    </IonFabButton>
                </Link>
            </IonFab>
        </>
    );
};
