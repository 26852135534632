import "./Chip.scss";
import classNames from "classnames";

interface ContainerProps {
    label?: string;
    className?: string;
    value: React.ReactNode;
    onClick?: () => void;
    valueStyle?: "normal" | "strong";
}

export const Chip: React.FC<ContainerProps> = ({
                                                   label, value,
                                                   className, onClick,
                                                   valueStyle,
                                               }) => {
    const classes = classNames("chip-component", className, {
        "chip-component--clickable": !!onClick,
    });
    valueStyle = valueStyle || "strong";
    return (
        <div
            className={classes}
            onClick={onClick}
        >
            <p>
                {label && <span>{label}: </span>}
                {valueStyle !== "normal" && <strong>{value}</strong>}
                {valueStyle === "normal" && <>{value}</>}
            </p>
        </div>
    );
};
