import "./Login.scss";
import { logInWithEmailAndPassword, logInWithGoogle } from "../../firebase";
import { IonButton, IonIcon, IonList, IonRouterLink, IonText, useIonRouter } from "@ionic/react";
import { logoGoogle } from "ionicons/icons";
import { environments } from "../../environments/environment";
import { useForm } from "react-hook-form";
import { ControlledInput } from "../../components/ControlledInput";
import { useState } from "react";
import { Paths } from "../routes";

interface ContainerProps {}

export const Login: React.FC<ContainerProps> = () => {
    const router = useIonRouter();
    const [error, setError] = useState<string | undefined>(undefined);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: { email: "", password: "" } });
    return (
        <div className="login-component">
            <form
                noValidate={true}
                id="login-form"
                onSubmit={handleSubmit(async (data) => {
                    const error = await logInWithEmailAndPassword(data.email, data.password);

                    if (error) {
                        setError(error);
                    } else {
                        router.push(Paths.root, "root");
                    }
                })}
            >
                <IonList>
                    <ControlledInput
                        name="email"
                        label="E-mail"
                        register={register}
                        validationRules={{ required: true }}
                        errors={errors}
                        placeholder="abc@gmail.com"
                    />
                    <ControlledInput
                        name="password"
                        label="Hasło"
                        register={register}
                        inputType="password"
                        validationRules={{ required: true }}
                        errors={errors}
                        placeholder="TajneHasło1234"
                    />
                    {!!error && (
                        <IonText
                            color="danger"
                            style={{ fontSize: "0.7em" }}
                        >
                            {error}
                        </IonText>
                    )}
                    <IonButton
                        expand="full"
                        type="submit"
                    >
                        Zaloguj
                    </IonButton>
                    <IonButton
                        expand="full"
                        onClick={async () => {
                            const error = await logInWithGoogle();

                            if (error) {
                                setError(error);
                            }
                        }}
                    >
                        Zaloguj przez Google
                        <IonIcon
                            slot="start"
                            icon={logoGoogle}
                        ></IonIcon>
                    </IonButton>
                    <div>
                        Nie masz konta? <IonRouterLink routerLink="/register">Zarejestruj się</IonRouterLink>
                    </div>
                    <div>
                        Zapomniałeś hasło? <IonRouterLink routerLink="/password-reset">Zresetuj je tutaj.</IonRouterLink>
                    </div>
                </IonList>
            </form>
        </div>
    );
};
