import { useHistory } from "react-router";
import { IonButton, IonIcon } from "@ionic/react";
import { pencilOutline } from "ionicons/icons";

export const RedirectActionButton: React.FC<{ to: string; icon: string; text: string }> = ({ to, icon, text }) => {
    const history = useHistory();
    return (
        <IonButton
            onClick={() => history.push(to)}
            slot="end"
            fill="clear"
        >
            <IonIcon icon={pencilOutline}></IonIcon> {text}
        </IonButton>
    );
};
