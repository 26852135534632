import "./StartPage.scss";
import { useSelector } from "react-redux";
import { selectUser, selectUserId } from "../../store/authSlice";
import { FlexContainer } from "../../components/FlexContainer";
import { RecipeCard } from "../../components/RecipeCard";
import { MenuCard } from "../../components/MenuCard";
import { IonButton, IonCard, IonIcon } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { useGetRecipesQuery } from "../../store/data/recipeSlice";
import { FilterParameters, IQueryParameters, toQueryString } from "../../store/oDataHelpers";
import { getIsLiked, getMainImageId } from "../../to_remove_helpers";
import { useGetMenusQuery } from "../../store/data/menuSlice";
import moment from "moment";
import { Paths } from "../routes";

const LatestMenus: React.FC<{}> = () => {
    const today = moment().startOf("day").toISOString();
    let qp: IQueryParameters = {};
    qp.pageSize = 50;
    qp.orderBy = "startDate";
    qp.order = "asc";
    qp.filters = [new FilterParameters("endDate", "gt", today)];
    const query = useGetMenusQuery(toQueryString(qp));
    return (
        <div className="menus">
            <h4>Twoje aktualne jadłospisy</h4>
            <DataLoadingWardenForQuery query={query}>
                {() => (
                    <FlexContainer fakeChildClassName="menu-card-component">
                        {!!query.data?.result?.length &&
                            query.data.result.map((m) => (
                                <MenuCard
                                    key={m.id}
                                    name={m.name}
                                    routerLink={`/user/menus/preview/${m.id}`}
                                    dateFrom={m.startDate}
                                    dateTo={m.endDate}
                                />
                            ))}
                        {query.data?.result?.length === 0 && <h5>Nie masz zaplanowanych jadłospisów</h5>}
                        <div className="menu-card-component add-menu">
                            <IonCard routerLink={Paths.menuAdd}>
                                <div className="container">
                                    <IonButton
                                        expand="full"
                                        fill="clear"
                                    >
                                        <IonIcon
                                            slot="start"
                                            icon={addOutline}
                                        />
                                        Dodaj jadłospis
                                    </IonButton>
                                </div>
                            </IonCard>
                        </div>
                    </FlexContainer>
                )}
            </DataLoadingWardenForQuery>
        </div>
    );
};

const RecipesInReview: React.FC<{ count: number }> = ({ count }) => {
    const userId = useSelector(selectUserId);
    let qp: IQueryParameters = {};
    qp.pageSize = count;
    qp.orderBy = "ModifiedDate";
    qp.order = "desc";
    qp.filters = [new FilterParameters("author/id", "eq", userId), new FilterParameters("status", "ne", "'Private'")];
    const query = useGetRecipesQuery(toQueryString(qp));
    return (
        <div className="recipes-inreview">
            <h4>Twoje udostępnione przepisy</h4>
            <DataLoadingWardenForQuery query={query}>
                {() => (
                    <FlexContainer fakeChildClassName="recipe-card-component">
                        {!!query.data?.result?.length &&
                            query.data.result.map((r) => {
                                const imageUrl = getMainImageId(r);
                                const chips = [
                                    { label: "Czas", value: `${r.overallTime} min` },
                                    { label: "Porcje", value: `${r.numberOfEaters} os.` },
                                ];
                                const isLiked = getIsLiked(r, userId);
                                return (
                                    <RecipeCard
                                        key={r.id}
                                        id={r.id}
                                        name={r.name}
                                        status={r.status}
                                        imageUrl={imageUrl}
                                        routerLink={`/recipes/preview/${r.id}`}
                                        chips={chips}
                                        isLiked={isLiked}
                                        parentName=""
                                    />
                                );
                            })}
                        {query.data?.result?.length === 0 && (
                            <>
                                <h5>Nie masz jeszcze żadnych udostępnionych przepisów</h5>
                                <div className="recipe-card-component add-recipe">
                                    <IonCard routerLink={Paths.recipeAdd}>
                                        <div className="container">
                                            <IonButton
                                                expand="full"
                                                fill="clear"
                                            >
                                                <IonIcon
                                                    slot="start"
                                                    icon={addOutline}
                                                />
                                                Dodaj przepis
                                            </IonButton>
                                        </div>
                                    </IonCard>
                                </div>
                            </>
                        )}
                    </FlexContainer>
                )}
            </DataLoadingWardenForQuery>
        </div>
    );
};

const PublishedRecipes: React.FC<{ count: number }> = ({ count }) => {
    const userId = useSelector(selectUserId);
    let qp: IQueryParameters = {};
    qp.pageSize = count;
    qp.orderBy = "PublishedDate";
    qp.order = "desc";
    qp.filters = [new FilterParameters("status", "eq", "'Public'")];
    const query = useGetRecipesQuery(toQueryString(qp));
    return (
        <div className="published-recipes">
            <h4>Najnowsze przepisy</h4>
            <DataLoadingWardenForQuery query={query}>
                {() => (
                    <FlexContainer fakeChildClassName="recipe-card-component">
                        {!!query.data?.result?.length &&
                            query.data.result.map((r) => {
                                const imageUrl = getMainImageId(r);
                                const chips = [
                                    { label: "Czas", value: `${r.overallTime} min` },
                                    { label: "Porcje", value: `${r.numberOfEaters} os.` },
                                ];
                                const isLiked = getIsLiked(r, userId);
                                return (
                                    <RecipeCard
                                        key={r.id}
                                        id={r.id}
                                        name={r.name}
                                        status=""
                                        imageUrl={imageUrl}
                                        routerLink={`/recipes/preview/${r.id}`}
                                        chips={chips}
                                        parentName=""
                                        isLiked={isLiked}
                                    />
                                );
                            })}
                    </FlexContainer>
                )}
            </DataLoadingWardenForQuery>
        </div>
    );
};

export const StartPage: React.FC = () => {
    const user = useSelector(selectUser)!;
    return (
        <div className="start-page-component">
            <h2>Miło Cię widzieć, {user.name}!</h2>
            <LatestMenus />
            <RecipesInReview count={6} />
            <PublishedRecipes count={6} />
        </div>
    );
};
