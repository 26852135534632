import { useEffect, useRef, useState } from "react";
import { ControlledPicker } from "../ControlledPicker";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonModal,
    IonReorder,
    IonReorderGroup,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { useFieldArray, useForm } from "react-hook-form";
import { ControlledInput } from "../ControlledInput";
import { addOutline, closeOutline, trashOutline } from "ionicons/icons";
import classNames from "classnames";
import "./RecipeForm.scss";
import { LazyImage } from "../LazyImage";
import { environments } from "../../environments/environment";
import { ImageUpload } from "../ImageUpload";
import { useModalState } from "../../hooks/useModal";
import {
    useAddIngredientInitialQuery,
    useAddIngredientMutation,
    useGetIngredientsForDictQuery,
} from "../../store/data/ingredientsSlice";
import { useGetRecipeCategoriesForDictQuery } from "../../store/data/recipeCategoriesSlice";
import { useGetTagsForDictQuery } from "../../store/data/tagsSlice";
import { DataLoadingWardenForQuery } from "../DataLoadingWardenForQuery";
import { InputWrapper } from "../InputWrapper";
import { ErrorLabel } from "../ErrorLabel";
import { IngredientForm } from "./IngredientForm";
import { LoadingOrb } from "../LoadingOrb";
import { SomethingWentWrong } from "../SomethingWentWrong";
import { Prompt } from "react-router";
import { verifyIfNavigationNeedConfirmation } from "../../routerHelpers";
import { Paths } from "../../pages/routes";
import { ComplexityLabel } from "../ComplexityLabel";

function IngredientItem(props: {
    ingredient: any;
    register: any;
    index: number;
    ingredients: any[];
    control: any;
    errors: any;
    setValue: any;
    getValues: any;
    addIngredientModalState: any;
    setIngredientIdForModal: any;
}) {
    const [previousIngredientId, setPreviousIngredientId] = useState<number | undefined>();
    const [availableUnits, setAvailableUnits] = useState(getUnits(props.ingredients, props.ingredient.id));
    const selectedIngredientId = props.getValues(`ingredients.${props.index}.id`);

    useEffect(() => {
        const units = getUnits(props.ingredients, selectedIngredientId);
        setAvailableUnits(units);
        if (units[0] && (!!previousIngredientId || !props.getValues(`ingredients.${props.index}.unitId`))) {
            props.setValue(`ingredients.${props.index}.unitId`, units[0] && units[0].id);
        }
        if (selectedIngredientId) setPreviousIngredientId(selectedIngredientId);
    }, [selectedIngredientId, props.ingredients]);

    return (
        <>
            <ControlledPicker
                name={`ingredients.${props.index}.id`}
                label=""
                modalTitle="Wybierz składnik"
                placeholder="składnik"
                errors={props.errors}
                isLoading={false}
                items={props.ingredients}
                control={props.control}
                className="ingredient-id"
                closeOnChange={true}
                noResultsChildren={
                    <div className="no-ingredients-component">
                        <p>Nie znaleziono składnika, kliknij poniżej, żeby go dodać.</p>
                        <IonButton
                            onClick={() => {
                                props.addIngredientModalState.openModal("add-ingredient-modal");
                                props.setIngredientIdForModal(props.index);
                            }}
                        >
                            Dodaj składnik
                        </IonButton>
                    </div>
                }
            />

            <ControlledInput
                name={`ingredients.${props.index}.amount`}
                label=""
                placeholder="2,5"
                inputType="decimal"
                className="ingredient-amount"
                register={props.register}
                errors={props.errors}
            />

            <ControlledPicker
                name={`ingredients.${props.index}.unitId`}
                label=""
                modalTitle="Wybierz jednostkę"
                closeOnChange={true}
                placeholder="jedn."
                errors={props.errors}
                isLoading={false}
                className="ingredient-unit"
                items={availableUnits}
                control={props.control}
            />
        </>
    );
}

export const ImagesPreview = ({ control, watch }: { control: any; watch: any }) => {
    const { fields, append, remove } = useFieldArray<any>({
        control,
        name: "images",
    });

    const watchFieldArray = watch("ingredients");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index],
        };
    });

    const [modalOpened, openModal, closeModal] = useModalState("images");

    return (
        <>
            <div className="images-container">
                {controlledFields.map((i: any, index: number) => (
                    <div
                        key={index}
                        className="image-item"
                    >
                        <LazyImage src={`${environments.FILE_URL}/images/${i.key}/small`} />
                        <span>{i.description}</span>
                        <IonButton
                            color="danger"
                            onClick={() => remove(index)}
                        >
                            <IonIcon icon={trashOutline} />
                        </IonButton>
                    </div>
                ))}
                <div className="image-item">
                    <IonButton
                        className="add-button"
                        expand="block"
                        fill="outline"
                        onClick={() => openModal("images")}
                    >
                        <IonIcon
                            slot="icon-only"
                            icon={addOutline}
                        />
                        Dodaj zdjęcie
                    </IonButton>
                </div>
                <div className="image-item" />
                <div className="image-item" />
                <div className="image-item" />
                <div className="image-item" />
                <div className="image-item" />
                <div className="image-item" />
                <div className="image-item" />
                <div className="image-item" />
            </div>

            <IonModal
                isOpen={modalOpened}
                onDidDismiss={() => closeModal("images")}
                className="image-upload-modal"
            >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Dodaj zdjęcie</IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                strong={true}
                                onClick={() => closeModal("images")}
                            >
                                <IonIcon
                                    slot="icon-only"
                                    icon={closeOutline}
                                ></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <ImageUpload
                    onChangeImage={(key) => {
                        append({ key, description: "", isDefault: false });
                        closeModal("images");
                    }}
                />
            </IonModal>
        </>
    );
};
export const RecipeForm = ({ data, submitForm, errors }) => {
    const categories = useGetRecipeCategoriesForDictQuery();
    const tags = useGetTagsForDictQuery();
    const ingredients = useGetIngredientsForDictQuery();

    const { register, handleSubmit, control, watch, setValue, formState, getValues } = useForm({ defaultValues: data });
    const { fields, append, remove, move }: any = useFieldArray({
        control,
        keyName: "key",
        name: "ingredients",
    });

    const maxIndex = fields.reduce((acc, curr) => Math.max(acc, curr.index), 0);
    const descriptionValidationError = errors && errors["description"];
    const handleReorder = (event: CustomEvent<any>) => {
        const { from, to } = event.detail;
        move(from, to);
        fields.forEach((field, index) => {
            setValue(`ingredients.${index}.index`, index + 1);
        });
        event.detail.complete();
    };
    const [overallTime, setOverallTime] = useState(0);
    const [ingredientIdForModal, setIngredientIdForModal] = useState(0);
    const addIngredientModalStateArray = useModalState("add-ingredient-modal");
    const addIngredientModalState = {
        openModal: addIngredientModalStateArray[1],
        closeModal: addIngredientModalStateArray[2],
        modalOpened: addIngredientModalStateArray[0],
    };
    const complexity = watch("complexity");
    const calculateOverallTime = () => {
        const prepareTime = parseInt(getValues("prepareTime"), 10);
        const cookTime = parseInt(getValues("cookTime"), 10);
        const overall = isFinite(cookTime) && isFinite(prepareTime) ? prepareTime + cookTime : 0;

        setValue("overallTime", overall);
        setOverallTime(overall);
    };

    useEffect(() => {
        calculateOverallTime();
    });

    const submit = (saveAndPublish) => {
        if (formRef.current) {
            formRef.current.dispatchEvent(
                new CustomEvent("submit", { cancelable: true, bubbles: true, detail: { saveAndPublish } })
            );
        }
    };

    let formRef = useRef<HTMLFormElement>();
    return (
        <>
            <Prompt
                when={formState.isDirty}
                message={verifyIfNavigationNeedConfirmation([Paths.recipeAdd, Paths.recipeUpdate])}
            />
            <form
                noValidate={true}
                key="recipe-form"
                ref={formRef as any}
                onSubmit={(e) => {
                    handleSubmit(submitForm((e.nativeEvent as any).detail.saveAndPublish))(e);
                }}
            >
                <div className="recipe-form-component">
                    <div className="form-content">
                        <ControlledInput
                            name="name"
                            label="Nazwa"
                            register={register}
                            errors={errors}
                        />
                        <div className="eaters-and-complexity">
                            <ControlledInput
                                name="numberOfEaters"
                                label="Liczba porcji"
                                placeholder="np. 4"
                                register={register}
                                inputType="number"
                                errors={errors}
                                className="eaters-input"
                            />
                            <InputWrapper className="complexity-input">
                                <IonInput
                                    fill="outline"
                                    label="Poziom trudności"
                                    labelPlacement="stacked"
                                    readonly={true}
                                ></IonInput>
                                <div className="complexity-button-holder">
                                    <IonButton
                                        fill="clear"
                                        className={complexity === 1 ? "complexity-active" : ""}
                                        onClick={(x) => setValue("complexity", 1)}
                                    >
                                        <ComplexityLabel complexity={1} />
                                    </IonButton>
                                    <IonButton
                                        fill="clear"
                                        className={complexity === 2 ? "complexity-active" : ""}
                                        onClick={(x) => setValue("complexity", 2)}
                                    >
                                        <ComplexityLabel complexity={2} />
                                    </IonButton>
                                    <IonButton
                                        fill="clear"
                                        className={complexity === 3 ? "complexity-active" : ""}
                                        onClick={(x) => setValue("complexity", 3)}
                                    >
                                        <ComplexityLabel complexity={3} />
                                    </IonButton>
                                </div>
                            </InputWrapper>
                        </div>
                        <div className="time-inputs">
                            <ControlledInput
                                className="prepare-time-input"
                                name="prepareTime"
                                label="Czas przygotowania"
                                placeholder="np. 30"
                                register={register}
                                inputType="number"
                                onChange={calculateOverallTime}
                                errors={errors}
                            />
                            <p style={{ padding: 0 }}>+</p>
                            <ControlledInput
                                className="cook-time-input"
                                name="cookTime"
                                label="Czas gotowania"
                                placeholder="np. 30"
                                inputType="number"
                                register={register}
                                onChange={calculateOverallTime}
                                errors={errors}
                            />
                            <p className="overall-time-result"> = {overallTime} min</p>
                        </div>
                        <ControlledInput
                            name="source"
                            label="Żródło"
                            placeholder="np. https://www.google.com, własne"
                            register={register}
                            errors={errors}
                        />

                        <DataLoadingWardenForQuery
                            query={tags}
                            mode="control"
                        >
                            {() => (
                                <ControlledPicker
                                    name="tags"
                                    label="Tagi"
                                    errors={errors}
                                    closeOnChange={false}
                                    isLoading={false}
                                    items={tags.data.result}
                                    control={control}
                                    isMulti={true}
                                    modalTitle="Wybierz tag"
                                />
                            )}
                        </DataLoadingWardenForQuery>
                        <DataLoadingWardenForQuery
                            query={categories}
                            mode="control"
                        >
                            {() => (
                                <ControlledPicker
                                    name="categories"
                                    label="Kategorie"
                                    closeOnChange={false}
                                    errors={errors}
                                    isLoading={false}
                                    items={categories.data.result}
                                    control={control}
                                    isMulti={true}
                                    modalTitle="Wybierz kategorię"
                                />
                            )}
                        </DataLoadingWardenForQuery>
                    </div>

                    <div className="form-content">
                        <span>Opis</span>
                        <InputWrapper
                            key="description"
                            className={classNames("description", {
                                "ion-valid": !descriptionValidationError,
                                "ion-invalid": descriptionValidationError,
                            })}
                        >
                            <IonTextarea
                                placeholder="Opisz jak przygotować danie"
                                fill="outline"
                                autoGrow={true}
                                {...register("description")}
                            />
                            <ErrorLabel>{descriptionValidationError}</ErrorLabel>
                        </InputWrapper>
                    </div>

                    <div
                        className="form-content"
                        key="ingredients"
                    >
                        <span>Składniki</span>
                        <IonReorderGroup
                            disabled={false}
                            onIonItemReorder={handleReorder}
                        >
                            {fields.map((ingredient, index) => (
                                <div
                                    className="ingredient"
                                    key={`ingredient-${ingredient.key}`}
                                >
                                    <IonReorder />
                                    {ingredient.type === "Ingredient" && (
                                        <DataLoadingWardenForQuery
                                            query={ingredients}
                                            mode="control"
                                        >
                                            {() => (
                                                <IngredientItem
                                                    ingredient={ingredient}
                                                    index={index}
                                                    register={register}
                                                    control={control}
                                                    ingredients={ingredients.data.result}
                                                    errors={errors}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                    addIngredientModalState={addIngredientModalState}
                                                    setIngredientIdForModal={setIngredientIdForModal}
                                                />
                                            )}
                                        </DataLoadingWardenForQuery>
                                    )}
                                    {ingredient.type === "IngredientGroup" && (
                                        <ControlledInput
                                            name={`ingredients.${index}.name`}
                                            label=""
                                            placeholder="np. Sos, Ciasto"
                                            className="ingredient-group-item"
                                            register={register}
                                            errors={errors}
                                        />
                                    )}

                                    <IonButton
                                        fill="clear"
                                        className="ingredient-remove"
                                        color="danger"
                                        onClick={() => remove(index)}
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={trashOutline}
                                        ></IonIcon>
                                    </IonButton>
                                </div>
                            ))}
                        </IonReorderGroup>
                        <div className="ingredients-button-container">
                            <IonButton
                                className="ingredients-button-container"
                                fill="outline"
                                onClick={() => {
                                    append({
                                        index: maxIndex + 1,
                                        type: "Ingredient",
                                        id: null,
                                        amount: null,
                                        unitId: null,
                                    });
                                }}
                            >
                                <IonIcon icon={addOutline} /> Dodaj
                            </IonButton>
                            <IonButton
                                fill="outline"
                                onClick={() => {
                                    append({ index: maxIndex + 1, type: "IngredientGroup", name: null });
                                }}
                            >
                                <IonIcon icon={addOutline} /> Dodaj grupę
                            </IonButton>
                        </div>
                    </div>
                </div>
            </form>
            <div className="form-content">
                <span>Zdjęcia</span>
                <ImagesPreview
                    control={control}
                    watch={watch}
                />
            </div>
            <div className="form-content">
                <IonButton
                    id="save-button"
                    type="submit"
                    onClick={() => submit(false)}
                >
                    Zapisz
                </IonButton>
                <IonButton
                    id="save-and-publish-button"
                    type="button"
                    onClick={() => submit(true)}
                >
                    Zapisz i podziel się
                </IonButton>
            </div>
            <NoIngredientsWithModal
                name="add-ingredient-modal"
                onAdded={(id) => {
                    setValue(`ingredients.${ingredientIdForModal}.id`, id);
                }}
                addIngredientModalState={addIngredientModalState}
            />
        </>
    );
};

const NoIngredientsWithModal = ({
    addIngredientModalState,
    name,
    onAdded,
}: {
    addIngredientModalState: any;
    name: string;
    onAdded: (any) => void;
}) => {
    const initial = useAddIngredientInitialQuery({});
    const [add, result] = useAddIngredientMutation();

    useEffect(() => {
        if (!!result?.isSuccess) {
            addIngredientModalState.closeModal(name);
            onAdded(result.data.objectId);
        }
    }, [name, result]);

    let modalContent: any = null;

    if (result.isLoading) {
        modalContent = <LoadingOrb text="Zapisuję..." />;
    } else if (result.error && result.error?.status !== 400) {
        modalContent = <SomethingWentWrong text="Coś poszło nie tak :(" />;
    } else
        modalContent = (
            <DataLoadingWardenForQuery query={initial}>
                {() => (
                    <IngredientForm
                        data={(result.error?.data && result.originalArgs?.body) || initial.data}
                        submitForm={(data) => add({ body: data })}
                        errors={result.error?.data || {}}
                    />
                )}
            </DataLoadingWardenForQuery>
        );

    return (
        <>
            <IonModal
                isOpen={addIngredientModalState.modalOpened}
                onDidDismiss={() => addIngredientModalState.closeModal(name)}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Dodaj składnik</IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                strong={true}
                                onClick={() => addIngredientModalState.closeModal(name)}
                            >
                                <IonIcon
                                    slot="icon-only"
                                    icon={closeOutline}
                                ></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>{modalContent}</IonContent>
            </IonModal>
        </>
    );
};

const getUnits = (ingredients, ingredientId: number) => {
    const ingredient = ingredients.filter((i) => i.id === ingredientId)[0];
    if (!ingredient) {
        return [];
    }

    return [ingredient.baseUnit, ...ingredient.unitTranslations.map((ut) => ut.unit)];
};
