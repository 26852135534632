import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import CookieConsent from "react-cookie-consent";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";

import { NavigationMenu } from "./pages/Menu";
import { ApplicationRoutes } from "./pages/routes";
import { UserGuard } from "./components/UserGuard";
import { ToastContainer } from "./components/ToastContainer";
import { useContext } from "react";

setupIonicReact();

const App: React.FC = () => {
    return (
        <IonApp>
            <CookieConsent
                location="bottom"
                buttonText="Akceptuję"
                cookieName="acceptedCookiesPolicy"
                style={{ background: "#eee", color: "#000", fontSize: "11px" }}
                buttonStyle={{ background: "rgb(76, 183, 81)", fontSize: "12px", fontWeight: "bold", color: "#fff" }}
            >
                Używamy cookies w celach funkcjonalnych, aby ułatwić użytkownikom korzystanie z witryny oraz w celu tworzenia
                anonimowych statystyk serwisu. Jeżeli nie blokujesz plików cookies, to zgadzasz się na ich używanie oraz zapisanie
                w pamięci urządzenia.
                <br />
                <br /> Więcej informacji:{" "}
                <a
                    href="https://www.feed-me.app/cookies-policy"
                    target="_blank"
                >
                    w Polityce Cookies
                </a>
            </CookieConsent>

            <ToastContainer key="toast-container" />
            <UserGuard>
                <IonReactRouter>
                    <IonSplitPane contentId="main">
                        <NavigationMenu />
                        <IonRouterOutlet id="main">{ApplicationRoutes}</IonRouterOutlet>
                    </IonSplitPane>
                </IonReactRouter>
            </UserGuard>
        </IonApp>
    );
};

export default App;
