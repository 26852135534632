import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { environments } from "../../environments/environment";

export const publicApi = createApi({
    reducerPath: "publicApi",
    baseQuery: fetchBaseQuery({
        baseUrl: environments.API_URL,
    }),
    endpoints: (builder) => ({
        menus: builder.query<any, {}>({
            query: () => `/public/menus`,
        }),
        getMenuById: builder.query<any, { id: string }>({
            query: ({ id }) => `/public/menus/${id}`,
        }),
        getMenuRecipeByIds: builder.query<any, { menuId: string; recipeId: string }>({
            query: ({ menuId, recipeId }) => `/public/menus/${menuId}/recipes/${recipeId}`,
        }),
        getShoppingListById: builder.query<any, { menuId: string }>({
            query: ({ menuId }) => `/public/menus/${menuId}/shopping-list`,
        }),
    }),
});

export const { useMenusQuery, useGetMenuByIdQuery, useGetMenuRecipeByIdsQuery, useGetShoppingListByIdQuery } = publicApi;
