import "./ResetPassword.scss";
import { useState } from "react";
import { sendPasswordReset } from "../../firebase";
import { IonButton, IonRouterLink, IonText, useIonRouter } from "@ionic/react";
import { useForm } from "react-hook-form";
import { ControlledInput } from "../../components/ControlledInput";
import { useDispatch } from "react-redux";
import { openToast } from "../../store/toastSlice";
import { Paths } from "../routes";

interface ContainerProps {}

export const ResetPassword: React.FC<ContainerProps> = () => {
    const router = useIonRouter();
    const dispatch = useDispatch();
    const [error, setError] = useState<string | undefined>(undefined);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: { email: "" } });

    return (
        <div className="reset-password-component">
            <form
                noValidate={true}
                onSubmit={handleSubmit(async (data) => {
                    const error = await sendPasswordReset(data.email);
                    if (error) {
                        setError(error);
                    } else {
                        dispatch(openToast("Wysłano link do zresetowania hasła"));
                        router.push(Paths.login, "root");
                    }
                })}
            >
                <ControlledInput
                    name="email"
                    label="E-mail"
                    register={register}
                    validationRules={{ required: true }}
                    errors={errors}
                    placeholder="abc@gmail.com"
                />
                {!!error && (
                    <IonText
                        color="danger"
                        style={{ fontSize: "0.7em" }}
                    >
                        {error}
                    </IonText>
                )}
                <IonButton
                    expand="full"
                    type="submit"
                >
                    Zresetuj hasło
                </IonButton>
                <div>
                    Nie masz konta? <IonRouterLink routerLink="/register">Zarejestruj się</IonRouterLink>
                </div>
            </form>
        </div>
    );
};
