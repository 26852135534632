import { Redirect } from "react-router-dom";
import { useAddRecipeInitialQuery, useAddRecipeMutation } from "../../store/data/recipeSlice";
import { RecipeForm } from "../../components/forms/RecipeForm";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { LoadingOrb } from "../../components/LoadingOrb";
import { SomethingWentWrong } from "../../components/SomethingWentWrong";

class ContainerProps {}

export const RecipeAddForm: React.FC<ContainerProps> = () => {
    const initial = useAddRecipeInitialQuery({});
    const [add, result] = useAddRecipeMutation();

    if (result.isLoading) {
        return <LoadingOrb text="Zapisuję..." />;
    }
    if (result.error && result.error?.status !== 400) {
        return <SomethingWentWrong text="Coś poszło nie tak :(" />;
    }
    if (result.isSuccess) {
        return <Redirect to="/recipes" />;
    }

    return (
        <div className="recipe-form-component">
            <DataLoadingWardenForQuery query={initial}>
                {() => (
                    <RecipeForm
                        data={result.originalArgs?.body || initial.data}
                        submitForm={(saveAndPublish) => (data) => add({ body: { ...data, saveAndPublish } })}
                        errors={result.error?.data || {}}
                    />
                )}
            </DataLoadingWardenForQuery>
        </div>
    );
};
