import "./MenuList.scss";
import { IonItem, IonLabel, IonList } from "@ionic/react";
import Moment from "react-moment";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { useMenusQuery } from "../../store/data/publicSlice";
import { DATE_FORMAT } from "../../consts";

interface ContainerProps {}

export const MenuList: React.FC<ContainerProps> = () => {
    const menus = useMenusQuery({});
    return (
        <div className="menu-list-component">
            <DataLoadingWardenForQuery
                query={menus}
                children={() => (
                    <IonList>
                        {menus.data.result.map((m) => (
                            <IonItem
                                routerLink={`/menus/${m.id}`}
                                key={m.id}
                            >
                                <IonLabel>
                                    {m.name} (<Moment format={DATE_FORMAT}>{m.startDate}</Moment> -{" "}
                                    <Moment format={DATE_FORMAT}>{m.endDate}</Moment>)
                                </IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                )}
            />
        </div>
    );
};
