import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import "./Page.scss";
import { environments } from "../environments/environment";
import { RouteComponentProps } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { SetDocumentTitle } from "../components/SetDocumentTitle";
import classNames from "classnames";
import { ToastContainer } from "../components/ToastContainer";
import { useSelector } from "react-redux";
import { selectUser } from "../store/authSlice";

interface PageProps {
    children: JSX.Element;
    name: string;
    rightButtons?: JSX.Element;
    link: string;
}

const Page: React.FC<PageProps | (PageProps & RouteComponentProps)> = ({ children, name, rightButtons, link, ...props }) => {
    const userRole = useSelector(selectUser)!.role;
    const titleClasses = classNames({ "title-with-buttons": !!rightButtons });
    return (
        <IonPage className="page-component">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                        <IonBackButton />
                    </IonButtons>
                    <IonButtons slot="end">{rightButtons}</IonButtons>
                    <IonTitle className={titleClasses}>{name}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <ErrorBoundary
                    FallbackComponent={MyFallbackComponent}
                    onError={(error, errorInfo) => {
                        fetch(environments.API_URL + "/Log", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                message: error.message + error.stack,
                                level: "Critical",
                                applicationName: "feed-me-public",
                            }),
                        });
                    }}
                    onReset={() => {
                        document.location.reload();
                    }}
                >
                    <>
                        <SetDocumentTitle
                            title={name}
                            key={link}
                        />
                        {children}
                    </>
                </ErrorBoundary>
            </IonContent>
            {!environments.IS_PROD && <IonFooter>UWAGA ŚRODOWISKO TESTOWE!!</IonFooter>}
            {environments.IS_PROD && userRole === "anonymous" && (
                <IonFooter style={{ height: "75px" }}>
                    <GoogleAdSense />
                </IonFooter>
            )}
        </IonPage>
    );
};

function MyFallbackComponent({ error, resetErrorBoundary }) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
}

function GoogleAdSense() {
    return (
        <>
            <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1740785997310806"
                crossOrigin="anonymous"
            ></script>
            <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-1740785997310806"
                data-ad-slot="9177643792"
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
            <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        </>
    );
}

export default Page;
