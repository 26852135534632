import { IonFab, IonFabButton, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem } from "@ionic/react";
import { add } from "ionicons/icons";
import { Link } from "react-router-dom";
import { useGetRecipesQuery } from "../../store/data/recipeSlice";
import { RecipeCard } from "../../components/RecipeCard";
import "./RecipeList.scss";
import { useState } from "react";
import { getMainImageId, getIsLiked } from "../../to_remove_helpers";
import { FlexContainer } from "../../components/FlexContainer";
import useInfiniteScroll from "../../store/useInfiniteScroll";
import { RecipeFilterForm } from "../../components/forms/RecipeFilterForm";
import { DataLoadingWarden } from "../../components/DataLoadingWardenForQuery";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE, selectRecipeListFilters, setRecipeListFilters } from "../../store/filtersSlice";
import { selectUserId } from "../../store/authSlice";

class ContainerProps {}

export const RecipeList: React.FC<ContainerProps> = () => {
    const userId = useSelector(selectUserId)!;

    const [infiniteScrollEvent, setInfiniteScrollEvent] = useState<HTMLIonInfiniteScrollElement | undefined>(undefined);
    const filters = useSelector(selectRecipeListFilters);
    const dispatch = useDispatch();

    const {
        combinedData: data,
        readMore,
        totalCount,
        isLoading,
        canLoadMore,
    } = useInfiniteScroll(useGetRecipesQuery, { ...filters.queryParameters });

    if (!isLoading && infiniteScrollEvent) {
        infiniteScrollEvent.complete();
    }

    return (
        <>
            <div className="recipe-list-component">
                <RecipeFilterForm
                    value={filters}
                    setValue={(f) => dispatch(setRecipeListFilters(f))}
                />
                <FlexContainer fakeChildClassName="recipe-card-component">
                    {!!data?.length &&
                        data.map((r) => {
                            const imageUrl = getMainImageId(r);
                            const chips = [
                                { label: "Czas", value: `${r.overallTime} min` },
                                { label: "Porcje", value: `${r.numberOfEaters} os.` },
                            ];
                            const isLiked = getIsLiked(r, userId);
                            return (
                                <RecipeCard
                                    key={r.id}
                                    id={r.id}
                                    name={r.name}
                                    status={r.author.id === userId ? r.status : ""}
                                    imageUrl={imageUrl}
                                    routerLink={`/recipes/preview/${r.id}`}
                                    chips={chips}
                                    isLiked={isLiked}
                                    parentName=""
                                />
                            );
                        })}
                </FlexContainer>
                <DataLoadingWarden
                    mode="page"
                    isLoading={isLoading}
                    isLoadingText={totalCount > 0 ? xFromRecipesText(data.length, totalCount) : undefined}
                    isError={false}
                    children={() => (
                        <>
                            <IonInfiniteScroll
                                disabled={!canLoadMore}
                                onIonInfinite={(ev) => {
                                    if (!isLoading) {
                                        readMore();
                                        setInfiniteScrollEvent(ev.target as HTMLIonInfiniteScrollElement);
                                    }
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                            {!!data?.length && !canLoadMore && (
                                <span style={{ padding: "10px", textAlign: "center" }}>
                                    {xFromRecipesText(data.length, totalCount)}
                                </span>
                            )}
                            {!data?.length && (
                                <IonItem>
                                    Nie znaleziono pasujących wyników, zmień kryteria wyszukiwania lub dodaj przepis.
                                </IonItem>
                            )}
                        </>
                    )}
                />
            </div>
            <IonFab
                slot="fixed"
                vertical="bottom"
                horizontal="end"
            >
                <Link to="/recipes/add">
                    <IonFabButton>
                        <IonIcon icon={add}></IonIcon>
                    </IonFabButton>
                </Link>
            </IonFab>
        </>
    );
};

const xFromRecipesText = (x, from) => {
    if (from === 1) return `Załadowano 1 przepis`;
    if (from % 10 <= 4 && from < PAGE_SIZE) return `Załadowano ${from} przepisy`;
    if (from < PAGE_SIZE) return `Załadowano ${from} przepisów`;
    if (x === from) return `Załadowano ${x} przepisów`;

    return `Załadowano ${x} z ${from} przepisów`;
};
