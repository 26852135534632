import { Menu } from "./components/previews/MenuPreview";
//TODO: remove this code and move all the mappings to backend
export const getIsLiked = (r, userId) => {
    return r?.likes.some((l) => l.userId === userId) || false;
};

export const getMainImageId = (recipe: any) =>
    recipe.images?.filter((i) => i.isDefault)[0]?.key || (recipe.images || [])[0]?.key || "";

export const getImageIds = (recipe: any) => recipe.images?.map((i) => i.key) || [];

export const getAllIngredients = (recipe) =>
    recipe.ingredients
        .map((r) => ({
            name: r.ingredient.name,
            unit: r.unit.name,
            amount: r.amount,
            type: "Ingredient",
            index: r.index,
        }))
        .concat(recipe.recipeIngredientGroups.map((r) => ({ name: r.name, type: "Group", index: r.index })))
        .sort((a, b) => a.index - b.index);

export const mapMenu = (m) => {
    const days = [...m.days].sort((a, b) => a.index - b.index);
    const menu: Menu = {
        id: m.id,
        name: m.name,
        days: days.map((d) => {
            const recipes = [...d.recipes].sort((a, b) => a.index - b.index);
            return {
                name: d.name,
                date: d.date,
                recipes: recipes.map((r) => {
                    return {
                        id: r.recipe.id,
                        name: r.recipe.name,
                        imageUrl: getMainImageId(r.recipe),
                        slotName: r.slotName,
                        overallTime: r.recipe.overallTime,
                        numberOfEaters: r.numberOfEaters,
                    };
                }),
            };
        }),
    };
    return menu;
};
