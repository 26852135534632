import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";

export type recipeType = "all" | "favorites" | "my" | "last";
const segments: { value: recipeType; label: string; enabled: boolean }[] = [
    { value: "all", label: "Wszystkie", enabled: true },
    { value: "my", label: "Moje", enabled: true },
    { value: "favorites", label: "Ulubione", enabled: true },
    // { value: "last", label: "Ostatnio oglądane", enabled: false },
];

export const RecipeTypeButtons: React.FC<{ onChange: (type: recipeType) => void; value?: recipeType }> = ({
    onChange,
    value,
}) => (
    <IonSegment value={value || segments[0].value}>
        {segments.map((s) => (
            <IonSegmentButton
                key={s.value}
                value={s.value}
                disabled={!s.enabled}
                onClick={() => onChange(s.value)}
            >
                <IonLabel>{s.label}</IonLabel>
            </IonSegmentButton>
        ))}
    </IonSegment>
);
