import "./RecipeMenuCard.scss";
import { IonButton, IonCard, IonIcon } from "@ionic/react";
import { environments } from "../../../environments/environment";
import { useGetRecipesForDictQuery } from "../../../store/data/recipeSlice";
import { getMainImageId } from "../../../to_remove_helpers";
import { LazyImage } from "../../LazyImage";
import { ControlledRecipePicker } from "../../ControlledRecipePicker";
import { ControlledInput } from "../../ControlledInput";
import { trashOutline } from "ionicons/icons";
import { DataLoadingWardenForQuery } from "../../DataLoadingWardenForQuery";
import { useModalState } from "../../../hooks/useModal";

interface ContainerProps {
    recipeId: number;
    register: any;
    control: any;
    errors: any;
    recipeField: string;
    remove: any;
    index: number;
}

export const RecipeMenuCard: React.FC<ContainerProps> = ({ recipeId, register, control, errors, recipeField, remove, index }) => {
    const recipes = useGetRecipesForDictQuery();
    let image: React.ReactNode = null;
    const [, openModal] = useModalState(`${recipeField}.recipeId`);

    return (
        <div className="recipe-menu-card-component">
            <DataLoadingWardenForQuery
                query={recipes}
                mode="control"
            >
                {() => {
                    if (recipeId) {
                        const recipe = recipes.data.result?.find((x) => x.id === recipeId);
                        if (recipe) {
                            const imageUrl = getMainImageId(recipe);
                            image = (
                                <LazyImage
                                    onClick={(x) => openModal(`${recipeField}.recipeId`)}
                                    src={`${environments.FILE_URL}/images/${imageUrl}/small`}
                                />
                            );
                        }
                    }
                    return (
                        <IonCard type="button">
                            <div className="container">
                                <div className="img-wrapper">{image}</div>
                                <div className="side-wrapper">
                                    <ControlledInput
                                        name={`${recipeField}.numberOfEaters`}
                                        label="Liczba porcji"
                                        inputType="decimal"
                                        placeholder="np. 2"
                                        register={register}
                                        errors={errors}
                                    />
                                    <ControlledInput
                                        name={`${recipeField}.slotName`}
                                        label="Nazwa posiłku"
                                        placeholder="np. śniadanie"
                                        register={register}
                                        errors={errors}
                                    />
                                </div>
                                <div className="flex-break" />
                                <ControlledRecipePicker
                                    name={`${recipeField}.recipeId`}
                                    label="Przepis"
                                    modalTitle="Wybierz przepis"
                                    errors={errors}
                                    isLoading={false}
                                    items={recipes.data.result}
                                    control={control}
                                />
                                <div className="flex-break" />
                                <div className="remove-button">
                                    <IonButton
                                        expand="block"
                                        color="danger"
                                        fill="clear"
                                        onClick={() => remove(index)}
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={trashOutline}
                                        ></IonIcon>{" "}
                                        Usuń
                                    </IonButton>
                                </div>
                            </div>
                        </IonCard>
                    );
                }}
            </DataLoadingWardenForQuery>
        </div>
    );
};
