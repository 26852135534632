import { Controller } from "react-hook-form";
import { Picker } from "./Picker";

export const ControlledPicker = ({
    name,
    label,
    modalTitle,
    errors,
    isLoading,
    items,
    labelSelector,
    valueSelector,
    control,
    className,
    onChange,
    isMulti,
    placeholder,
    noResultsChildren,
    closeOnChange,
}: {
    name: string;
    label: string;
    modalTitle: string;
    errors: any;
    isLoading: boolean;
    items: any[];
    labelSelector?: (x: any) => string;
    valueSelector?: (x: any) => any;
    control: any;
    className?: string;
    onChange?: (e: any) => void;
    isMulti?: boolean;
    placeholder?: string;
    noResultsChildren?: React.ReactNode;
    closeOnChange: boolean;
}) => {
    return (
        <Controller
            key={name}
            name={name}
            control={control}
            render={({ field }) => (
                <Picker
                    {...field}
                    label={label}
                    modalTitle={modalTitle}
                    name={name}
                    errors={errors}
                    isLoading={isLoading}
                    placeholder={placeholder}
                    items={items}
                    className={className}
                    labelSelector={labelSelector || ((x) => x.name)}
                    valueSelector={valueSelector || ((x) => x.id)}
                    onChange={(e) => {
                        onChange && onChange(e);
                        field.onChange(e);
                    }}
                    isMulti={isMulti}
                    noResultsChildren={noResultsChildren}
                    closeOnChange={closeOnChange}
                />
            )}
        />
    );
};
