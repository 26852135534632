import { Route, RouteComponentProps } from "react-router-dom";
import { RouteGuard } from "../../components/RouteGuard";
import Page from "../Page";
import { RecipeAddForm } from "./RecipeAddForm";
import { RecipeList } from "./RecipeList";
import { RecipePreviewPage, RecipePreviewPageButtons } from "./RecipePreviewPage";
import { RecipeUpdateForm, RecipeUpdatePageButtons } from "./RecipeUpdateForm";
import { RecipeFeedback } from "./RecipeFeedback";

export const RecipePaths = {
    recipes: "/recipes",
    recipeAdd: "/recipes/add",
    recipeUpdate: "/recipes/update/:id",
    recipePreview: "/recipes/preview/:id",
    recipeFeedback: "/recipes/feedback/:id",
};
export const RecipesRoutes = (
    <>
        <Route
            path={RecipePaths.recipes}
            key={RecipePaths.recipes}
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={RecipePaths.recipes}
                >
                    <Page
                        link={RecipePaths.recipes}
                        name="Przepisy"
                        {...props}
                    >
                        <RecipeList />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={RecipePaths.recipeAdd}
            key={RecipePaths.recipeAdd}
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={RecipePaths.recipeAdd}
                >
                    <Page
                        link={RecipePaths.recipeAdd}
                        name="Dodaj przepis"
                        {...props}
                    >
                        <RecipeAddForm />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={RecipePaths.recipeUpdate}
            key={RecipePaths.recipeUpdate}
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/recipes/update/${props.match.params.id}`}
                >
                    <Page
                        link={`/recipes/update/${props.match.params.id}`}
                        name="Zmień przepis"
                        rightButtons={<RecipeUpdatePageButtons {...props} />}
                        {...props}
                    >
                        <RecipeUpdateForm {...props} />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={RecipePaths.recipePreview}
            key={RecipePaths.recipePreview}
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/recipes/preview/${props.match.params.id}`}
                >
                    <Page
                        link={`/recipes/preview/${props.match.params.id}`}
                        name="Podgląd przepisu"
                        {...props}
                        rightButtons={<RecipePreviewPageButtons {...props} />}
                    >
                        <RecipePreviewPage {...props} />
                    </Page>
                </RouteGuard>
            )}
        />
        ,
        <Route
            path={RecipePaths.recipeFeedback}
            key={RecipePaths.recipeFeedback}
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/recipes/feedback/${props.match.params.id}`}
                >
                    <Page
                        link={`/recipes/feedback/${props.match.params.id}`}
                        name="Uwagi do przepisu"
                        {...props}
                    >
                        <RecipeFeedback {...props} />
                    </Page>
                </RouteGuard>
            )}
        />
    </>
);
