import { IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { add, pencilOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { useDeleteTagMutation, useGetTagsQuery } from "../../store/data/tagsSlice";
import { ActionWithConfirmation } from "../../components/ActionWithConfirmation";
import { RedirectActionButton } from "../../components/previews/RedirectActionButton";

class ContainerProps {}

export const TagList: React.FC<ContainerProps> = () => {
    const units = useGetTagsQuery("empty for now");
    const [remove, result] = useDeleteTagMutation();
    return (
        <DataLoadingWardenForQuery query={units}>
            {() => (
                <>
                    <IonList className="menu-list-component">
                        {units.data.result?.length &&
                            units.data.result?.map((x) => (
                                <IonItem key={x.id}>
                                    <IonLabel>{x.name}</IonLabel>
                                    <RedirectActionButton
                                        text="Edytuj"
                                        to={`/dictionaries/tags/update/${x.id}`}
                                        icon={pencilOutline}
                                    />
                                    <ActionWithConfirmation
                                        message={`Czy na pewno chcesz usunąć tag ${x.name}?`}
                                        header="Usuwanie taga"
                                        confirmedAction={() => remove({ id: x.id })}
                                    />
                                </IonItem>
                            ))}
                    </IonList>
                    <IonFab
                        slot="fixed"
                        vertical="bottom"
                        horizontal="end"
                    >
                        <Link to="/dictionaries/tags/add">
                            <IonFabButton>
                                <IonIcon icon={add}></IonIcon>
                            </IonFabButton>
                        </Link>
                    </IonFab>
                </>
            )}
        </DataLoadingWardenForQuery>
    );
};
