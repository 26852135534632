import "./LoadingOrb.scss";
import { IonIcon } from "@ionic/react";
import { fastFoodOutline } from "ionicons/icons";

interface LoadingOrbProps {
    text?: string;
    children?: React.ReactNode;
    hideText?: boolean;
    mode?: "control" | "page";
}

const randomTexts = ["Sortuje sztućce...", "Mieszam składniki...", "Rozgrzewam patelnię..."];

export const LoadingOrb: React.FC<LoadingOrbProps> = ({ text, children, hideText, mode }) => {
    const randomText = text || randomTexts[Math.floor(Math.random() * randomTexts.length)];
    return (
        <div className={`loading-orb-component loading-orb-component--${mode || "page"}`}>
            <div className="loading-spinner">
                <IonIcon icon={fastFoodOutline} />
            </div>
            <div className="text">
                {!hideText && <span>{randomText}</span>}
                <span>{children}</span>
            </div>
        </div>
    );
};
