import { useState } from "react";
import { IonAlert, IonButton, IonIcon } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import "./ActionWithConfirmation.scss";

export const ActionWithConfirmation: React.FC<{
    confirmedAction: () => {};
    message: string;
    header: string;
}> = ({ confirmedAction, message, header }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <IonButton
                slot="end"
                color="danger"
                fill="clear"
                onClick={(e) => {
                    setIsOpen(true);
                }}
            >
                <IonIcon icon={trashOutline}></IonIcon> Usuń
            </IonButton>
            <IonAlert
                className="action-with-confirmation-modal"
                isOpen={isOpen}
                header={header}
                message={message}
                onDidDismiss={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsOpen(false);
                }}
                buttons={[
                    {
                        text: "Anuluj",
                        role: "cancel",
                        handler: () => {},
                    },
                    {
                        text: "Tak",
                        cssClass: "danger",
                        role: "confirm",
                        handler: () => {
                            confirmedAction();
                        },
                    },
                ]}
            ></IonAlert>
        </>
    );
};
