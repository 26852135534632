import { Route, RouteComponentProps } from "react-router-dom";
import { RouteGuard } from "../../components/RouteGuard";
import Page from "../Page";
import { StartPage } from "./StartPage";
import { Subscription } from "./Subscription";
import { Onboarding } from "./Onboarding";
import { Feedback } from "./Feedback";
import React from "react";

export const UserPaths = {
    startPage: "/user/start-page",
    onboarding: "/user/onboarding/:id",
    feedback: "/user/feedback",
};

export const UserRoutes = (
    <>
        <Route
            path={UserPaths.startPage}
            key={UserPaths.startPage}
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={UserPaths.startPage}
                >
                    <Page
                        link={UserPaths.startPage}
                        name="Strona startowa"
                        {...props}
                    >
                        <StartPage />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={UserPaths.onboarding}
            key={UserPaths.onboarding}
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/user/onboarding/${props.match.params.id}`}
                >
                    <Page
                        link={`/user/onboarding/${props.match.params.id}`}
                        name="Pierwsze kroki"
                        {...props}
                    >
                        <Onboarding {...props} />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={UserPaths.feedback}
            key={UserPaths.feedback}
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={UserPaths.feedback}
                >
                    <Page
                        link={UserPaths.feedback}
                        name="Opinia"
                        {...props}
                    >
                        <Feedback {...props} />
                    </Page>
                </RouteGuard>
            )}
        />
    </>
);
