import { IonToast } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { destroyToast, selectToast } from "../store/toastSlice";

export const ToastContainer: React.FC = () => {
    const { isOpen, message } = useSelector(selectToast);
    const dispatch = useDispatch();
    return (
        <IonToast
            isOpen={isOpen}
            message={message}
            duration={5000}
            onDidDismiss={() => dispatch(destroyToast())}
        ></IonToast>
    );
};
