import { Redirect } from "react-router-dom";
import { LoadingOrb } from "../../components/LoadingOrb";
import { SomethingWentWrong } from "../../components/SomethingWentWrong";
import { useAddTagInitialQuery, useAddTagMutation } from "../../store/data/tagsSlice";
import { NameOnlyForm } from "../../components/forms/NameOnlyForm";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";

class ContainerProps {}

export const TagAddForm: React.FC<ContainerProps> = () => {
    const initial = useAddTagInitialQuery({});
    const [add, result] = useAddTagMutation();

    if (result.isLoading) {
        return <LoadingOrb text="Zapisuję..." />;
    }
    if (result.error && result.error?.status !== 400) {
        return <SomethingWentWrong text="Coś poszło nie tak :(" />;
    }
    if (result.isSuccess) {
        return <Redirect to="/dictionaries/tags" />;
    }

    return (
        <div className="tag-add-form-component form-content">
            <DataLoadingWardenForQuery query={initial}>
                {() => (
                    <NameOnlyForm
                        data={result.originalArgs?.body || initial.data}
                        submitForm={(data) => add({ body: data })}
                        errors={result.error?.data || {}}
                    />
                )}
            </DataLoadingWardenForQuery>
        </div>
    );
};
