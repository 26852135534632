import { Route } from "react-router-dom";
import { environments } from "../environments/environment";
import { PublicRoutes } from "./public";
import { AuthPaths, AuthRoutes } from "./auth";
import { DictionariesRoutes } from "./dicionaries";
import { RecipePaths, RecipesRoutes } from "./recipes";
import { MenuPaths, MenusRoutes } from "./menus";
import { UserPaths, UserRoutes } from "./user";
import { RootRedirect } from "../components/RootRedirect";

export const ApplicationRoutes = (
    <>
        <Route
            path="/"
            key="page-root"
            exact={true}
            component={() => <RootRedirect />}
        />

        {PublicRoutes}
        {DictionariesRoutes}
        {RecipesRoutes}
        {MenusRoutes}
        {AuthRoutes}
        {UserRoutes}
    </>
);

export const Paths = { root: "/", ...AuthPaths, ...UserPaths, ...MenuPaths, ...RecipePaths };
