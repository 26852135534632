import "./MenuForm.scss";
import { useFieldArray, useForm } from "react-hook-form";
import { IonButton, IonCard, IonIcon, IonItem } from "@ionic/react";
import { ControlledInput } from "../ControlledInput";
import { addCircleOutline, trashOutline } from "ionicons/icons";
import { RecipeMenuCard } from "./atoms/RecipeMenuCard";
import { ControlledDatePicker } from "../ControlledDatePicker";
import moment from "moment";
import { Prompt } from "react-router";
import classNames from "classnames";
import { ErrorLabel } from "../ErrorLabel";
import { Paths } from "../../pages/routes";
import { verifyIfNavigationNeedConfirmation } from "../../routerHelpers";

export const MenuForm = ({ data, submitForm, errors }) => {
    const { formState, register, handleSubmit, watch, control, getValues } = useForm({ defaultValues: data });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "days",
        keyName: "key",
    });
    const watchFieldArray = watch("days");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index],
        };
    });
    const form = { register, watch, control, appendDay: append, dayRemove: remove, getValues };
    const maxIndex = controlledFields.reduce((acc, curr) => Math.max(acc, curr.index), 0);
    const maxDate = controlledFields.reduce(
        (acc, curr) => (acc > moment(curr.date).utc() ? acc : moment(curr.date).utc()),
        moment.utc()
    );

    const daysClassNames = classNames("days", {
        "ion-invalid": errors.days,
        "ion-valid": !errors.days,
    });

    return (
        <form
            noValidate={true}
            onSubmit={handleSubmit(submitForm)}
            className="menu-form-component"
        >
            <Prompt
                when={formState.isDirty}
                message={verifyIfNavigationNeedConfirmation([Paths.menuAdd, Paths.menuUpdate])}
            />
            <div className="form-content">
                <div className="menu-inputs">
                    <ControlledInput
                        name="name"
                        placeholder="np. Jadłospis na tydzień, Impreza urodzinowa"
                        label="Nazwa"
                        register={register}
                        errors={errors}
                    />
                    <ControlledInput
                        name="defaultNumberOfEaters"
                        label="Domyślna liczba porcji"
                        inputType="decimal"
                        placeholder="np. 2"
                        register={register}
                        errors={errors}
                    />
                </div>
            </div>

            {controlledFields.map((item, index) => {
                const dayItem = `days.${index}`;
                return (
                    <DayComponent
                        dayIndex={index}
                        dayFieldName={dayItem}
                        key={item.key}
                        errors={errors}
                        form={form}
                    />
                );
            })}
            <IonItem
                key="addDay"
                className={daysClassNames}
            >
                <IonButton onClick={() => append({ name: "", date: maxDate.add(1, "d").format(), index: maxIndex + 1 })}>
                    Dodaj dzień
                </IonButton>
            </IonItem>
            <div className="form-content">
                <IonButton type="submit">Zapisz</IonButton>
            </div>
        </form>
    );
};

const DayComponent = ({ dayIndex, dayFieldName, errors, form: { register, watch, control, dayRemove, getValues } }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${dayFieldName}.recipes`,
        keyName: "key",
    });
    const watchFieldArray = watch(`${dayFieldName}.recipes`);
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index],
        };
    });

    const defaultNumberOfEaters = getValues("defaultNumberOfEaters");
    const maxIndex = controlledFields.reduce((acc, curr) => Math.max(acc, curr.index), 0);
    let errorText = errors[`${dayFieldName}.recipes`];
    const recipesClasses = classNames("recipes", {
        "ion-invalid": errorText,
        "ion-valid": !errorText,
    });
    return (
        <div className="day-component">
            <div
                key={dayFieldName}
                className="day-header"
            >
                <ControlledInput
                    className="day-name"
                    name={`${dayFieldName}.name`}
                    label="Nazwa"
                    register={register}
                    placeholder="np. środa"
                    errors={errors}
                />
                <ControlledDatePicker
                    name={`${dayFieldName}.date`}
                    label="Data"
                    errors={errors}
                    control={control}
                    className="day-date"
                />
                <div className="day-remove">
                    <IonButton
                        fill="clear"
                        color="danger"
                        onClick={() => dayRemove(dayIndex)}
                    >
                        <IonIcon
                            slot="icon-only"
                            icon={trashOutline}
                        ></IonIcon>{" "}
                    </IonButton>
                </div>
            </div>
            <div
                key={`${dayFieldName}.recipes`}
                className={recipesClasses}
            >
                {controlledFields.map((item, index) => (
                    <RecipeMenuCard
                        key={item.key}
                        recipeId={item.recipeId}
                        recipeField={`${dayFieldName}.recipes.${index}`}
                        index={index}
                        remove={remove}
                        register={register}
                        errors={errors}
                        control={control}
                    />
                ))}
                <ErrorLabel>{errorText}</ErrorLabel>
                <div className="recipe-menu-card-component">
                    <IonCard
                        button={true}
                        key={`${dayFieldName}.recipes.addRecipe}`}
                        onClick={() =>
                            append({
                                numberOfEaters: defaultNumberOfEaters,
                                index: maxIndex + 1,
                                slotName: "",
                                recipeId: null,
                            })
                        }
                    >
                        <div className="add-recipe-card">
                            <IonIcon
                                size="large"
                                icon={addCircleOutline}
                            ></IonIcon>
                            <span>Dodaj posiłek</span>
                        </div>
                    </IonCard>
                </div>
                <div className="recipe-menu-card-component"></div>
                <div className="recipe-menu-card-component"></div>
                <div className="recipe-menu-card-component"></div>
                <div className="recipe-menu-card-component"></div>
                <div className="recipe-menu-card-component"></div>
                <div className="recipe-menu-card-component"></div>
            </div>
        </div>
    );
};
