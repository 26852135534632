import "./FlexContainer.scss";

export const FlexContainer: React.FC<{ children: JSX.Element | JSX.Element[]; fakeChildClassName: string }> = ({
    children,
    fakeChildClassName,
}) => {
    return (
        <div className="flex-container-component">
            {children}
            <div className={fakeChildClassName} />
            <div className={fakeChildClassName} />
            <div className={fakeChildClassName} />
            <div className={fakeChildClassName} />
            <div className={fakeChildClassName} />
            <div className={fakeChildClassName} />
            <div className={fakeChildClassName} />
        </div>
    );
};
