import { Controller, useForm } from "react-hook-form";
import { IonButton, IonItem, IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { Redirect } from "react-router-dom";
import { LoadingOrb } from "../../components/LoadingOrb";
import { SomethingWentWrong } from "../../components/SomethingWentWrong";
import { useAddMenuInitialQuery, useAddMenuMutation } from "../../store/data/menuSlice";
import { useState } from "react";
import { CheckboxList } from "../../components/CheckboxList";
import { ControlledInput } from "../../components/ControlledInput";
import { MenuForm } from "../../components/forms/MenuForm";
import { ControlledDatePicker } from "../../components/ControlledDatePicker";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import moment from "moment";
import "./MenuAddForm.scss";

class ContainerProps {}

const presets = {
    "3 dania": ["Śniadanie", "Obiad", "Kolacja"],
    "5 dań": ["Śniadanie", "Drugie śniadanie", "Obiad", "Podwieczorek", "Kolacja"],
    Impreza: ["Drinki", "Przekąski"],
};
const meals = ["Śniadanie", "Drugie śniadanie", "Obiad", "Podwieczorek", "Kolacja", "Drinki", "Przekąski"];
const presetsKeys = Object.keys(presets);
let defaultPresetKey = presetsKeys[0];

export const MenuAddForm: React.FC<ContainerProps> = () => {
    const [menuInitialData, setMenuInitialData] = useState(null);

    const stepOneForm = <StepOneForm setMenuInitialData={setMenuInitialData} />;
    const stepTwoForm = <StepTwoForm menuInitialData={menuInitialData} />;

    return menuInitialData === null ? stepOneForm : stepTwoForm;
};

const StepOneForm = ({ setMenuInitialData }) => {
    var errors = {};
    const { register, handleSubmit, control, setValue } = useForm({
        defaultValues: {
            meals: presets[defaultPresetKey],
            startDate: moment.utc().add(1, "day").format(),
        } as any,
    });

    return (
        <form
            noValidate={true}
            onSubmit={handleSubmit((data) => setMenuInitialData(data))}
            className="menu-add-form-component"
        >
            <div className="form-content">
                <ControlledInput
                    name="name"
                    placeholder="np. Jadłospis na tydzień, Impreza urodzinowa"
                    label="Nazwa"
                    register={register}
                    errors={errors}
                />
                <div className="period-inputs">
                    <ControlledDatePicker
                        name="startDate"
                        label="Data początku jadłospisu"
                        errors={errors}
                        control={control}
                    />
                    <ControlledInput
                        name="numberOfDays"
                        placeholder="np. 7"
                        inputType="number"
                        label="Liczba dni"
                        register={register}
                        errors={errors}
                    />
                </div>
                <ControlledInput
                    name="defaultNumberOfEaters"
                    placeholder="np. 2"
                    inputType="decimal"
                    label="Liczba porcji"
                    register={register}
                    errors={errors}
                />
            </div>

            <div className="form-content">
                <p>Schemat posiłków</p>
                <IonItem key="presets">
                    <IonSegment value={defaultPresetKey}>
                        {presetsKeys.map((preset) => (
                            <IonSegmentButton
                                key={preset}
                                value={preset}
                                onClick={() => setValue("meals", presets[preset])}
                            >
                                <IonLabel>{preset}</IonLabel>
                            </IonSegmentButton>
                        ))}
                    </IonSegment>
                </IonItem>
                <Controller
                    key="meals"
                    name="meals"
                    control={control}
                    render={({ field }) => (
                        <CheckboxList
                            {...(field as any)}
                            items={meals.map((m) => ({ name: m, id: m }))}
                        ></CheckboxList>
                    )}
                />
            </div>
            <div className="form-content">
                <IonButton type="submit">Dalej</IonButton>
            </div>
        </form>
    );
};

const StepTwoForm = ({ menuInitialData }) => {
    const initial = useAddMenuInitialQuery({ body: { ...menuInitialData } });
    const [add, result] = useAddMenuMutation();

    if (result.isLoading) {
        return <LoadingOrb text="Zapisuję..." />;
    }
    if (result.error && result.error?.status !== 400) {
        return <SomethingWentWrong text="Coś poszło nie tak :(" />;
    }
    if (result.isSuccess) {
        return <Redirect to="/user/menus" />;
    }

    return (
        <DataLoadingWardenForQuery query={initial}>
            {() => (
                <MenuForm
                    data={result.originalArgs?.body || initial.data}
                    submitForm={(data) => add({ body: data })}
                    errors={result.error?.data || {}}
                />
            )}
        </DataLoadingWardenForQuery>
    );
};
