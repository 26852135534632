import { RouteComponentProps } from "react-router-dom";
import { FilterParameters, IQueryParameters, toQueryString } from "../../store/oDataHelpers";
import {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { cartOutline, closeOutline, globeOutline, pencilOutline } from "ionicons/icons";
import { useGetMenusQuery, useSetMenuPublicInitialQuery, useSetMenuPublicMutation } from "../../store/data/menuSlice";
import { MenuPreview } from "../../components/previews/MenuPreview";
import { mapMenu } from "../../to_remove_helpers";
import { environments } from "../../environments/environment";
import { useModalState } from "../../hooks/useModal";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { SetDocumentTitle } from "../../components/SetDocumentTitle";
import { useSelector } from "react-redux";
import { hasAdminPermission, hasOwnerPermission } from "../../store/authSlice";

export const MenuPreviewPage: React.FC<RouteComponentProps<{ id: string }>> = ({
    match: {
        params: { id },
    },
}) => {
    let qp: IQueryParameters = {};
    qp.filters = [new FilterParameters("id", "eq", id)];
    qp.expand = ["days", "user,days($expand=recipes($expand=recipe($expand=images)))"];
    const menu = useGetMenusQuery(toQueryString(qp));
    return (
        <DataLoadingWardenForQuery query={menu}>
            {() => {
                const m = mapMenu(menu.data.result[0]);
                return (
                    <>
                        <SetDocumentTitle title={m.name} />
                        <MenuPreview
                            {...m}
                            linkFactory={(recipeId, _, numberOfEaters) =>
                                `/recipes/preview/${recipeId}?numberOfEaters=${numberOfEaters}`
                            }
                        />
                    </>
                );
            }}
        </DataLoadingWardenForQuery>
    );
};

export const MenuPreviewPageButtons: React.FC<RouteComponentProps<{ id: string }>> = ({
    match: {
        params: { id },
    },
}) => {
    return (
        <>
            <IonButton routerLink={`/user/menus/shopping-list/${id}`}>
                <IonIcon
                    icon={cartOutline}
                    title="Lista zakupów"
                    color="dark"
                ></IonIcon>
                Lista zakupów
            </IonButton>
            <IonButton routerLink={`/user/menus/update/${id}`}>
                <IonIcon
                    icon={pencilOutline}
                    title="Edytuj"
                    color="dark"
                ></IonIcon>
                Edytuj
            </IonButton>
            <SetMenuPublic id={id} />
        </>
    );
};

const SetMenuPublic: React.FC<{ id: string }> = ({ id }) => {
    const [modalOpened, openModal, closeModal] = useModalState("public-menu");

    const parsedId = parseInt(id, 10);
    const initial = useSetMenuPublicInitialQuery({ id: parsedId });
    const [setPublic] = useSetMenuPublicMutation();

    let qp: IQueryParameters = {};
    qp.filters = [new FilterParameters("id", "eq", id)];
    qp.expand = ["days", "user,days($expand=recipes($expand=recipe($expand=images)))"];
    const menu = useGetMenusQuery(toQueryString(qp));
    const hasOwnerPermissions = useSelector(hasOwnerPermission(menu.data?.result[0].user.id));
    const hasAdminPermissions = useSelector(hasAdminPermission);

    return (
        <DataLoadingWardenForQuery
            query={menu}
            mode="control"
        >
            {() =>
                hasAdminPermissions ? (
                    <DataLoadingWardenForQuery
                        query={initial}
                        mode="control"
                    >
                        {() => (
                            <>
                                <IonButton onClick={() => openModal("public-menu")}>
                                    <IonIcon
                                        icon={globeOutline}
                                        title="Publikuj menu"
                                        color="dark"
                                    ></IonIcon>
                                    Publikuj menu
                                </IonButton>
                                <IonModal
                                    isOpen={modalOpened}
                                    onDidDismiss={() => closeModal("public-menu")}
                                >
                                    <IonHeader>
                                        <IonToolbar>
                                            <IonTitle>Udostępnij menu publicznie</IonTitle>
                                            <IonButtons slot="end">
                                                <IonButton
                                                    fill="clear"
                                                    strong={true}
                                                    onClick={() => closeModal("public-menu")}
                                                >
                                                    <IonIcon
                                                        slot="icon-only"
                                                        icon={closeOutline}
                                                    ></IonIcon>
                                                </IonButton>
                                            </IonButtons>
                                        </IonToolbar>
                                    </IonHeader>
                                    <IonContent>
                                        <IonItem>
                                            <IonLabel>Dostępne publicznie</IonLabel>
                                            <IonCheckbox
                                                checked={initial.data.isPublic}
                                                onClick={(e) => setPublic({ id: parsedId, isPublic: !initial.data.isPublic })}
                                            />
                                        </IonItem>
                                        <IonItem>
                                            <IonButton
                                                href={`${environments.MENU_PUBLIC_URL}/${id}`}
                                                target="_blank"
                                                fill="solid"
                                                disabled={!initial.data.isPublic}
                                            >
                                                Otwórz
                                            </IonButton>
                                        </IonItem>
                                    </IonContent>
                                </IonModal>
                            </>
                        )}
                    </DataLoadingWardenForQuery>
                ) : (
                    <></>
                )
            }
        </DataLoadingWardenForQuery>
    );
};
