import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "../firebase";
import { selectAuth, setToken, destroyUser } from "../store/authSlice";
import { LoadingOrb } from "./LoadingOrb";

export const UserGuard: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const [, loadingAuth, ] = useAuthState(auth);
    const userAuth = useSelector(selectAuth);
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) dispatch(setToken((currentUser as any).stsTokenManager.accessToken));
            else dispatch(destroyUser());
        });
        return unsubscribe;
    }, [dispatch]);

    if (loadingAuth || userAuth.isLoading) return <LoadingOrb text={"Ładowanie profilu..."} />;

    return children;
};
