import {
    IonButton,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
} from "@ionic/react";
import { RouteComponentProps } from "react-router-dom";
import { useGenerateShoppingListQuery, useSetIngredientStatusMutation } from "../../store/data/shoppingList";
import { RoundAmount } from "../../components/RoundAmount";
import { arrowUndoOutline, cartOutline, closeOutline, homeOutline } from "ionicons/icons";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import "./ShoppingListPage.scss";

const statusOrder = {
    None: 0,
    Bought: 1,
    NotAvailable: 2,
    CrossedOut: 3,
};
export const ShoppingListPage: React.FC<RouteComponentProps<{ id: string }>> = ({
    match: {
        params: { id },
    },
}) => {
    const listQuery = useGenerateShoppingListQuery({ id: parseInt(id) });

    return (
        <DataLoadingWardenForQuery
            query={listQuery}
            children={() => (
                <div className="shopping-list-page-component">
                    <p className="manual manual-green">Przesuń w prawo, żeby oznaczyć składnik jako kupiony.</p>
                    <p className="manual manual-red">
                        Przesuń w lewo, aby zapamiętać, że nie udało się kupić wybranego składnika.
                    </p>
                    <p className="manual manual-black">
                        Kliknij{" "}
                        <IonIcon
                            slot="icon-only"
                            icon={homeOutline}
                        ></IonIcon>
                        , aby zaznaczyć, że masz produkt w domu i nie musisz go kupować.
                    </p>
                    <p className="manual manual-black">
                        Przesuń w dowolną stronę, aby wycofać i przywrócić produkt na listę do kupienia.
                    </p>
                    <IonList>
                        {[...listQuery.data.categories]
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((category) => (
                                <IonItemGroup
                                    key={category.id}
                                    color="light"
                                    className="category-header"
                                >
                                    <IonItemDivider sticky={true}>
                                        <IonLabel>
                                            {category.name} ({category.ingredients.filter((i) => i.status !== "None").length} /{" "}
                                            {category.ingredients.length})
                                        </IonLabel>
                                    </IonItemDivider>
                                    {[...category.ingredients]
                                        .sort((a, b) => statusOrder[a.status] - statusOrder[b.status])
                                        .map((ingredient) => (
                                            <ShoppingListItem
                                                menuId={id}
                                                key={ingredient.id}
                                                ingredient={ingredient}
                                                category={category}
                                                menuGenerationId={listQuery.data.menuGenerationId}
                                            />
                                        ))}
                                </IonItemGroup>
                            ))}
                    </IonList>
                </div>
            )}
        />
    );
};

const ShoppingListItem: React.FC<{ menuId: string; ingredient: any; category: any; menuGenerationId: any }> = ({
    menuId,
    ingredient,
    category,
    menuGenerationId,
}) => {
    const [setStatus, result] = useSetIngredientStatusMutation();

    return (
        <IonItemSliding
            className={`ingredient-item ingredient-item--${ingredient.status}`}
            key={ingredient.id}
            onIonDrag={(e) => {
                if (e.detail.ratio === -1) {
                    e.target.close();
                    setStatus({
                        menuId: menuId,
                        ingredientId: ingredient.id,
                        categoryId: category.id,
                        menuGenerationId: menuGenerationId,
                        status: ingredient.status === "None" ? "Bought" : "None",
                    });
                }

                if (e.detail.ratio === 1) {
                    e.target.close();
                    setStatus({
                        menuId: menuId,
                        ingredientId: ingredient.id,
                        categoryId: category.id,
                        menuGenerationId: menuGenerationId,
                        status: ingredient.status === "None" ? "NotAvailable" : "None",
                    });
                }
            }}
        >
            <IonItemOptions side="start">
                <IonItemOption color={ingredient.status === "None" ? "success" : "light"}>
                    <IonIcon
                        slot="icon-only"
                        icon={ingredient.status === "None" ? cartOutline : arrowUndoOutline}
                    ></IonIcon>
                </IonItemOption>
            </IonItemOptions>
            <IonItem key={ingredient.id}>
                {ingredient.name} <RoundAmount amount={ingredient.amount} /> {ingredient.unitName}
                <IonButton
                    slot="end"
                    fill="clear"
                    color="medium"
                    onClick={(e) =>
                        setStatus({
                            menuId: menuId,
                            ingredientId: ingredient.id,
                            categoryId: category.id,
                            menuGenerationId: menuGenerationId,
                            status: "CrossedOut",
                        })
                    }
                >
                    <IonIcon
                        slot="icon-only"
                        icon={homeOutline}
                    ></IonIcon>
                </IonButton>
            </IonItem>
            <IonItemOptions slot="end">
                <IonItemOption
                    color="danger"
                    onClick={(e) =>
                        setStatus({
                            menuId: menuId,
                            ingredientId: ingredient.id,
                            categoryId: category.id,
                            menuGenerationId: menuGenerationId,
                            status: "NotAvailable",
                        })
                    }
                >
                    <IonIcon
                        slot="icon-only"
                        icon={closeOutline}
                    ></IonIcon>
                </IonItemOption>
            </IonItemOptions>
            <IonItemOptions side="end">
                <IonItemOption color={ingredient.status === "None" ? "danger" : "light"}>
                    <IonIcon
                        slot="icon-only"
                        icon={ingredient.status === "None" ? closeOutline : arrowUndoOutline}
                    ></IonIcon>
                </IonItemOption>
            </IonItemOptions>
        </IonItemSliding>
    );
};
