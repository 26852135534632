import { Redirect, RouteComponentProps } from "react-router-dom";
import { LoadingOrb } from "../../components/LoadingOrb";
import { SomethingWentWrong } from "../../components/SomethingWentWrong";
import { useUpdateIngredientInitialQuery, useUpdateIngredientMutation } from "../../store/data/ingredientsSlice";
import { IngredientForm } from "../../components/forms/IngredientForm";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";

interface ContainerProps extends RouteComponentProps<{ id: string }> {}

export const IngredientUpdateForm: React.FC<ContainerProps> = ({ match }) => {
    const id = parseInt(match.params.id, 10);
    const initial = useUpdateIngredientInitialQuery({ id });
    const [update, result] = useUpdateIngredientMutation();

    if (result.isLoading) {
        return <LoadingOrb text="Zapisuję..." />;
    }
    if (result.error && result.error?.status !== 400) {
        return <SomethingWentWrong text="Coś poszło nie tak :(" />;
    }
    if (result.isSuccess) {
        return <Redirect to="/dictionaries/ingredients" />;
    }
    return (
        <div className="ingredient-update-form-component form-content">
            <DataLoadingWardenForQuery query={initial}>
                {() => (
                    <IngredientForm
                        data={result.originalArgs?.body || initial.data}
                        submitForm={(data) => update({ id, body: data })}
                        errors={result.error?.data || {}}
                    />
                )}
            </DataLoadingWardenForQuery>
        </div>
    );
};
