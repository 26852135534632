import { ReactElement } from "react";
import { LoadingOrb } from "./LoadingOrb";

export const DataLoadingWardenForQuery: React.FC<{
    query: any;
    children: () => ReactElement<any, any>;
    mode?: "control" | "page";
}> = ({ query, children, mode }) => {
    return (
        <DataLoadingWarden
            children={children}
            mode={mode}
            isLoading={query.isLoading || !query.data}
            isError={query.isError}
        />
    );
};

export const DataLoadingWarden: React.FC<{
    isLoading: boolean;
    isError: boolean;
    isLoadingText?: string;
    children: () => ReactElement<any, any>;
    mode?: "control" | "page";
}> = ({ isLoading, isError, children, mode, isLoadingText }) => {
    mode = mode || "page";

    if (isError) return <>"Errorłem się!"</>;

    if (isLoading)
        return (
            <LoadingOrb
                text={isLoadingText}
                hideText={mode === "control"}
                mode={mode}
            />
        );

    return children();
};
