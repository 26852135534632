import "./RecipePicker.scss";
import { useState } from "react";
import { LoadingOrb } from "./LoadingOrb";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonModal,
    IonRippleEffect,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import classNames from "classnames";
import { closeOutline } from "ionicons/icons";
import { RecipeCard } from "./RecipeCard";
import { FlexContainer } from "./FlexContainer";
import { getIsLiked, getMainImageId } from "../to_remove_helpers";
import { useModalState } from "../hooks/useModal";
import { RecipeFilterForm } from "./forms/RecipeFilterForm";
import { useGetRecipesQuery } from "../store/data/recipeSlice";
import useInfiniteScroll from "../store/useInfiniteScroll";
import { ErrorLabel } from "./ErrorLabel";
import { DataLoadingWarden } from "./DataLoadingWardenForQuery";
import { selectPickerFilters, setPickerFilters } from "../store/filtersSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "../store/authSlice";

export interface RecipePickerProps {
    className?: string;
    value?: any | any[];
    onChange?: any;
    items: any[];
    labelSelector: any;
    valueSelector: any;
    isLoading: boolean;
    label: string;
    errors: any;
    name?: string;
    modalTitle: string;
    placeholder?: string;
}

export const RecipePicker: React.FC<RecipePickerProps> = (props: RecipePickerProps) => {
    const userId = useSelector(selectUserId);
    const [infiniteScrollEvent, setInfiniteScrollEvent] = useState<HTMLIonInfiniteScrollElement | undefined>(undefined);
    const filters = useSelector(selectPickerFilters);
    const dispatch = useDispatch();

    const {
        combinedData: data,
        readMore,
        totalCount,
        isLoading,
        canLoadMore,
    } = useInfiniteScroll(useGetRecipesQuery, { ...filters.queryParameters });

    const [modalOpened, openModal, closeModal] = useModalState(props.name!);

    let selectedItems: any[] = [];
    if (props.items && props.value) {
        selectedItems = [...props.items.filter((x) => props.valueSelector(x) === props.value)];
    }
    const errorText = props.errors[props.name!];
    const inputClasses = classNames(
        {
            "ion-invalid": errorText,
            "ion-valid": !errorText,
        },
        "recipe-picker-button ion-activatable ripple-parent rectangle",
        props.className
    );

    let modalContent;
    if (!isLoading && infiniteScrollEvent) {
        infiniteScrollEvent.complete();
    }
    if (props.isLoading) modalContent = <LoadingOrb text="Ładuję dane..." />;
    else
        modalContent = (
            <>
                <FlexContainer fakeChildClassName="recipe-card-component">
                    {data.map((i) => {
                        const imageUrl = getMainImageId(i);
                        const chips = [
                            { label: "Czas", value: `${i.overallTime} min` },
                            { label: "Porcje", value: `${i.numberOfEaters} os.` },
                        ];
                        const isLiked = getIsLiked(i, userId);
                        return (
                            <RecipeCard
                                key={i.id}
                                id={i.id}
                                name={i.name}
                                status={i.status}
                                chips={chips}
                                isLiked={isLiked}
                                onChange={() => {
                                    const currentValue = props.valueSelector(i);
                                    props.onChange && props.onChange(currentValue);
                                    closeModal(props.name!);
                                }}
                                parentName=""
                                routerLink=""
                                imageUrl={imageUrl}
                            />
                        );
                    })}
                </FlexContainer>
                <DataLoadingWarden
                    mode="page"
                    isLoading={isLoading}
                    isLoadingText={totalCount > 0 ? `Załadowane ${data.length} z ${totalCount} przepisów` : undefined}
                    isError={false}
                    children={() => (
                        <>
                            <IonInfiniteScroll
                                disabled={!canLoadMore}
                                onIonInfinite={(ev) => {
                                    if (!isLoading) {
                                        readMore();
                                        setInfiniteScrollEvent(ev.target as HTMLIonInfiniteScrollElement);
                                    }
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                            {!!data?.length && !canLoadMore && (
                                <span style={{ padding: "10px", textAlign: "center" }}>
                                    Pobrano wszystkie {data.length} przepisów
                                </span>
                            )}
                            {!data?.length && (
                                <IonItem>
                                    Nie znaleziono pasujących wyników, zmień kryteria wyszukiwania lub dodaj przepis.
                                </IonItem>
                            )}
                        </>
                    )}
                />
            </>
        );

    return (
        <div className="recipe-picker-component">
            <IonModal
                isOpen={modalOpened}
                onDidDismiss={() => closeModal(props.name!)}
                className="recipe-picker-modal"
            >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{props.modalTitle}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                strong={true}
                                onClick={() => closeModal(props.name!)}
                            >
                                <IonIcon
                                    slot="icon-only"
                                    icon={closeOutline}
                                ></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <RecipeFilterForm
                        value={filters}
                        setValue={(f) => dispatch(setPickerFilters(f))}
                    />
                    {modalContent}
                </IonContent>
            </IonModal>
            <div
                className={inputClasses}
                onClick={() => openModal(props.name!)}
            >
                <IonRippleEffect></IonRippleEffect>
                <span>{selectedItems.map((x) => props.labelSelector(x)).join(", ") || "Wybierz przepis..."}</span>
                <ErrorLabel>{errorText}</ErrorLabel>
            </div>
        </div>
    );
};
