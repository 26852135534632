import { RouteComponentProps } from "react-router-dom";
import { useHistory } from "react-router";
import { LazyImage } from "../../components/LazyImage";
import { IonButton, IonIcon } from "@ionic/react";
import { arrowBackOutline, arrowForwardOutline, closeOutline } from "ionicons/icons";
import "./Onboarding.scss";
import { meApi, useSetOnboardingDisplayedMutation } from "../../store/data/meSlice";
import { useDispatch } from "react-redux";

const steps = {
    1: {
        title: "Witaj w FeedMe!",
        text: "Zmienimy Twoje podejście do gotowania i zakupów!",
        imageSrc: "/assets/onboarding/1.png",
        next: 2,
        previous: null,
    },
    2: {
        title: "Dodaj lub wyszukaj przepisy",
        text: "Udostępnij swoje ulubione przepisy lub znajdź inspiracje od innych użytkowników filtrując przepisy po składnikach, które masz w lodówce, porze dnia itp…",
        imageSrc: "/assets/onboarding/2.png",
        next: 3,
        previous: 1,
    },
    3: {
        title: "Ułóż jadłospis",
        text: "Zaplanuj posiłki wybranych przepisów na cały tydzień, imprezę, dla singla lub całej rodziny – zgodnie ze swoim stylem życia.",
        imageSrc: "/assets/onboarding/3.png",
        next: 4,
        previous: 2,
    },
    4: {
        title: "Zrób zakupy z gotową listą",
        text: " Idź na zakupy z listą podzieloną na kategorie składników, odznaczając kupione i niedostępne.",
        imageSrc: "/assets/onboarding/4.png",
        next: 5,
        previous: 3,
    },
    5: {
        title: "Gotuj z aplikacją na telefonie",
        text: "Korzystaj z wygodnego podglądu jadłospisu i przepisów na telefonie i ciesz się gotowaniem.",
        imageSrc: "/assets/onboarding/5.png",
        next: null,
        previous: 4,
    },
};

export const Onboarding: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
    const id = parseInt(match?.params?.id || "1", 10);
    const currentStep = steps[id] || steps[1];

    const router = useHistory();
    const [set, result] = useSetOnboardingDisplayedMutation();

    return (
        <>
            <img
                src={steps[1].imageSrc}
                style={{ display: "none" }}
            />
            <img
                src={steps[2].imageSrc}
                style={{ display: "none" }}
            />
            <img
                src={steps[3].imageSrc}
                style={{ display: "none" }}
            />
            <img
                src={steps[4].imageSrc}
                style={{ display: "none" }}
            />
            <img
                src={steps[5].imageSrc}
                style={{ display: "none" }}
            />
            <div className="onboarding-component form-content">
                <IonButton
                    fill="clear"
                    color="medium"
                    strong={true}
                    className="close-button"
                    onClick={() => {
                        set({});
                        router.push("/user/start-page");
                    }}
                >
                    <IonIcon
                        slot="icon-only"
                        icon={closeOutline}
                    ></IonIcon>
                </IonButton>
                <h3>{currentStep?.title}</h3>
                <LazyImage
                    className="onboarding-image"
                    src={currentStep?.imageSrc}
                />
                <p>{currentStep?.text}</p>
                <div className="onboarding-buttons">
                    <IonButton
                        disabled={currentStep.previous === null}
                        fill="clear"
                        onClick={() => router.push(`/user/onboarding/${currentStep.previous}`)}
                    >
                        <IonIcon icon={arrowBackOutline} />
                        Poprzedni
                    </IonButton>
                    {currentStep.next !== null && (
                        <IonButton
                            fill="clear"
                            onClick={() => router.push(`/user/onboarding/${currentStep.next}`)}
                        >
                            Następny
                            <IonIcon icon={arrowForwardOutline} />
                        </IonButton>
                    )}
                    {currentStep.next === null && (
                        <IonButton
                            fill="clear"
                            onClick={() => {
                                set({});
                                router.push("/user/start-page");
                            }}
                        >
                            Zaczynamy!
                        </IonButton>
                    )}
                </div>
            </div>
        </>
    );
};
