import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { environments } from "../../environments/environment";
import { getToken } from "../../firebase";

const entityName = "Menu";
const tagName = `${entityName}s`;
const entityNamePlural = tagName;
const reducerPath = "menusApi";
const queryForList = (params: string) => `query/menus?` + params;

export const menusApi = createApi({
    reducerPath: reducerPath,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.API_URL,
        prepareHeaders: async (headers, api) => {
            const token = await getToken();

            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
        },
    }),
    tagTypes: [tagName],
    endpoints: (builder) => {
        let eps = {};
        eps[`get${entityNamePlural}`] = builder.query<any, string>({
            query: queryForList,
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`add${entityName}Initial`] = builder.query<any, { body?: any }>({
            query: ({ body }) => ({
                url: `commands/getInitial/Add${entityName}/`,
                method: "POST",
                body: { ...body },
            }),
        });
        eps[`add${entityName}`] = builder.mutation<any, { body?: any }>({
            query: ({ body }) => ({
                url: `commands/execute/Add${entityName}/`,
                method: "POST",
                body: { ...body },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        eps[`update${entityName}Initial`] = builder.query<any, { id: number; body?: any }>({
            query: ({ id, body }) => ({
                url: `commands/getInitial/Update${entityName}/`,
                method: "POST",
                body: { id, ...body },
            }),
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`update${entityName}`] = builder.mutation<any, { id: number; body?: any }>({
            query: ({ id, body }) => ({
                url: `commands/execute/Update${entityName}/`,
                method: "POST",
                body: { id, ...body },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        eps[`setMenuPublicInitial`] = builder.query<any, { id: number }>({
            query: ({ id }) => ({
                url: `commands/getInitial/SetMenuPublic/`,
                method: "POST",
                body: { id },
            }),
            //TODO change this to refresh only public status
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`setMenuPublic`] = builder.mutation<any, { id: number; isPublic: boolean }>({
            query: ({ id, isPublic }) => ({
                url: `commands/execute/SetMenuPublic/`,
                method: "POST",
                body: { id, isPublic },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        eps[`delete${entityName}`] = builder.mutation<any, { id: number }>({
            query: ({ id }) => ({
                url: `commands/execute/Delete${entityName}/`,
                method: "POST",
                body: { id },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        return eps;
    },
});

export const {
    useGetMenusQuery,
    useAddMenuInitialQuery,
    useAddMenuMutation,
    useUpdateMenuInitialQuery,
    useUpdateMenuMutation,
    useSetMenuPublicInitialQuery,
    useSetMenuPublicMutation,
    useDeleteMenuMutation,
} = menusApi as any;
