import { useFieldArray, useForm } from "react-hook-form";
import { IonButton, IonIcon, IonItemDivider, IonList } from "@ionic/react";
import { ControlledInput } from "../ControlledInput";
import { addOutline, trashOutline } from "ionicons/icons";
import { ControlledPicker } from "../ControlledPicker";
import { useGetIngredientCategoriesForDictQuery } from "../../store/data/ingredientCategoriesSlice";
import { useGetUnitsForDictQuery } from "../../store/data/unitsSlice";
import { DataLoadingWardenForQuery } from "../DataLoadingWardenForQuery";
import "./IngredientForm.scss";
import { RoundAmount } from "../RoundAmount";

export const IngredientForm = ({ data, submitForm, errors }) => {
    const ingredientCategories = useGetIngredientCategoriesForDictQuery();
    const units = useGetUnitsForDictQuery();

    const { register, handleSubmit, control, watch, getValues } = useForm({ defaultValues: data });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "unitTranslations",
        keyName: "key",
    });
    const watchFieldArray = watch("unitTranslations");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index],
        };
    });
    return (
        <form
            noValidate={true}
            onSubmit={handleSubmit(submitForm)}
        >
            <IonList className="ingredient-form-component">
                <ControlledInput
                    name="name"
                    label="Nazwa"
                    register={register}
                    errors={errors}
                />
                <ControlledInput
                    name="expirationPeriod"
                    label="Okres przydatności do spożycia"
                    inputType="number"
                    register={register}
                    errors={errors}
                >
                    <span style={{padding: "7px", height: "100%"}}>dni</span>
                </ControlledInput>
                <DataLoadingWardenForQuery query={units}>
                    {() => (
                        <ControlledPicker
                            name="baseUnitId"
                            label="Główna jednostka"
                            closeOnChange={true}
                            modalTitle="Wybierz jednostkę"
                            errors={errors}
                            isLoading={false}
                            items={units.data.result}
                            control={control}
                        />
                    )}
                </DataLoadingWardenForQuery>
                <DataLoadingWardenForQuery query={ingredientCategories}>
                    {() => (
                        <ControlledPicker
                            name="categoryId"
                            label="Kategoria"
                            closeOnChange={true}
                            modalTitle="Wybierz kategorię"
                            errors={errors}
                            isLoading={false}
                            items={ingredientCategories.data.result}
                            control={control}
                        />
                    )}
                </DataLoadingWardenForQuery>
                <p>Alternatywne jednostki</p>
                {controlledFields.map((field, index) => {
                    const unitName = units.data.result.find((unit) => unit.id === field.unitId)?.name;
                    const mainUnitName = units.data.result.find((unit) => unit.id === getValues("baseUnitId"))?.name;
                    return (
                        <div
                            className="unit-translations"
                            key={field.key}
                        >
                            <DataLoadingWardenForQuery query={units}>
                                {() => (
                                    <ControlledPicker
                                        name={`unitTranslations.${index}.unitId` as const}
                                        label="Jednostka"
                                        closeOnChange={true}
                                        modalTitle="Wybierz jednostkę"
                                        className="unit-translations-unit"
                                        errors={errors}
                                        isLoading={false}
                                        items={units.data.result}
                                        control={control}
                                    />
                                )}
                            </DataLoadingWardenForQuery>
                            <ControlledInput
                                name={`unitTranslations.${index}.value`}
                                label="Wartość"
                                className="unit-translations-value"
                                register={register}
                                inputType="decimal"
                                errors={errors}
                            />
                            <IonButton
                                className="unit-translations-remove"
                                key={`unitTranslations.${index}.remove`}
                                onClick={() => remove(index)}
                                color="danger"
                                fill="clear"
                            >
                                <IonIcon
                                    slot="icon-only"
                                    icon={trashOutline}
                                ></IonIcon>
                            </IonButton>
                            {mainUnitName && field.value && mainUnitName && (
                                <span>
                                    Czyli: 1 {mainUnitName} to {field.value} {unitName} i <RoundAmount amount={1 / field.value} />{" "}
                                    {mainUnitName} to 1 {unitName}
                                </span>
                            )}
                        </div>
                    );
                })}
                <IonButton
                    onClick={() => append({})}
                    fill="outline"
                >
                    <IonIcon
                        slot="start"
                        icon={addOutline}
                    />
                    Dodaj jednostkę
                </IonButton>
                <IonItemDivider />
                <IonButton type="submit">Zapisz</IonButton>
            </IonList>
        </form>
    );
};
