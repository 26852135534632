import { useParams, useLocation } from "react-router-dom";
import { RecipePreview } from "../../components/previews/RecipePreview";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { SetDocumentTitle } from "../../components/SetDocumentTitle";
import { useGetMenuRecipeByIdsQuery } from "../../store/data/publicSlice";

export const RecipePreviewPublicPage: React.FC<{}> = () => {
    const recipeId = (useParams() as any).id;
    const menuId = (useParams() as any).menuId;

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const numberOfEaters = searchParams.get("numberOfEaters") || "";

    const recipe = useGetMenuRecipeByIdsQuery({ recipeId, menuId });

    return (
        <div className="recipe-preview-page-component">
            <DataLoadingWardenForQuery query={recipe}>
                {() => (
                    <>
                        <SetDocumentTitle title={recipe.data.name} />
                        <RecipePreview
                            recipe={recipe.data}
                            numberOfEaters={parseFloat(numberOfEaters)}
                        />
                    </>
                )}
            </DataLoadingWardenForQuery>
        </div>
    );
};
