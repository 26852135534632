import "./Register.scss";
import { useState } from "react";
import { addUserData, logInWithGoogle, registerWithEmailAndPassword } from "../../firebase";
import { IonButton, IonCheckbox, IonIcon, IonRouterLink, IonText, useIonRouter } from "@ionic/react";
import { logoGoogle } from "ionicons/icons";
import { useForm } from "react-hook-form";
import { ControlledInput } from "../../components/ControlledInput";
import { useUpdateProfileMutation } from "../../store/data/meSlice";
import { InputWrapper } from "../../components/InputWrapper";

interface ContainerProps {}

export const Register: React.FC<ContainerProps> = () => {
    const [update, result] = useUpdateProfileMutation();
    const router = useIonRouter();
    const [error, setError] = useState<string | undefined>(undefined);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: { email: "", password: "" } });
    const formSubmit = async (data) => {
        if (data.password !== data.repeatedPassword) {
            setError("Podane hasła nie są takie same!");
            return;
        }

        const error = await registerWithEmailAndPassword(data.name, data.email, data.password);

        if (error) {
            setError(error);
        } else {
            router.push("/", "root");
            update({ displayName: data.name });
            await addUserData({ displayName: data.name, emailAddress: data.email });
        }
    };

    return (
        <div className="register-component">
            <form
                noValidate={true}
                onSubmit={handleSubmit(formSubmit)}
            >
                <ControlledInput
                    name="name"
                    label="Nazwa"
                    register={register}
                    validationRules={{ required: true }}
                    errors={errors}
                    placeholder="Jan Kowalski"
                />
                <ControlledInput
                    name="email"
                    label="E-mail"
                    register={register}
                    validationRules={{ required: true }}
                    errors={errors}
                    placeholder="abc@gmail.com"
                />
                <ControlledInput
                    name="password"
                    label="Hasło"
                    inputType="password"
                    register={register}
                    validationRules={{ required: true }}
                    errors={errors}
                    placeholder="TajneHasło1234"
                />
                <ControlledInput
                    name="repeatedPassword"
                    label="Powtórz hasło"
                    inputType="password"
                    register={register}
                    validationRules={{ required: true }}
                    errors={errors}
                    placeholder="TajneHasło1234"
                />
                <InputWrapper className="terms-and-conditions">
                    <IonCheckbox labelPlacement="end">Rejestrując się, akceptuję regulamin dostępny </IonCheckbox>
                    <a
                        target="_blank"
                        href="https://www.feed-me.app/terms-and-conditions/"
                        onClick={(e) => e.stopPropagation()}
                    >
                        tutaj
                    </a>
                </InputWrapper>
                {!!error && (
                    <IonText
                        color="danger"
                        style={{ fontSize: "0.7em" }}
                    >
                        {error}
                    </IonText>
                )}
                <IonButton
                    expand="full"
                    type="submit"
                >
                    Zarejestruj
                </IonButton>
                <IonButton
                    expand="full"
                    onClick={logInWithGoogle}
                >
                    Zarejestruj przez Google
                    <IonIcon
                        slot="start"
                        icon={logoGoogle}
                    ></IonIcon>
                </IonButton>
                <div>
                    Masz już konto? <IonRouterLink routerLink="/login">Zaloguj się</IonRouterLink>
                </div>
            </form>
        </div>
    );
};
