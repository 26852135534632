import "./Profile.scss";
import { IonButton, IonList, useIonRouter } from "@ionic/react";
import { logout } from "../../firebase";
import { destroyUser, selectAuth } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";

interface ContainerProps {}

export const Profile: React.FC<ContainerProps> = () => {
    const profile = useSelector(selectAuth);
    const dispatch = useDispatch();
    const router = useIonRouter();

    return (
        <div className="profile">
            <IonList>
                <h1>Profil</h1>
                <p>Nazwa: {profile?.user.name}</p>
                <p>Id: {profile?.user.id}</p>
                <p>Rola: {profile?.user.role}</p>
                <p>Data onboardingu: {profile?.user.onboardingDisplayed}</p>
                <IonButton
                    expand="full"
                    onClick={async () => {
                        await logout();
                        dispatch(destroyUser());
                        router.push("/login", "root");
                    }}
                >
                    Wyloguj się
                </IonButton>
            </IonList>
        </div>
    );
};
