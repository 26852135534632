import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { environments } from "../../environments/environment";
import { getToken } from "../../firebase";

const reducerPath = "shoppingListApi";

export const shoppingListApi = createApi({
    reducerPath: reducerPath,
    tagTypes: ["shoppingList"],
    baseQuery: fetchBaseQuery({
        baseUrl: environments.API_URL,
        prepareHeaders: async (headers, api) => {
            const token = await getToken();

            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
        },
    }),
    endpoints: (builder) => {
        return {
            generateShoppingList: builder.query<any, { id: number }>({
                query: ({ id }) => ({
                    url: `commands/execute/GenerateShoppingList/`,
                    method: "POST",
                    body: { menuId: id },
                }),
                providesTags: [{ type: "shoppingList", id: "LIST" }],
            }),
            setIngredientStatus: builder.mutation<any, { status; ingredientId; categoryId; menuGenerationId; menuId }>({
                query: ({ status, ingredientId, categoryId, menuGenerationId, menuId }) => ({
                    url: `commands/execute/SetShoppingListIngredientStatus/`,
                    method: "POST",
                    body: {
                        status,
                        clientTimestamp: new Date().toISOString(),
                        ingredientId,
                        categoryId,
                        menuGenerationId,
                    },
                }),
                invalidatesTags: [{ type: "shoppingList", id: "LIST" }],
            }),
        };
    },
});

export const { useGenerateShoppingListQuery, useSetIngredientStatusMutation } = shoppingListApi;
