import "./RecipeFilterForm.scss";
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonFooter,
    IonItem,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonInput,
} from "@ionic/react";
import { closeOutline, filterOutline, funnelOutline } from "ionicons/icons";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useModalState } from "../../hooks/useModal";
import { selectUser } from "../../store/authSlice";
import { useGetIngredientsForDictQuery } from "../../store/data/ingredientsSlice";
import { useGetRecipeCategoriesForDictQuery } from "../../store/data/recipeCategoriesSlice";
import { useGetTagsForDictQuery } from "../../store/data/tagsSlice";
import { FilterParameters, IQueryParameters } from "../../store/oDataHelpers";
import { ControlledInput } from "../ControlledInput";
import { ControlledPicker } from "../ControlledPicker";
import { DataLoadingWardenForQuery } from "../DataLoadingWardenForQuery";
import { RecipeTypeButtons, recipeType } from "../RecipeTypePicker";
import { ControlledToggle } from "../ControlledToggle";
import { Badge } from "../Badge";
import React, { useMemo } from "react";
import { debounce } from "lodash";

const statuses = [
    { id: "Private", name: "Prywatny" },
    { id: "InReview", name: "W moderacji" },
    { id: "Rejected", name: "Odrzucony" },
    { id: "Public", name: "Publiczny" },
];

const statusesDictionary: { [key: string]: string } = {
    Private: "Prywatny",
    InReview: "W moderacji",
    Rejected: "Odrzucony",
    Public: "Publiczny",
};

const orderByOptions: { id: string; label: string; name: string; order: "asc" | "desc" }[] = [
    {
        id: "date-asc",
        label: "Data",
        name: "modifiedDate",
        order: "asc",
    },
    {
        id: "date-desc",
        label: "Data",
        name: "modifiedDate",
        order: "desc",
    },
    {
        id: "name-asc",
        label: "Nazwa",
        name: "name",
        order: "asc",
    },
    {
        id: "name-desc",
        label: "Nazwa",
        name: "name",
        order: "desc",
    },
];

const initialFilterData = {
    name: "",
    type: "all",
    ingredients: [],
    ingredientsOperator: "or",
    tags: [],
    tagsOperator: "or",
    categories: [],
    categoriesOperator: "or",
    statuses: [],
    orderBy: "date-desc",
};

const anyFiltersSet = (data) => {
    return (
        data && (data.categories?.length || data.statuses?.length || data.tags?.length || data.ingredients?.length || data.name)
    );
};

export const RecipeFilterForm: React.FC<{ setValue; value }> = ({ setValue, value }) => {
    const user = useSelector(selectUser);
    const recipeCategories = useGetRecipeCategoriesForDictQuery();
    const ingredients = useGetIngredientsForDictQuery();
    const tags = useGetTagsForDictQuery();

    let recipeCategoriesDictionary: { [key: number]: string } | undefined;
    if (recipeCategories.data?.result?.length > 0 && !recipeCategoriesDictionary) {
        recipeCategoriesDictionary = {};
        recipeCategories.data?.result?.forEach((x) => (recipeCategoriesDictionary![x.id] = x.name));
    }

    let tagsDictionary: { [key: number]: string } | undefined;
    if (tags.data?.result?.length > 0 && !tagsDictionary) {
        tagsDictionary = {};
        tags.data?.result?.forEach((x) => (tagsDictionary![x.id] = x.name));
    }

    let ingredientsDictionary: { [key: number]: string } | undefined;
    if (ingredients.data?.result?.length > 0 && !ingredientsDictionary) {
        ingredientsDictionary = {};
        ingredients.data?.result?.forEach((x) => (ingredientsDictionary![x.id] = x.name));
    }

    const [modalOpened, openModal, closeModal] = useModalState("recipe-filters");

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue: setFormValue,
    } = useForm({
        defaultValues: value.data || initialFilterData,
    });
    const onSubmit = (data: any) => {
        if (!data) {
            const qp: IQueryParameters = {};
            qp.pageSize = value.queryParameters.pageSize;
            qp.page = 1;
            setValue({ data: undefined, queryParameters: qp });
            return;
        }

        let f: FilterParameters[] = [];
        if (data.name) f.push(new FilterParameters("name", "contains", data.name));
        if (data.ingredients?.length)
            f.push(new FilterParameters("ingredients", "in", data.ingredients, "ingredientId", data.ingredientsOperator));
        if (data.tags?.length) f.push(new FilterParameters("tags", "in", data.tags, "tagId", data.tagsOperator));
        if (data.statuses?.length) f.push(new FilterParameters("status", "in", data.statuses));
        if (data.categories?.length)
            f.push(new FilterParameters("categories", "in", data.categories, "recipeCategoryId", data.categoriesOperator));
        if (data.type === "my") f.push(new FilterParameters("author/id", "eq", user?.id));
        if (data.type === "favorites") f.push(new FilterParameters("likes/any(c:c/userId  eq " + user?.id + ")"));

        const orderBy = orderByOptions.find((x) => x.id === data.orderBy);
        let qp: IQueryParameters = {};
        qp.filters = f;
        qp.orderBy = orderBy?.name;
        qp.order = orderBy?.order;
        qp.pageSize = value.queryParameters.pageSize;
        qp.page = 1;
        setValue({ data: data, queryParameters: qp });
    };
    const debouncedOnSubmit = useMemo(() => debounce(onSubmit, 300), []);
    return (
        <form
            noValidate={true}
            onSubmit={handleSubmit(onSubmit)}
            className="recipe-filter-form-component"
        >
            <IonModal
                className="recipe-filter-form-modal"
                isOpen={modalOpened}
                onDidDismiss={() => closeModal("recipe-filters")}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Filtry</IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                strong={true}
                                onClick={() => closeModal("recipe-filters")}
                            >
                                <IonIcon
                                    slot="icon-only"
                                    icon={closeOutline}
                                />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div className="form-content">
                        <ControlledInput
                            name="name"
                            label="Nazwa"
                            register={register}
                            errors={errors}
                        />
                        <div className="dropdown-with-toogle">
                            <DataLoadingWardenForQuery query={ingredients}>
                                {() => (
                                    <ControlledPicker
                                        name="ingredients"
                                        closeOnChange={false}
                                        label="Składniki"
                                        control={control}
                                        errors={errors}
                                        isMulti={true}
                                        modalTitle="Wybierz składniki"
                                        items={ingredients.data.result}
                                        isLoading={false}
                                    />
                                )}
                            </DataLoadingWardenForQuery>
                            <ControlledToggle
                                control={control}
                                name="ingredientsOperator"
                                values={["and", "or"]}
                                labels={["wszystkie wybrane", "jeden z wybranych"]}
                            />
                        </div>
                        <div className="dropdown-with-toogle">
                            <DataLoadingWardenForQuery query={tags}>
                                {() => (
                                    <ControlledPicker
                                        name="tags"
                                        label="Tagi"
                                        control={control}
                                        closeOnChange={false}
                                        errors={errors}
                                        isMulti={true}
                                        modalTitle="Wybierz tagi"
                                        items={tags.data.result}
                                        isLoading={false}
                                    />
                                )}
                            </DataLoadingWardenForQuery>
                            <ControlledToggle
                                control={control}
                                name="tagsOperator"
                                values={["and", "or"]}
                                labels={["wszystkie wybrane", "jeden z wybranych"]}
                            />
                        </div>
                        <div className="dropdown-with-toogle">
                            <DataLoadingWardenForQuery query={recipeCategories}>
                                {() => (
                                    <ControlledPicker
                                        name="categories"
                                        label="Kategorie"
                                        control={control}
                                        closeOnChange={false}
                                        errors={errors}
                                        isMulti={true}
                                        modalTitle="Wybierz kategorie"
                                        items={recipeCategories.data.result}
                                        isLoading={false}
                                    />
                                )}
                            </DataLoadingWardenForQuery>
                            <ControlledToggle
                                control={control}
                                name="categoriesOperator"
                                values={["and", "or"]}
                                labels={["wszystkie wybrane", "jeden z wybranych"]}
                            />
                        </div>
                        {(user?.role === "moderator" || user?.role === "admin") && (
                            <ControlledPicker
                                name="statuses"
                                label="Status"
                                closeOnChange={false}
                                control={control}
                                errors={errors}
                                isMulti={true}
                                modalTitle="Wybierz statusy"
                                items={statuses}
                                isLoading={false}
                            />
                        )}
                    </div>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButton
                            expand="block"
                            onClick={() => {
                                handleSubmit(onSubmit)();
                                closeModal("recipe-filters");
                            }}
                        >
                            Zastosuj
                        </IonButton>
                    </IonToolbar>
                </IonFooter>
            </IonModal>
            <div className="filters-bar">
                <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <IonSearchbar
                            inputMode="search"
                            value={value}
                            placeholder="Szukaj..."
                            onIonInput={(e: any) => {
                                onChange(e.target.value!);
                                handleSubmit(debouncedOnSubmit)();
                            }}
                        />
                    )}
                />
                <div className="buttons-container">
                    <Controller
                        name="orderBy"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <IonSelect
                                interface="popover"
                                value={value}
                                onIonChange={(e) => {
                                    onChange(e.detail.value);
                                    handleSubmit(onSubmit)();
                                }}
                            >
                                {orderByOptions.map((o) => (
                                    <IonSelectOption
                                        key={o.id}
                                        value={o.id}
                                    >
                                        {o.order === "asc" && <>&#8595;</>}
                                        {o.order === "desc" && <>&#8593;</>} {o.label}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        )}
                    />
                    <IonButton
                        fill="clear"
                        onClick={() => {
                            openModal("recipe-filters");
                        }}
                    >
                        <IonIcon
                            slot="icon-only"
                            icon={funnelOutline}
                        />
                        Filtry
                    </IonButton>
                </div>
            </div>
            {anyFiltersSet(value.data) && (
                <div className="filter-summary">
                    {value.data.name && (
                        <Badge
                            name={`nazwa: ${value.data.name}`}
                            onClick={() => {
                                setFormValue("name", "");
                                onSubmit({ ...value.data, name: "" });
                            }}
                        />
                    )}
                    {!!value.data.ingredients?.length &&
                        value.data.ingredients.map((i) => (
                            <Badge
                                key={i}
                                name={(ingredientsDictionary && ingredientsDictionary[i]) || ""}
                                onClick={() => {
                                    const ingredients = value.data.ingredients?.filter((ing) => ing !== i);
                                    setFormValue("ingredients", ingredients);
                                    onSubmit({ ...value.data, ingredients: ingredients });
                                }}
                            />
                        ))}
                    {!!value.data.tags?.length &&
                        value.data.tags.map((i) => (
                            <Badge
                                key={i}
                                name={(tagsDictionary && tagsDictionary[i]) || ""}
                                onClick={() => {
                                    const tags = value.data.tags?.filter((ing) => ing !== i);
                                    setFormValue("tags", tags);
                                    onSubmit({ ...value.data, tags: tags });
                                }}
                            />
                        ))}
                    {!!value.data.categories?.length &&
                        value.data.categories.map((i) => (
                            <Badge
                                key={i}
                                name={(recipeCategoriesDictionary && recipeCategoriesDictionary[i]) || ""}
                                onClick={() => {
                                    const categories = value.data.categories?.filter((ing) => ing !== i);
                                    setFormValue("categories", categories);
                                    onSubmit({ ...value.data, categories: categories });
                                }}
                            />
                        ))}
                    {!!value.data.statuses?.length &&
                        value.data.statuses.map((i) => (
                            <Badge
                                key={i}
                                name={statusesDictionary[i]}
                                onClick={() => {
                                    const statuses = value.data.statuses?.filter((ing) => ing !== i);
                                    setFormValue("statuses", statuses);
                                    onSubmit({ ...value.data, statuses: statuses });
                                }}
                            />
                        ))}
                    <Badge
                        className="clear-filters"
                        name="Wyczyść filtry"
                        onClick={() => {
                            reset();
                            setFormValue("type", value.data.type);
                            setFormValue("orderBy", initialFilterData.orderBy);
                            onSubmit({ ...initialFilterData, type: value.data.type });
                        }}
                    />
                    <div className="fake-item" />
                    <div className="fake-item" />
                    <div className="fake-item" />
                    <div className="fake-item" />
                    <div className="fake-item" />
                    <div className="fake-item" />
                    <div className="fake-item" />
                    <div className="fake-item" />
                </div>
            )}
            <Controller
                name="type"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <RecipeTypeButtons
                        value={value as recipeType}
                        onChange={(e) => {
                            onChange(e);
                            handleSubmit(onSubmit)();
                        }}
                    />
                )}
            />
        </form>
    );
};
