import { environments } from "../environments/environment";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";
import { useIonRouter } from "@ionic/react";
import { Paths } from "../pages/routes";

export const RootRedirect: React.FC = () => {
    const userAuth = useSelector(selectAuth);
    const router = useIonRouter();

    if (userAuth.user.role === "anonymous") {
        router.routeInfo.pathname !== Paths.login && router.push(Paths.login, "root");
    }

    if (userAuth.user.role !== "anonymous") {
        if (userAuth.user.onboardingDisplayed) {
            router.routeInfo.pathname !== Paths.startPage && router.push(Paths.startPage, "root");
        } else {
            router.routeInfo.pathname !== Paths.onboarding && router.push(Paths.onboarding.replace(":id", "1"), "root");
        }
    }

    return null;
};
