import React from "react";

export const TextWithNewLines: React.FC<{ text: string }> = ({ text }) => {
    let textLines = text.split("\n");
    if (textLines.length > 1)
        return (
            <>
                {textLines.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </>
        );
    return <p>{text}</p>;
};
