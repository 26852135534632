import "./RecipePreviewPage.scss";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { RecipePreview } from "../../components/previews/RecipePreview";
import {
    useAddReviewMutation,
    useGetRecipesQuery,
    useSendRecipeToReviewInitialQuery,
    useSendRecipeToReviewMutation,
    useToggleLikeMutation,
} from "../../store/data/recipeSlice";
import { FilterParameters, IQueryParameters, toQueryString } from "../../store/oDataHelpers";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonModal,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { alertCircleOutline, closeOutline, heart, heartOutline, pencilOutline } from "ionicons/icons";
import { getAllIngredients, getImageIds, getIsLiked, getMainImageId } from "../../to_remove_helpers";
import { useEffect, useState } from "react";
import { useModalState } from "../../hooks/useModal";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { SetDocumentTitle } from "../../components/SetDocumentTitle";
import { useSelector } from "react-redux";
import { hasOwnerPermission, selectUser } from "../../store/authSlice";
import { RecipeStatusIcon } from "../../components/RecipeStatusIcon";

export const RecipePreviewPage: React.FC<RouteComponentProps<any>> = ({
    match: {
        params: { id },
    },
}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const numberOfEaters = searchParams.get("numberOfEaters") || "";

    let qp: IQueryParameters = {};
    qp.filters = [new FilterParameters("id", "eq", id)];
    const recipe = useGetRecipesQuery(toQueryString(qp));

    return (
        <div className="recipe-preview-page-component">
            <DataLoadingWardenForQuery query={recipe}>
                {() => {
                    let r = { ...recipe.data.result[0] };
                    r.mainImageId = getMainImageId(r);
                    r.imagesIds = getImageIds(r);
                    r.allIngredients = getAllIngredients(r);
                    r.tags = r.tags.map((t) => ({ name: t.tag.name }));
                    r.categories = r.categories.map((c) => ({ name: c.recipeCategory.name }));
                    return (
                        <>
                            <SetDocumentTitle title={r.name} />
                            <RecipePreview
                                recipe={r}
                                numberOfEaters={parseFloat(numberOfEaters)}
                            />
                        </>
                    );
                }}
            </DataLoadingWardenForQuery>
        </div>
    );
};

export const RecipePreviewPageButtons: React.FC<RouteComponentProps<{ id: string }>> = ({
    match: {
        params: { id },
    },
}) => {
    const [toggleLike, result] = useToggleLikeMutation();
    let qp: IQueryParameters = {};
    qp.filters = [new FilterParameters("id", "eq", id)];
    const recipe = useGetRecipesQuery(toQueryString(qp));
    const ownerPermission = useSelector(hasOwnerPermission(recipe.data?.result[0]?.author?.id));
    const user = useSelector(selectUser)!;
    const [cachedIsLiked, setCachedIsLiked] = useState<boolean>(false);

    useEffect(() => {
        const isLiked = getIsLiked(recipe.data?.result[0], user.id);
        setCachedIsLiked(isLiked);
    }, [recipe.data]);

    const likeButton = (
        <IonButton
            fill="clear"
            className="liked-button"
            onClick={() => {
                toggleLike({ recipeId: parseInt(id, 10), like: !cachedIsLiked });
                setCachedIsLiked(!cachedIsLiked);
            }}
        >
            {!cachedIsLiked && (
                <>
                    Zapisz w <IonIcon icon={heartOutline} />
                </>
            )}
            {cachedIsLiked && (
                <>
                    Usuń z <IonIcon icon={heart} />
                </>
            )}
        </IonButton>
    );
    if (ownerPermission)
        return (
            <div className="recipe-preview-page-buttons">
                <IonButton routerLink={`/recipes/update/${id}`}>
                    <IonIcon
                        icon={pencilOutline}
                        title="Edytuj"
                        color="dark"
                    ></IonIcon>
                    Edytuj
                </IonButton>
                <RecipeReviewComponent id={id} />
                {likeButton}
            </div>
        );
    if (user.role !== "anonymous") {
        return (
            <div className="recipe-preview-page-buttons">
                <IonButton routerLink={`/recipes/feedback/${id}`}>
                    <IonIcon
                        icon={alertCircleOutline}
                        title="Zgłoś błąd w przepisie"
                        color="dark"
                    ></IonIcon>
                    Zgłoś błąd
                </IonButton>
                {likeButton}
            </div>
        );
    }
    return null;
};

const RecipeReviewComponent: React.FC<{ id: string }> = ({ id }) => {
    const { role: userRole, id: userId } = useSelector(selectUser)!;
    const parsedId = parseInt(id, 10);

    const initial = useSendRecipeToReviewInitialQuery({ id: parsedId });
    const [sendToReview] = useSendRecipeToReviewMutation();
    const [addReview] = useAddReviewMutation();
    const [modalOpened, openModal, closeModal] = useModalState("reviews");

    const [currentReview, setCurrentReview] = useState<string>();

    return (
        <DataLoadingWardenForQuery
            mode="control"
            query={initial}
        >
            {() => (
                <>
                    <IonButton onClick={() => openModal("reviews")}>
                        <RecipeStatusIcon status={initial.data.status} />
                        {initial.data.status === `Private` && `Opublikuj`}
                        {initial.data.status === `InReview` && `W moderacji`}
                        {initial.data.status === `Rejected` && `Odrzucony przez moderatora`}
                        {initial.data.status === `Public` && `Opublikowany`}
                    </IonButton>
                    <IonModal
                        isOpen={modalOpened}
                        onDidDismiss={() => closeModal("reviews")}
                    >
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Podziel się przepisem</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton
                                        fill="clear"
                                        strong={true}
                                        onClick={() => closeModal("reviews")}
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={closeOutline}
                                        ></IonIcon>
                                    </IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            {initial.data.status === `Private` && (
                                <IonItem>
                                    Przepis zostanie przesłany do moderacji i będzie opublikowany po zaakceptowaniu.
                                </IonItem>
                            )}
                            {initial.data.status === `InReview` && <IonItem>Przepis jest trakcie moderacji.</IonItem>}
                            {initial.data.status === `Public` && (
                                <IonItem>Przepis jest dostępny dla wszystkich użytkowników.</IonItem>
                            )}
                            <IonList>
                                {initial.data.reviews.map((r, index) => (
                                    <IonItem key={index}>
                                        {r.outcome === "Approved" ? "Zakceptowano" : "Odrzucono"} {!!r.review && `- ${r.review}`}
                                    </IonItem>
                                ))}
                            </IonList>
                        </IonContent>
                        <IonFooter>
                            {(initial.data.status === `Private` || initial.data.status === `Rejected`) && (
                                <IonToolbar>
                                    <IonButton
                                        expand="block"
                                        onClick={() => sendToReview({ id: parsedId })}
                                    >
                                        Prześlij
                                    </IonButton>
                                </IonToolbar>
                            )}
                            {initial.data.status === `InReview` && (userRole === "admin" || userRole === "moderator") && (
                                <IonToolbar>
                                    <IonTextarea
                                        value={currentReview}
                                        onIonChange={(e) => setCurrentReview(e.detail.value!)}
                                    />
                                    <IonButton
                                        expand="block"
                                        onClick={() =>
                                            addReview({
                                                id: parsedId,
                                                review: currentReview,
                                                outcome: "Rejected",
                                            })
                                        }
                                        color="danger"
                                    >
                                        Odrzuć
                                    </IonButton>
                                    <IonButton
                                        expand="block"
                                        onClick={() =>
                                            addReview({
                                                id: parsedId,
                                                review: currentReview,
                                                outcome: "Approved",
                                            })
                                        }
                                    >
                                        Zaakceptuj
                                    </IonButton>
                                </IonToolbar>
                            )}
                        </IonFooter>
                    </IonModal>
                </>
            )}
        </DataLoadingWardenForQuery>
    );
};
