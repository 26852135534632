import classNames from "classnames";
import { IonInput } from "@ionic/react";
import { ErrorLabel } from "./ErrorLabel";
import { InputWrapper } from "./InputWrapper";
import { Simulate } from "react-dom/test-utils";
import error = Simulate.error;

//copy from Ionic
type TextFieldTypes =
    | "date"
    | "email"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "url"
    | "time"
    | "week"
    | "month"
    | "datetime-local"
    | "decimal";

export const ControlledInput = ({
    name,
    label,
    register,
    errors,
    className,
    onChange,
    disabled,
    readonly,
    placeholder,
    validationRules,
    inputType,
    children
}: {
    name: string;
    label: string;
    register: any;
    errors: any;
    className?: string;
    inputType?: TextFieldTypes;
    disabled?: boolean;
    readonly?: boolean;
    placeholder?: string;
    children?: React.ReactNode;
    onChange?: (e: any) => void;
    validationRules?: any;
}) => {
    const errorText = errors[name];
    const inputClasses = classNames(
        {
            "ion-invalid": errorText,
            "ion-valid": !errorText,
        },
        className
    );
    const registeredProps = register(name, validationRules);
    const onChangeFunc = (event) => {
        registeredProps.onChange(event);
        onChange && onChange(event.detail.value);
    };
    return (
        <InputWrapper
            key={name}
            className={inputClasses}
        >
            <IonInput
                {...registeredProps}
                fill="outline"
                placeholder={placeholder}
                pattern={inputType === "number" ? "\\d+([,.]\\d+)?" : undefined}
                onIonInput={(e) => {
                    const regExp = inputType === "number" ? /[^0-9]/g : inputType === "decimal" ? /[^0-9.,]/g : undefined;
                    if (regExp !== undefined) {
                        e.detail.value = e.detail.value?.replace(regExp, "");
                        e.target.value = (e.target.value as string)?.replace(regExp, "");
                    }
                    onChangeFunc(e);
                }}
                label={label}
                labelPlacement={label ? "stacked" : undefined}
                disabled={disabled}
                readonly={readonly}
                type={inputType === "number" || inputType === "decimal" ? "text" : inputType || "text"}
                inputMode={inputType === "number" ? "numeric" : inputType === "decimal" ? "decimal" : undefined}
                lang="pl"
            />
            {children}
            <ErrorLabel>{errorText}</ErrorLabel>
        </InputWrapper>
    );
};
