import { useEffect, useRef, useState } from "react";
import { LoadingOrb } from "./LoadingOrb";
import {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonList,
    IonModal,
    IonSearchbar,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import classNames from "classnames";
import { closeOutline } from "ionicons/icons";
import { useModalState } from "../hooks/useModal";
import { ErrorLabel } from "./ErrorLabel";
import { InputWrapper } from "./InputWrapper";
import { Badge } from "./Badge";
import "./Picker.scss";

export interface PickerProps {
    className?: string;
    value?: any | any[];
    onChange?: any;
    items: any[];
    labelSelector: any;
    valueSelector: any;
    isLoading: boolean;
    label: string;
    isMulti?: boolean;
    errors: any;
    name?: string;
    modalTitle: string;
    placeholder?: string;
    noResultsChildren?: React.ReactNode;
    closeOnChange?: boolean;
}

export const Picker: React.FC<PickerProps> = (props: PickerProps) => {
    const searchInput = useRef(null);
    const [modalOpened, openModal, closeModal] = useModalState(props.name!);
    const [previousValue, setPreviousValue] = useState(props.value);
    const [searchText, setSearchText] = useState("");

    const [availableItems, setAvailableItems] = useState(props.items);
    let selectedItems: any[] = [];
    if (props.items && props.value && !props.isMulti) {
        selectedItems = [...props.items.filter((x) => props.valueSelector(x) === props.value)];
    }
    if (props.items && props.value && props.isMulti) {
        selectedItems = [...props.items.filter((x) => props.value.includes(props.valueSelector(x)))];
    }
    const errorText = props.errors[props.name!];
    const inputClasses = classNames(
        {
            "ion-invalid": errorText,
            "ion-valid": !errorText,
        },
        props.className
    );

    useEffect(() => {
        if (props.value !== previousValue) {
            setPreviousValue(props.value);
            if (props.value && props.closeOnChange) closeModal(props.name!);
        }
    }, [props.value]);

    useEffect(() => {
        setAvailableItems(props.items?.filter((x) => props.labelSelector(x).toLowerCase().includes(searchText?.toLowerCase())));
    }, [props, props.items, searchText]);

    useEffect(() => {
        if (modalOpened) {
            setTimeout(() => {
                (searchInput.current as any)?.setFocus();
            }, 250);
        }
    }, [modalOpened, searchInput]);

    let modalContent;
    if (props.isLoading) modalContent = <LoadingOrb text="Ładuję dane..." />;
    else
        modalContent = (
            <IonContent>
                {!availableItems?.length &&
                    (props.noResultsChildren || (
                        <IonItem>Nie znaleziono pasujących wyników, zmień kryteria wyszukiwania.</IonItem>
                    ))}
                {availableItems?.length > 0 && (
                    <IonList>
                        {availableItems.map((i) => (
                            <IonItem
                                button
                                key={props.valueSelector(i)}
                                onClick={() => {
                                    const currentValue = props.valueSelector(i);
                                    if (props.isMulti) {
                                        const currentValues = (props.value as any[]) || [];
                                        if (currentValues.includes(currentValue))
                                            props.onChange && props.onChange(currentValues.filter((x) => x !== currentValue));
                                        else props.onChange && props.onChange([...currentValues, currentValue]);
                                    } else {
                                        props.onChange && props.onChange(currentValue);
                                    }
                                }}
                            >
                                {props.labelSelector(i)}
                                <IonCheckbox
                                    slot="start"
                                    checked={selectedItems.includes(i)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </IonItem>
                        ))}
                    </IonList>
                )}
            </IonContent>
        );

    return (
        <>
            <IonModal
                className="picker-modal"
                isOpen={modalOpened}
                onDidDismiss={() => closeModal(props.name!)}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{props.modalTitle}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                strong={true}
                                onClick={() => closeModal(props.name!)}
                            >
                                <IonIcon
                                    slot="icon-only"
                                    icon={closeOutline}
                                ></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>

                    <IonSearchbar
                        inputMode="search"
                        placeholder="Szukaj..."
                        ref={searchInput}
                        value={searchText}
                        onIonInput={(e: any) => {
                            setSearchText(e.target.value!);
                        }}
                    />
                </IonHeader>
                {modalContent}
                <IonFooter>
                    <IonToolbar>
                        {selectedItems?.length > 0 && (
                            <div className="selected-items-container">
                                <p>Wybrane</p>
                                {selectedItems?.map((i) => (
                                    <Badge
                                        key={props.valueSelector(i)}
                                        name={props.labelSelector(i)}
                                        onClick={() => {
                                            const currentValue = props.valueSelector(i);
                                            if (props.isMulti) {
                                                const currentValues = (props.value as any[]) || [];
                                                if (currentValues.includes(currentValue))
                                                    props.onChange &&
                                                        props.onChange(currentValues.filter((x) => x !== currentValue));
                                                else props.onChange && props.onChange([...currentValues, currentValue]);
                                            } else {
                                                props.onChange && props.onChange(null);
                                            }
                                        }}
                                    />
                                ))}
                                <div className="spacer" />
                                <div className="spacer" />
                                <div className="spacer" />
                                <div className="spacer" />
                                <div className="spacer" />
                            </div>
                        )}
                        <IonButton
                            expand="block"
                            onClick={() => closeModal(props.name!)}
                        >
                            Zapisz (wybrano {selectedItems.length})
                        </IonButton>
                    </IonToolbar>
                </IonFooter>
            </IonModal>
            <InputWrapper className={inputClasses}>
                <IonInput
                    onClick={() => openModal(props.name!)}
                    label={props.label}
                    labelPlacement={props.label ? "stacked" : undefined}
                    placeholder={props.placeholder || "Wybierz..."}
                    fill="outline"
                    readonly
                    value={selectedItems.map((x) => props.labelSelector(x)).join(", ")}
                ></IonInput>
                <ErrorLabel>{errorText}</ErrorLabel>
            </InputWrapper>
        </>
    );
};
