import "./MenuCard.scss";
import { IonCard, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import Moment from "react-moment";
import { DATE_FORMAT } from "../consts";
export const MenuCard: React.FC<{ name: string; routerLink?: string; dateFrom: any; dateTo: any }> = ({
    name,
    routerLink,
    dateFrom,
    dateTo,
}) => {
    return (
        <div className="menu-card-component">
            <IonCard routerLink={routerLink}>
                <div className="container">
                    <IonCardTitle>{name}</IonCardTitle>
                    <IonCardSubtitle>
                        <Moment format={DATE_FORMAT}>{dateFrom}</Moment> - <Moment format={DATE_FORMAT}>{dateTo}</Moment>
                    </IonCardSubtitle>
                </div>
            </IonCard>
        </div>
    );
};
