import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";

export function useModalState(modalName: string) {
    const [modalOpened, setModalOpened] = useState(false);
    const location = useLocation();
    const nav = useHistory();

    useEffect(() => {
        const openedModals = getOpenedModals(location.search);
        if (!openedModals.includes(modalName)) setModalOpened(false);
        else setModalOpened(true);
    }, [location, modalName]);

    const openModal = (name) => {
        const openedModals = getOpenedModals(location.search);
        const queryParameter = formatAsQueryParameter([...openedModals, name]);
        nav.push(nav.location.pathname + "?" + queryParameter);
        setModalOpened(true);
    };

    const closeModal = (name) => {
        const openedModals = getOpenedModals(location.search);
        const queryParameter = formatAsQueryParameter(openedModals.filter((n) => n !== name));
        nav.replace(nav.location.pathname + "?" + queryParameter);
        //TODO: wstecz przez to nie działa poprawnie, ale nie wiem jak to naprawić
        // if (nav.action === "PUSH") nav.goBack();
        // if (nav.action === "POP" || nav.action === "PUSH") nav.replace(nav.location.pathname + "?" + queryParameter);
        setModalOpened(false);
    };

    return [modalOpened, openModal, closeModal] as const;
}

const getOpenedModals = (search: string) => {
    const params = new URLSearchParams(search);
    return params.getAll("modalOpened");
};

const formatAsQueryParameter = (openedModals: string[]) => {
    return openedModals.map((name) => `modalOpened=${name}`).join("&");
};
