import { IonToggle } from "@ionic/react";
import { Controller } from "react-hook-form";

export const ControlledToggle: React.FC<{
    name: string;
    labels: string[];
    values: string[];
    control: any;
}> = ({ name, labels, values, control }) => (
    <Controller
        name={name}
        control={control as any}
        render={({ field }) => (
            <IonToggle
                checked={field.value === values[0]}
                labelPlacement="end"
                onClick={(e) => {
                    field.onChange(field.value === values[0] ? values[1] : values[0]);
                }}
            >
                {field.value === values[0] ? labels[0] : labels[1]}
            </IonToggle>
        )}
    />
);
