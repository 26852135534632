import { IonButton, IonContent, IonFooter, IonRippleEffect, IonToolbar } from "@ionic/react";
import React, { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { environments } from "../environments/environment";
import "./ImageUpload.scss";
import "react-image-crop/dist/ReactCrop.css";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";
import { getToken } from "../firebase";
import { LoadingOrb } from "./LoadingOrb";

const MIN_WIDTH = 1920;
const ASPECT_RATIO = 3.0 / 2.0;

function getResizedCanvas(image: HTMLImageElement, crop) {
    const tmpCanvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    tmpCanvas.width = MIN_WIDTH;
    tmpCanvas.height = MIN_WIDTH / ASPECT_RATIO;
    const ctx = tmpCanvas.getContext("2d");
    if (!ctx) throw new Error();

    ctx.imageSmoothingEnabled = false;
    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        tmpCanvas.width,
        tmpCanvas.height
    );

    return tmpCanvas;
}

async function uploadPhoto(image: HTMLImageElement, crop, callback) {
    if (!crop || !image) {
        return;
    }
    const canvas = getResizedCanvas(image, crop);
    const token = await getToken();

    canvas.toBlob(
        (blob: any) => {
            const formData = new FormData();
            formData.set("file", blob);
            fetch(environments.FILE_URL + "/images", {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    callback(result.id);
                })
                .catch((error) => {
                    callback(null);
                });
        },
        "image/jpeg",
        0.9
    );
}

export const ImageUpload: React.FC<any> = ({ onChangeImage }) => {
    const [upImg, setUpImg] = useState<any>();
    const imgRef = useRef<HTMLImageElement>();
    const [minWidth, setMinWidth] = useState<number>(0);
    const [minHeight, setMinHeight] = useState<number>(0);
    const [succeeded, setSucceeded] = useState<boolean | undefined>(undefined);
    const [uploading, setUploading] = useState<boolean>(false);
    const [crop, setCrop] = useState<any>();
    const [completedCrop, setCompletedCrop] = useState<any>(null);

    const onLoad = (img) => {
        imgRef.current = img;
        setMinWidth((MIN_WIDTH / img.naturalWidth) * img.width);
        setMinHeight(((MIN_WIDTH / img.naturalWidth) * img.width) / ASPECT_RATIO);

        let currentWidth = img.width;
        let currentHeight = img.height;
        if (currentHeight < currentWidth / ASPECT_RATIO) currentWidth = currentHeight * ASPECT_RATIO;
        else if (currentWidth < currentHeight / ASPECT_RATIO) currentHeight = currentWidth * ASPECT_RATIO;

        setCrop({
            unit: "px",
            width: currentWidth,
            height: currentHeight,
        });
    };

    const onChange = (e) => {
        const file = e.target?.files && e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setUpImg(reader.result));
            reader.readAsDataURL(file);
        }
    };

    const onUploadCompleted = (key) => {
        setUploading(false);
        if (key) {
            onChangeImage(key);
            setSucceeded(true);
        }
        if (!key) {
            setSucceeded(false);
        }
    };
    if (succeeded === undefined && !uploading)
        return (
            <>
                <IonContent className="image-upload-component">
                    <div className="image-file-upload">
                        <input
                            id="file"
                            type="file"
                            className="file"
                            accept=".jpg, .png, .jpeg"
                            onChange={onChange}
                        />
                        <label htmlFor="file">Wybierz zdjęcie</label>
                    </div>
                    <div>
                        <ReactCrop
                            minWidth={minWidth}
                            minHeight={minHeight}
                            aspect={ASPECT_RATIO}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                        >
                            {upImg && (
                                <img
                                    src={upImg}
                                    onLoad={(e) => onLoad(e.target)}
                                    alt="Crop"
                                />
                            )}
                        </ReactCrop>
                    </div>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButton
                            expand="block"
                            disabled={!completedCrop?.width || !completedCrop?.height}
                            onClick={async () => {
                                setUploading(true);
                                imgRef.current && (await uploadPhoto(imgRef.current, completedCrop, onUploadCompleted));
                            }}
                        >
                            Zapisz zdjęcie
                        </IonButton>
                    </IonToolbar>
                </IonFooter>
            </>
        );

    if (uploading)
        return (
            <LoadingOrb
                mode="page"
                text="Zapisywanie zdjęcia..."
            />
        );

    if (!succeeded) return <span>Wystąpił nieoczekiwany błąd</span>;

    return <span>Zdjęcie zapisane</span>;
};
