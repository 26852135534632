import { Route, RouteComponentProps } from "react-router-dom";
import { Login } from "./Login";
import Page from "../Page";
import { Register } from "./Register";
import { ResetPassword } from "./ResetPassword";
import { Profile } from "./Profile";
import { RouteGuard } from "../../components/RouteGuard";

export const AuthPaths = {
    login: "/login",
    register: "/register",
    resetPassword: "/password-reset",
    profile: "/profile",
};

export const AuthRoutes = (
    <>
        <Route
            path={AuthPaths.login}
            exact={true}
            key={AuthPaths.login}
            component={(props: RouteComponentProps) => {
                return (
                    <RouteGuard
                        roles={["anonymous"]}
                        link={AuthPaths.login}
                    >
                        <Page
                            link={AuthPaths.login}
                            name="Zaloguj"
                            {...props}
                        >
                            <Login />
                        </Page>
                    </RouteGuard>
                );
            }}
        />
        <Route
            path={AuthPaths.register}
            exact={true}
            key={AuthPaths.register}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["anonymous"]}
                    link={AuthPaths.register}
                >
                    <Page
                        link={AuthPaths.register}
                        name="Zarejestruj"
                        {...props}
                    >
                        <Register />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={AuthPaths.resetPassword}
            exact={true}
            key={AuthPaths.resetPassword}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["anonymous"]}
                    link={AuthPaths.resetPassword}
                >
                    <Page
                        link={AuthPaths.resetPassword}
                        name="Resetuj hasło"
                        {...props}
                    >
                        <ResetPassword />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={AuthPaths.profile}
            exact={true}
            key={AuthPaths.profile}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={AuthPaths.profile}
                >
                    <Page
                        link={AuthPaths.profile}
                        name="Profil"
                        {...props}
                    >
                        <Profile />
                    </Page>
                </RouteGuard>
            )}
        />
    </>
);
