import React from "react";
import { IonButton, IonTextarea } from "@ionic/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ControlledInput } from "../../components/ControlledInput";
import classNames from "classnames";
import { ErrorLabel } from "../../components/ErrorLabel";
import { InputWrapper } from "../../components/InputWrapper";
import { RouteComponentProps } from "react-router-dom";
import { addFeedback } from "../../firebase";
import "./Feedback.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { openToast } from "../../store/toastSlice";
import { selectUser } from "../../store/authSlice";
import moment from "moment";
import { Timestamp } from "firebase/firestore";

export const Feedback: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch();
    const router = useHistory();
    const user = useSelector(selectUser)!;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            subject: "",
            description: "",
        },
    });
    const onSubmit: SubmitHandler<any> = async (data) => {
        await addFeedback({
            ...data,
            userId: user.id,
            userName: user.name,
            userRole: user.role,
            date: new Timestamp(moment().unix(), 0),
        });
        dispatch(openToast("Dziękujemy za przesłanie opinii!"));
        router.goBack();
    };

    return (
        <div className="feedback-component">
            <div className="form-content">
                <form
                    noValidate={true}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <p>
                        Zauważyłeś błąd w aplikacji? Masz pomysł na rozwój nowych funkcji? Chcesz powiedzieć, za co lubisz FeedMe?
                        Śmiało, napisz do nas - jesteśmy tu, aby słuchać i razem udoskonalać FeedMe.
                    </p>
                    <ControlledInput
                        name="subject"
                        label="Temat"
                        register={register}
                        validationRules={{ required: true }}
                        errors={errors}
                    />
                    <InputWrapper
                        key="description"
                        className={classNames("description", {
                            "ion-valid": !errors.description?.type,
                            "ion-invalid": !!errors.description?.type,
                        })}
                    >
                        <IonTextarea
                            fill="outline"
                            labelPlacement="stacked"
                            label="Opis"
                            autoGrow={true}
                            errorText={errors.description?.type}
                            {...register("description", { required: true })}
                        />
                        <ErrorLabel>{errors.description?.type}</ErrorLabel>
                    </InputWrapper>
                    <div className="form-content"></div>
                    <IonButton
                        type="submit"
                        expand="block"
                    >
                        Wyślij
                    </IonButton>
                </form>
            </div>
        </div>
    );
};
