import { configureStore } from "@reduxjs/toolkit";
import { authListener, authReducer } from "./authSlice";
import { unitsApi } from "./data/unitsSlice";
import { recipesApi } from "./data/recipeSlice";
import { recipeCategoriesApi } from "./data/recipeCategoriesSlice";
import { ingredientsApi } from "./data/ingredientsSlice";
import { ingredientCategoriesApi } from "./data/ingredientCategoriesSlice";
import { tagsApi } from "./data/tagsSlice";
import { menusApi } from "./data/menuSlice";
import { meApi } from "./data/meSlice";
import { publicApi } from "./data/publicSlice";
import { filtersReducer } from "./filtersSlice";
import { toastListener, toastReducer } from "./toastSlice";
import { shoppingListApi } from "./data/shoppingList";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        filters: filtersReducer,
        toast: toastReducer,
        [unitsApi.reducerPath]: unitsApi.reducer,
        [recipesApi.reducerPath]: recipesApi.reducer,
        [recipeCategoriesApi.reducerPath]: recipeCategoriesApi.reducer,
        [ingredientsApi.reducerPath]: ingredientsApi.reducer,
        [ingredientCategoriesApi.reducerPath]: ingredientCategoriesApi.reducer,
        [tagsApi.reducerPath]: tagsApi.reducer,
        [menusApi.reducerPath]: menusApi.reducer,
        [meApi.reducerPath]: meApi.reducer,
        [publicApi.reducerPath]: publicApi.reducer,
        [shoppingListApi.reducerPath]: shoppingListApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(unitsApi.middleware)
            .concat(recipesApi.middleware)
            .concat(recipeCategoriesApi.middleware)
            .concat(ingredientsApi.middleware)
            .concat(ingredientCategoriesApi.middleware)
            .concat(tagsApi.middleware)
            .concat(publicApi.middleware)
            .concat(menusApi.middleware)
            .concat(meApi.middleware)
            .concat(shoppingListApi.middleware)
            .prepend(authListener.middleware)
            .prepend(toastListener.middleware),
});
