import { IonText } from "@ionic/react";

export const ErrorLabel = ({ children }) => {
    return (
        <IonText
            color="danger"
            style={{ fontSize: "0.7em" }}
        ></IonText>
    );
};
