import { Redirect, RouteComponentProps } from "react-router-dom";
import { LoadingOrb } from "../../components/LoadingOrb";
import { SomethingWentWrong } from "../../components/SomethingWentWrong";
import { useDeleteRecipeMutation, useUpdateRecipeInitialQuery, useUpdateRecipeMutation } from "../../store/data/recipeSlice";
import { RecipeForm } from "../../components/forms/RecipeForm";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { ActionWithConfirmation } from "../../components/ActionWithConfirmation";

interface ContainerProps extends RouteComponentProps<{ id: string }> {}

export const RecipeUpdateForm: React.FC<ContainerProps> = ({ match }) => {
    const id = parseInt(match.params.id, 10);
    let initial = useUpdateRecipeInitialQuery({ id });

    const [update, result] = useUpdateRecipeMutation();

    if (result.isLoading) {
        return <LoadingOrb text="Zapisuję..." />;
    }
    if (result.error && result.error?.status !== 400) {
        return <SomethingWentWrong text="Coś poszło nie tak :(" />;
    }
    if (result.isSuccess) {
        return <Redirect to="/recipes" />;
    }

    return (
        <div className="recipe-form-component">
            <DataLoadingWardenForQuery query={initial}>
                {() => (
                    <RecipeForm
                        data={result.originalArgs?.body || initial.data}
                        submitForm={(saveAndPublish) => (data) => update({ id, body: { ...data, saveAndPublish } })}
                        errors={result.error?.data || {}}
                    />
                )}
            </DataLoadingWardenForQuery>
        </div>
    );
};

export const RecipeUpdatePageButtons: React.FC<RouteComponentProps<{ id: string }>> = ({
    match: {
        params: { id },
    },
}) => {
    const [remove, result] = useDeleteRecipeMutation();

    const history = useHistory();
    useEffect(() => {
        result.isSuccess && history.replace("/recipes");
    }, [result.isSuccess, history]);
    return (
        <ActionWithConfirmation
            message={`Czy na pewno chcesz usunąć przepis?`}
            header="Usuwanie przepisu"
            confirmedAction={() => remove({ id: id })}
        />
    );
};
