import { IonIcon } from "@ionic/react";
import { alertCircleOutline, globeOutline, helpOutline, hourglassOutline, lockClosedOutline } from "ionicons/icons";

export const RecipeStatusIcon: React.FC<{ status: string }> = ({ status }) => {
    switch (status) {
        case "Public":
            return (
                <IonIcon
                    icon={globeOutline}
                    title="Opublikowany"
                />
            );
        case "InReview":
            return (
                <IonIcon
                    icon={hourglassOutline}
                    title="W moderacji"
                />
            );
        case "Private":
            return (
                <IonIcon
                    icon={lockClosedOutline}
                    title="Prywatny"
                />
            );
        case "Rejected":
            return (
                <IonIcon
                    icon={alertCircleOutline}
                    title="Odrzucony"
                />
            );
        default:
            return (
                <IonIcon
                    icon={helpOutline}
                    title="Coś poszło nie tak"
                />
            );
    }
};
