import { IonIcon } from "@ionic/react";
import { ellipse, ellipseOutline, star, starOutline } from "ionicons/icons";

export const ComplexityLabel: React.FC<{ complexity?: number }> = ({ complexity }) => {
    if (complexity === 1)
        return (
            <>
                <IonIcon icon={ellipse} />
                <IonIcon icon={ellipseOutline} />
                <IonIcon icon={ellipseOutline} />
            </>
        );
    if (complexity === 2)
        return (
            <>
                <IonIcon icon={ellipse} />
                <IonIcon icon={ellipse} />
                <IonIcon icon={ellipseOutline} />
            </>
        );
    if (complexity === 3)
        return (
            <>
                <IonIcon icon={ellipse} />
                <IonIcon icon={ellipse} />
                <IonIcon icon={ellipse} />
            </>
        );
    return <>"-"</>;
};
