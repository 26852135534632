import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { environments } from "../../environments/environment";
import { getToken } from "../../firebase";

const entityName = "Recipe";
const tagName = `${entityName}s`;
const entityNamePlural = tagName;
const reducerPath = "recipesApi";
const queryForList = (params: string) =>
    `query/recipes?$expand=ingredients($expand=unit,ingredient),recipeIngredientGroups,images,tags($expand=tag),categories($expand=recipeCategory),author,likes` +
    params;
const queryForDict = () =>
    `query/recipes?$orderBy=name&$expand=images,tags($expand=tag),categories($expand=recipeCategory),author,likes`;

export const recipesApi = createApi({
    reducerPath: reducerPath,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.API_URL,
        prepareHeaders: async (headers, api) => {
            const token = await getToken();

            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
        },
    }),
    tagTypes: [tagName],
    endpoints: (builder) => {
        let eps = {};
        eps[`get${entityNamePlural}`] = builder.query<any, string>({
            query: queryForList,
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`get${entityNamePlural}ForDict`] = builder.query<any, void>({
            query: queryForDict,
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`add${entityName}Initial`] = builder.query<any, { body?: any }>({
            query: ({ body }) => ({
                url: `commands/getInitial/Add${entityName}/`,
                method: "POST",
                body: { ...body },
            }),
        });
        eps[`add${entityName}`] = builder.mutation<any, { body?: any }>({
            query: ({ body }) => ({
                url: `commands/execute/Add${entityName}/`,
                method: "POST",
                body: { ...body },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        eps[`update${entityName}Initial`] = builder.query<any, { id: number; body?: any }>({
            query: ({ id, body }) => ({
                url: `commands/getInitial/Update${entityName}/`,
                method: "POST",
                body: { id, ...body },
            }),
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`update${entityName}`] = builder.mutation<any, { id: number; body?: any }>({
            query: ({ id, body }) => ({
                url: `commands/execute/Update${entityName}/`,
                method: "POST",
                body: { id, ...body },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        eps[`sendRecipeToReviewInitial`] = builder.query<any, { id: number }>({
            query: ({ id }) => ({
                url: `commands/getInitial/SendRecipeToReview/`,
                method: "POST",
                body: { id },
            }),
            //TODO change this to refresh only public status
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`sendRecipeToReview`] = builder.mutation<any, { id: number }>({
            query: ({ id }) => ({
                url: `commands/execute/SendRecipeToReview/`,
                method: "POST",
                body: { id },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        eps[`addReviewInitial`] = builder.query<any, { id: number }>({
            query: ({ id }) => ({
                url: `commands/getInitial/AddReview/`,
                method: "POST",
                body: { id },
            }),
            //TODO change this to refresh only public status
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`addReview`] = builder.mutation<any, { id: number; review: string; outcome: string }>({
            query: ({ id, review, outcome }) => ({
                url: `commands/execute/AddReview/`,
                method: "POST",
                body: { id, review, outcome },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        eps[`delete${entityName}`] = builder.mutation<any, { id: number }>({
            query: ({ id }) => ({
                url: `commands/execute/Delete${entityName}/`,
                method: "POST",
                body: { id },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        eps[`toggleLike`] = builder.mutation<any, { recipeId: number; like: boolean }>({
            query: ({ recipeId, like }) => ({
                url: `commands/execute/ToggleLike/`,
                method: "POST",
                body: { recipeId, like },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        return eps;
    },
});

export const {
    useGetRecipesQuery,
    useGetRecipesForDictQuery,
    useAddRecipeInitialQuery,
    useAddRecipeMutation,
    useUpdateRecipeInitialQuery,
    useUpdateRecipeMutation,
    useSendRecipeToReviewInitialQuery,
    useSendRecipeToReviewMutation,
    useAddReviewInitialQuery,
    useAddReviewMutation,
    useDeleteRecipeMutation,
    useToggleLikeMutation,
} = recipesApi as any;
