import { IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { add } from "ionicons/icons";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { useGetMenusQuery } from "../../store/data/menuSlice";
import { IQueryParameters, toQueryString } from "../../store/oDataHelpers";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { DATE_FORMAT } from "../../consts";

class ContainerProps {}

export const MenusList: React.FC<ContainerProps> = () => {
    const qp: IQueryParameters = {};
    qp.order = "desc";
    qp.orderBy = "startDate";
    const query = useGetMenusQuery(toQueryString(qp));

    return (
        <>
            <DataLoadingWardenForQuery
                query={query}
                children={() => (
                    <div className="menu-list-component">
                        {!!query.data.result?.length && (
                            <IonList>
                                {query.data.result?.map((x) => (
                                    <IonItem
                                        routerLink={`/user/menus/preview/${x.id}`}
                                        key={x.id}
                                    >
                                        <IonLabel>
                                            {x.name} (<Moment format={DATE_FORMAT}>{x.startDate}</Moment> -{" "}
                                            <Moment format={DATE_FORMAT}>{x.endDate}</Moment>)
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                        )}

                        {!query.data.result?.length && (
                            <span style={{ padding: "10px", textAlign: "center", width: "100%", display: "block" }}>
                                Nie masz jeszcze jadłospisów - dodaj nowy, aby rozpocząć przygodę z FeedMe!
                            </span>
                        )}
                    </div>
                )}
            />
            <IonFab
                slot="fixed"
                vertical="bottom"
                horizontal="end"
            >
                <Link to="/user/menus/add">
                    <IonFabButton>
                        <IonIcon icon={add}></IonIcon>
                    </IonFabButton>
                </Link>
            </IonFab>
        </>
    );
};
