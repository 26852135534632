import "./ControlledDatePicker.scss";
import classNames from "classnames";
import { UnwrappedDatePicker } from "./UnwrappedDatePicker";
import { InputWrapper } from "./InputWrapper";
import { ErrorLabel } from "./ErrorLabel";

export const ControlledDatePicker = ({
    name,
    label,
    control,
    errors,
    className,
}: {
    name: string;
    label: string;
    control: any;
    errors: any;
    className?: string;
}) => {
    const errorText = errors[name];
    const inputClasses = classNames(
        "controlled-date-picker-component",
        {
            "ion-invalid": errorText,
            "ion-valid": !errorText,
        },
        className
    );

    return (
        <InputWrapper className={inputClasses}>
            <UnwrappedDatePicker
                label={label}
                name={name}
                control={control}
            />
            <ErrorLabel>{errorText}</ErrorLabel>
        </InputWrapper>
    );
};
