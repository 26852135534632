export interface IQueryParameters {
    select?: string[];
    expand?: string[];
    pageSize?: number;
    page?: number;
    orderBy?: string;
    order?: "asc" | "desc" | undefined;
    filters?: FilterParameters[];
}

export const assignQueryParameters = (qp: IQueryParameters, obj: IQueryParameters): IQueryParameters => {
    Object.assign(qp, obj);
    return qp;
};

export const toQueryString = (qp: IQueryParameters): string => {
    let query: string[] = [];
    if (qp.select && qp.select.length) {
        query.push(`$select=${qp.select.join(",")}`);
    }
    if (qp.expand && qp.expand.length) {
        query.push(`$expand=${qp.expand.join(",")}`);
    }
    if (qp.pageSize) {
        query.push(`$top=${qp.pageSize}`);
    }
    if (qp.page && qp.pageSize) {
        query.push(`$skip=${(qp.page - 1) * qp.pageSize}`);
    }
    if (qp.order && qp.orderBy) {
        query.push(`$orderBy=${qp.orderBy} ${qp.order}`);
    }
    if (qp.filters && qp.filters.length > 0) {
        let filter = "$filter=";
        query.push(filter + qp.filters.map((i) => i.toString).join(" and "));
    }
    if (query.length > 0) {
        return `&${query.join("&")}`; // change to ? on the beginning
    }

    return "";
};

export class FilterParameters {
    constructor(
        public field: string,
        public operator?: "contains" | "in" | "not in" | "le" | "gt" | "eq" | "ne",
        public value?: any,
        public subField: string | undefined = undefined,
        public groupOperator: "or" | "and" = "or"
    ) {}

    get toString(): string {
        if (this.value === undefined || this.value === null) return this.field;

        switch (this.operator) {
            case "contains":
                return `contains(tolower(${this.field}), '${this.value.toLowerCase()}')`;
            case "in":
                if (!this.subField) return `(${this.field} ${this.operator} (${this.value.map((v) => `"${v}"`).join(",")}))`;

                if (Array.isArray(this.value)) {
                    return `(${this.value
                        .map((v) => `${this.field}/any(c:c/${this.subField} eq ${v})`)
                        .join(` ${this.groupOperator} `)})`;
                }
                return `${this.field}/any(c:c/${this.subField} eq ${this.value})`;
            case "le":
            case "gt":
            case "ne":
            case "eq":
                if (Array.isArray(this.value)) {
                    return `(${this.value.map((v) => `${this.field} ${this.operator} ${v}`).join(" or ")})`;
                }
                return `${this.field} ${this.operator} ${this.value}`;
            default:
                return "";
        }
    }
}
