import { IonIcon, IonSkeletonText, IonThumbnail } from "@ionic/react";
import { cameraOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import "./LazyImage.scss";

export const LazyImage: React.FC<{ src: string; className?: string; onClick?: any }> = ({ src, ...props }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        setIsLoading(true);
        if (src) {
            const handleLoad = () => {
                setIsLoading(false);
                setIsError(false);
            };
            const handleError = () => {
                setIsLoading(false);
                setIsError(true);
            };
            const image = new Image();
            image.addEventListener("load", handleLoad);
            image.addEventListener("error", handleError);
            image.src = src;
            return () => {
                image.removeEventListener("load", handleLoad);
                image.removeEventListener("error", handleLoad);
            };
        }
    }, [src]);
    if (isLoading)
        return (
            <IonThumbnail className="lazy-image-thumbnail">
                <IonSkeletonText
                    animated={true}
                    onClick={() => props.onClick && props.onClick()}
                />
            </IonThumbnail>
        );

    if (isError)
        return (
            <IonThumbnail className="lazy-image-thumbnail">
                <IonIcon
                    icon={cameraOutline}
                    onClick={() => props.onClick && props.onClick()}
                />
            </IonThumbnail>
        );

    return (
        <img
            src={src}
            {...props}
            onClick={() => props.onClick && props.onClick()}
            alt="Unable to load"
        />
    );
};
