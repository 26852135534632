import { useUpdateUnitInitialQuery, useUpdateUnitMutation } from "../../store/data/unitsSlice";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { LoadingOrb } from "../../components/LoadingOrb";
import { SomethingWentWrong } from "../../components/SomethingWentWrong";
import { NameOnlyForm } from "../../components/forms/NameOnlyForm";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";

interface ContainerProps extends RouteComponentProps<{ id: string }> {}

export const UnitUpdateForm: React.FC<ContainerProps> = ({ match }) => {
    const id = parseInt(match.params.id, 10);
    const initial = useUpdateUnitInitialQuery({ id });
    const [update, result] = useUpdateUnitMutation();

    if (result.isLoading) {
        return <LoadingOrb text="Zapisuję..." />;
    }
    if (result.error && result.error?.status !== 400) {
        return <SomethingWentWrong text="Coś poszło nie tak :(" />;
    }
    if (result.isSuccess) {
        return <Redirect to="/dictionaries/units" />;
    }

    return (
        <div className="unit-update-form-component form-content">
            <DataLoadingWardenForQuery query={initial}>
                {() => (
                    <NameOnlyForm
                        data={result.originalArgs?.body || initial.data}
                        submitForm={(data) => update({ id, body: data })}
                        errors={result.error?.data || {}}
                    />
                )}
            </DataLoadingWardenForQuery>
        </div>
    );
};
