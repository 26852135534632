import { selectAuth, UserRole } from "../store/authSlice";
import { useSelector } from "react-redux";
import { useIonRouter } from "@ionic/react";
import { Paths } from "../pages/routes";

interface PageProps {
    children: JSX.Element;
    roles: UserRole[];
    forEveryone?: boolean;
    link: string;
}

export const RouteGuard: React.FC<PageProps> = ({ roles, forEveryone, children, link }) => {
    const userAuth = useSelector(selectAuth);
    const router = useIonRouter();

    if (router.routeInfo.pathname.length > 1 && router.routeInfo.pathname !== link) return null;

    if (forEveryone) return children;

    if (roles.includes(userAuth?.user.role)) return children;
    if (userAuth?.user.role && roles.length === 1 && roles[0] === "anonymous") router.push("/", "root");

    return null;
};
