import { Route, RouteComponentProps } from "react-router-dom";
import { RouteGuard } from "../../components/RouteGuard";
import Page from "../Page";
import { UnitList } from "./UnitList";
import { UnitAddForm } from "./UnitAddForm";
import { UnitUpdateForm } from "./UnitUpdateForm";
import { RecipeCategoryList } from "./RecipeCategoryList";
import { RecipeCategoryAddForm } from "./RecipeCategoryAddForm";
import { RecipeCategoryUpdateForm } from "./RecipeCategoryUpdateForm";
import { IngredientCategoryUpdateForm } from "./IngredientCategoryUpdateForm";
import { IngredientCategoryList } from "./IngredientCategoryList";
import { IngredientCategoryAddForm } from "./IngredientCategoryAddForm";
import { IngredientList } from "./IngredientList";
import { IngredientAddForm } from "./IngredientAddForm";
import { IngredientUpdateForm } from "./IngredientUpdateForm";
import { TagUpdateForm } from "./TagUpdateForm";
import { TagList } from "./TagList";
import { TagAddForm } from "./TagAddForm";

export const DictionariesRoutes = (
    <>
        <Route
            path="/dictionaries/units"
            key="/dictionaries/units"
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link="/dictionaries/units"
                >
                    <Page
                        link="/dictionaries/units"
                        name="Jednostki"
                        {...props}
                    >
                        <UnitList />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path="/dictionaries/units/add"
            key="/dictionaries/units/add"
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link="/dictionaries/units/add"
                >
                    <Page
                        link="/dictionaries/units/add"
                        name="Dodaj jednostkę"
                        {...props}
                    >
                        <UnitAddForm />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path="/dictionaries/units/update/:id"
            key="/dictionaries/units/update/:id"
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/dictionaries/units/update/${props.match.params.id}`}
                >
                    <Page
                        link={`/dictionaries/units/update/${props.match.params.id}`}
                        name="Zmień jednostkę"
                        {...props}
                    >
                        <UnitUpdateForm {...props} />
                    </Page>
                </RouteGuard>
            )}
        />

        <Route
            path="/dictionaries/ingredientCategories"
            key="/dictionaries/ingredientCategories"
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link="/dictionaries/ingredientCategories"
                >
                    <Page
                        link="/dictionaries/ingredientCategories"
                        name="Kategorie składników"
                        {...props}
                    >
                        <IngredientCategoryList />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path="/dictionaries/ingredientCategories/add"
            key="/dictionaries/ingredientCategories/add"
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link="/dictionaries/ingredientCategories/add"
                >
                    <Page
                        link="/dictionaries/ingredientCategories/add"
                        name="Dodaj kategorię składników"
                        {...props}
                    >
                        <IngredientCategoryAddForm />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path="/dictionaries/ingredientCategories/update/:id"
            key="/dictionaries/ingredientCategories/update/:id"
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/dictionaries/ingredientCategories/update/${props.match.params.id}`}
                >
                    <Page
                        link={`/dictionaries/ingredientCategories/update/${props.match.params.id}`}
                        name="Zmień kategorię składników"
                        {...props}
                    >
                        <IngredientCategoryUpdateForm {...props} />
                    </Page>
                </RouteGuard>
            )}
        />

        <Route
            path="/dictionaries/recipeCategories"
            key="/dictionaries/recipeCategories"
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link="/dictionaries/recipeCategories"
                >
                    <Page
                        link="/dictionaries/recipeCategories"
                        name="Kategorie przepisów"
                        {...props}
                    >
                        <RecipeCategoryList />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path="/dictionaries/recipeCategories/add"
            key="/dictionaries/recipeCategories/add"
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link="/dictionaries/recipeCategories/add"
                >
                    <Page
                        link="/dictionaries/recipeCategories/add"
                        name="Dodaj kategorię przepisów"
                        {...props}
                    >
                        <RecipeCategoryAddForm />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path="/dictionaries/recipeCategories/update/:id"
            key="/dictionaries/recipeCategories/update/:id"
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/dictionaries/recipeCategories/update/${props.match.params.id}`}
                >
                    <Page
                        link={`/dictionaries/recipeCategories/update/${props.match.params.id}`}
                        name="Zmień kategorię przepisów"
                        {...props}
                    >
                        <RecipeCategoryUpdateForm {...props} />
                    </Page>
                </RouteGuard>
            )}
        />

        <Route
            path="/dictionaries/ingredients"
            key="/dictionaries/ingredients"
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link="/dictionaries/ingredients"
                >
                    <Page
                        link="/dictionaries/ingredients"
                        name="Składniki"
                        {...props}
                    >
                        <IngredientList />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path="/dictionaries/ingredients/add"
            key="/dictionaries/ingredients/add"
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link="/dictionaries/ingredients/add"
                >
                    <Page
                        link="/dictionaries/ingredients/add"
                        name="Dodaj składnik"
                        {...props}
                    >
                        <IngredientAddForm />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path="/dictionaries/ingredients/update/:id"
            key="/dictionaries/ingredients/update/:id"
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/dictionaries/ingredients/update/${props.match.params.id}`}
                >
                    <Page
                        link={`/dictionaries/ingredients/update/${props.match.params.id}`}
                        name="Zmień składnik"
                        {...props}
                    >
                        <IngredientUpdateForm {...props} />
                    </Page>
                </RouteGuard>
            )}
        />

        <Route
            path="/dictionaries/tags"
            key="/dictionaries/tags"
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link="/dictionaries/tags"
                >
                    <Page
                        link="/dictionaries/tags"
                        name="Tagi"
                        {...props}
                    >
                        <TagList />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path="/dictionaries/tags/add"
            key="/dictionaries/tags/add"
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link="/dictionaries/tags/add"
                >
                    <Page
                        link="/dictionaries/tags/add"
                        name="Dodaj tag"
                        {...props}
                    >
                        <TagAddForm />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path="/dictionaries/tags/update/:id"
            key="/dictionaries/tags/update/:id"
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/dictionaries/tags/update/${props.match.params.id}`}
                >
                    <Page
                        link={`/dictionaries/tags/update/${props.match.params.id}`}
                        name="Zmień tag"
                        {...props}
                    >
                        <TagUpdateForm {...props} />
                    </Page>
                </RouteGuard>
            )}
        />
    </>
);
