import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { environments } from "../../environments/environment";
import { getToken } from "../../firebase";

const entityName = "IngredientCategory";
const tagName = `IngredientCategories`;
const entityNamePlural = "IngredientCategories";
const reducerPath = "ingredientCategoriesApi";
const queryForList = (params: string) => `query/ingredientCategories?$orderBy=name`;
const queryForDict = () => `query/ingredientCategories?$orderBy=name`;

export const ingredientCategoriesApi = createApi({
    reducerPath: reducerPath,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.API_URL,
        prepareHeaders: async (headers, api) => {
            const token = await getToken();

            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
        },
    }),
    tagTypes: [tagName],
    endpoints: (builder) => {
        let eps = {};
        eps[`get${entityNamePlural}`] = builder.query<any, string>({
            query: queryForList,
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`get${entityNamePlural}ForDict`] = builder.query<any, void>({
            query: queryForDict,
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`add${entityName}Initial`] = builder.query<any, { body?: any }>({
            query: ({ body }) => ({
                url: `commands/getInitial/Add${entityName}/`,
                method: "POST",
                body: { ...body },
            }),
        });
        eps[`add${entityName}`] = builder.mutation<any, { body?: any }>({
            query: ({ body }) => ({
                url: `commands/execute/Add${entityName}/`,
                method: "POST",
                body: { ...body },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        eps[`update${entityName}Initial`] = builder.query<any, { id: number; body?: any }>({
            query: ({ id, body }) => ({
                url: `commands/getInitial/Update${entityName}/`,
                method: "POST",
                body: { id, ...body },
            }),
            providesTags: (result) => [{ type: tagName, id: "LIST" }],
        });
        eps[`update${entityName}`] = builder.mutation<any, { id: number; body?: any }>({
            query: ({ id, body }) => ({
                url: `commands/execute/Update${entityName}/`,
                method: "POST",
                body: { id, ...body },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        eps[`delete${entityName}`] = builder.mutation<any, { id: number }>({
            query: ({ id }) => ({
                url: `commands/execute/Delete${entityName}/`,
                method: "POST",
                body: { id },
            }),
            invalidatesTags: [{ type: tagName, id: "LIST" }],
        });
        return eps;
    },
});

export const {
    useGetIngredientCategoriesQuery,
    useGetIngredientCategoriesForDictQuery,
    useAddIngredientCategoryInitialQuery,
    useAddIngredientCategoryMutation,
    useUpdateIngredientCategoryInitialQuery,
    useUpdateIngredientCategoryMutation,
    useDeleteIngredientCategoryMutation,
} = ingredientCategoriesApi as any;
