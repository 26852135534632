import { IonButton, IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { add, pencilOutline, trashOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { useDeleteRecipeCategoryMutation, useGetRecipeCategoriesQuery } from "../../store/data/recipeCategoriesSlice";
import { useDeleteUnitMutation } from "../../store/data/unitsSlice";
import { ActionWithConfirmation } from "../../components/ActionWithConfirmation";
import { RedirectActionButton } from "../../components/previews/RedirectActionButton";

class ContainerProps {}

export const RecipeCategoryList: React.FC<ContainerProps> = () => {
    const recipeCategories = useGetRecipeCategoriesQuery("empty for now");
    const [remove, result] = useDeleteRecipeCategoryMutation();

    return (
        <DataLoadingWardenForQuery query={recipeCategories}>
            {() => (
                <>
                    <IonList className="menu-list-component">
                        {recipeCategories.data.result.map((x) => (
                            <IonItem key={x.id}>
                                <IonLabel>{x.name}</IonLabel>
                                <RedirectActionButton
                                    text="Edytuj"
                                    to={`/dictionaries/recipeCategories/update/${x.id}`}
                                    icon={pencilOutline}
                                />
                                <ActionWithConfirmation
                                    message={`Czy na pewno chcesz kategorię przepisu ${x.name}?`}
                                    header="Usuwanie kategorii przepisu"
                                    confirmedAction={() => remove({ id: x.id })}
                                />
                            </IonItem>
                        ))}
                    </IonList>
                    <IonFab
                        slot="fixed"
                        vertical="bottom"
                        horizontal="end"
                    >
                        <Link to="/dictionaries/recipeCategories/add">
                            <IonFabButton>
                                <IonIcon icon={add}></IonIcon>
                            </IonFabButton>
                        </Link>
                    </IonFab>
                </>
            )}
        </DataLoadingWardenForQuery>
    );
};
