import "./Badge.scss";
import classNames from "classnames";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

export const Badge: React.FC<{
    name: string;
    label?: string;
    className?: string;
    onClick?: () => void;
}> = ({ name, onClick, label, className }) => {
    const classes = classNames("badge-component", className, {
        "badge-component--clickable": !!onClick,
    });
    return (
        <div
            className={classes}
            onClick={onClick}
        >
            {label && <span>{label}: </span>}
            <p>{name}</p>
        </div>
    );
};
