import "./UnwrappedDatePicker.scss";
import { IonDatetimeButton, IonModal, IonDatetime, IonInput } from "@ionic/react";
import moment from "moment";
import { useRef } from "react";
import { Controller } from "react-hook-form";
import { useModalState } from "../hooks/useModal";
import { DATE_FORMAT } from "../consts";

export const UnwrappedDatePicker: React.FC<{
    name: string;
    control: any;
    label?: string;
}> = ({ name, control, label }) => {
    const [modalOpened, openModal, closeModal] = useModalState(name);

    const datetime = useRef<null | any>(null);
    return (
        <Controller
            key={name}
            name={name}
            control={control}
            render={({ field }) => {
                const value = moment(field.value).format(DATE_FORMAT);
                //IonDatetimeButton is not showed, but it is adding somehow some styles to modal window
                //so I have to add it to DOM
                return (
                    <div className="unwrapped-date-picker-component">
                        <IonInput
                            fill="outline"
                            label={label}
                            labelPlacement={label ? "stacked" : undefined}
                            value={value}
                            readonly={true}
                            onClick={() => openModal(name)}
                        ></IonInput>
                        <IonDatetimeButton
                            datetime={name}
                            style={{ display: "none" }}
                        ></IonDatetimeButton>
                        <IonModal
                            ref={datetime}
                            keepContentsMounted={true}
                            isOpen={modalOpened}
                            onDidDismiss={() => closeModal(name)}
                        >
                            <IonDatetime
                                id={name}
                                presentation="date"
                                locale="pl-PL"
                                firstDayOfWeek={1}
                                onIonChange={(e) => {
                                    field.onChange(e.detail.value);
                                }}
                                showDefaultButtons={true}
                                value={field.value}
                                cancelText="Anuluj"
                                doneText="Wybierz"
                            ></IonDatetime>
                        </IonModal>
                    </div>
                );
            }}
        />
    );
};
