import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { updateProfile } from "@firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDlnUILQCCBtZuk8w7MJ48_2M3nL-4jpAg",
    authDomain: "feed-me-322c1.firebaseapp.com",
    projectId: "feed-me-322c1",
    storageBucket: "feed-me-322c1.appspot.com",
    messagingSenderId: "532562860400",
    appId: "1:532562860400:web:6d2c4c71d7b9bbf32cd7b5",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const firestoreDb = getFirestore();
const feedbackCollection = collection(firestoreDb, "feedback");
const recipeFeedbackCollection = collection(firestoreDb, "recipeFeedback");
const usersDataCollection = collection(firestoreDb, "users_data");

export const addFeedback = async (feedback: any) => {
    try {
        await addDoc(feedbackCollection, feedback);
    } catch (err: any) {
        console.error(err);
    }
};

export const addRecipeFeedback = async (feedback: any) => {
    try {
        await addDoc(recipeFeedbackCollection, feedback);
    } catch (err: any) {
        console.error(err);
    }
};

export const addUserData = async (user: { displayName: string; emailAddress: string }) => {
    try {
        await addDoc(usersDataCollection, { tag: "Zarejestrowany", ...user });
    } catch (err: any) {
        console.error(err);
    }
};

const logInWithGoogle = async () => {
    try {
        await signInWithPopup(auth, googleProvider);
    } catch (err: any) {
        return mapCodeToMessage(err);
    }
};

const getToken = async () => {
    try {
        return await auth.currentUser?.getIdToken();
    } catch (err: any) {
        return mapCodeToMessage(err);
    }
};

const logInWithEmailAndPassword = async (email: string, password: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err: any) {
        return mapCodeToMessage(err);
    }
};

const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
    try {
        const response = await createUserWithEmailAndPassword(auth, email, password);
        await updateProfile(response.user, { displayName: name });
    } catch (err: any) {
        return mapCodeToMessage(err);
    }
};

const sendPasswordReset = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (err: any) {
        return mapCodeToMessage(err);
    }
};

const mapCodeToMessage = (error) => {
    switch (error.code) {
        case "auth/invalid-email":
            return "Niepoprawny adres email.";
        case "auth/user-disabled":
            return "Konto zostało zablokowane.";
        case "auth/user-not-found":
            return "Nie znaleziono użytkownika.";
        case "auth/wrong-password":
            return "Niepoprawne hasło.";
        default:
            return "Wystąpił błąd.";
    }
};

const logout = async () => {
    await signOut(auth);
};

export { auth, logInWithGoogle, logInWithEmailAndPassword, registerWithEmailAndPassword, sendPasswordReset, logout, getToken };
