import { Route, RouteComponentProps } from "react-router-dom";
import Page from "../Page";
import { MenuList } from "./MenuList";
import { MenuPublicPreview, MenuPreviewButtons } from "./MenuPublicPreview";
import { RecipePreviewPublicPage } from "./RecipePreviewPublicPage";
import { ShoppingList } from "./ShoppingList";

export const PublicRoutes = (
    <>
        <Route
            path="/menus"
            exact={true}
            key="page-public-menus"
            component={(props: RouteComponentProps) => (
                <Page
                    link={`/menus`}
                    name="Lista jadłospisów"
                    {...props}
                >
                    <MenuList />
                </Page>
            )}
        />
        <Route
            path="/menus/:id"
            exact={true}
            key="page-public-menu"
            component={(props: RouteComponentProps<{ id: string }>) => (
                <Page
                    name="Podgląd jadłospisu"
                    link={`/menus/${props.match.params.id}`}
                    rightButtons={<MenuPreviewButtons {...props} />}
                    {...props}
                >
                    <MenuPublicPreview />
                </Page>
            )}
        />
        <Route
            path="/menus/:menuId/recipe/:id"
            exact={true}
            key="page-public-recipe-menu"
            component={(props: RouteComponentProps<{ id: string; menuId: string }>) => (
                <Page
                    link={`/menus/${props.match.params.menuId}/recipe/${props.match.params.id}`}
                    name="Podgląd przepisu"
                    {...props}
                >
                    <RecipePreviewPublicPage />
                </Page>
            )}
        />
        <Route
            path="/menus/:id/shopping-list"
            exact={true}
            key="page-public-shopping-list"
            component={(props: RouteComponentProps<{ id: string }>) => (
                <Page
                    link={`/menus/${props.match.params.id}/shopping-list`}
                    name="Lista zakupów"
                    {...props}
                >
                    <ShoppingList />
                </Page>
            )}
        />
    </>
);
