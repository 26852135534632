import "./RecipePreview.scss";
import { IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { Chip } from "../../components/Chip";
import { environments } from "../../environments/environment";
import { SomethingWentWrong } from "../SomethingWentWrong";
import { SetDocumentTitle } from "../SetDocumentTitle";
import { TextWithNewLines } from "../TextWithNewLines";
import { LazyImage } from "../LazyImage";
import { starHalf, wifiOutline } from "ionicons/icons";
import { Badge } from "../Badge";
import { useEffect, useState } from "react";
import { RoundAmount } from "../RoundAmount";
import { ComplexityLabel } from "../ComplexityLabel";
import Moment from "react-moment";

interface ContainerProps {
    recipe: {
        name: string;
        prepareTime: number;
        complexity: number;
        cookTime: number;
        overallTime: number;
        numberOfEaters: number;
        mainImageId: string;
        imagesIds: string[];
        description: string;
        source: string;
        allIngredients: { name: string; amount: number; unit: string; type: "Ingredient" | "Group" }[];
        categories: { name: string }[];
        tags: { name: string }[];
        createdDate: Date;
        author: { name: string }
    };
    numberOfEaters: number;
}

export const RecipePreview: React.FC<ContainerProps> = ({ recipe, numberOfEaters }) => {
    const [selectedImage, setSelectedImage] = useState<string>(recipe.mainImageId);
    const [selectedNumberOfEaters, setSelectedNumberOfEaters] = useState<string>(
        numberOfEaters > 0 ? numberOfEaters.toString() : recipe.numberOfEaters.toString(),
    );

    const [amountMultiplier, setAmountMultiplier] = useState<number>(
        (numberOfEaters > 0 ? numberOfEaters : recipe.numberOfEaters) / recipe.numberOfEaters,
    );

    useEffect(() => {
        const numberOfEaters = parseFloat(selectedNumberOfEaters.replace(",", "."));
        setAmountMultiplier((numberOfEaters > 0 ? numberOfEaters : recipe.numberOfEaters) / recipe.numberOfEaters);
    }, [selectedNumberOfEaters]);

    if (!recipe) return <SomethingWentWrong text="Coś poszło nie tak :(" />;

    document.title = recipe.name;

    let badges = recipe.categories?.map((c) => <Badge name={c.name}></Badge>) || [];
    badges = badges.concat(recipe.tags?.map((t) => <Badge name={t.name}></Badge>) || []);
    return (
        <div className="recipe-preview-component">
            <SetDocumentTitle title={recipe.name} />
            <div className="img-wrapper">
                <LazyImage src={`${environments.FILE_URL}/images/${selectedImage}`} />
                {recipe.imagesIds?.length > 1 && (
                    <div className="thumbnails">
                        {recipe.imagesIds.map((i) => (
                            <LazyImage
                                src={`${environments.FILE_URL}/images/${i}/small`}
                                onClick={() => setSelectedImage(i)}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className="chips">
                <Chip
                    label="porcje"
                    value={`${recipe.numberOfEaters} os.`}
                />
                <Chip
                    label="trudność"
                    value={<ComplexityLabel complexity={recipe.complexity} />}
                />
                <Chip
                    label="czas"
                    className="full-width-chip"
                    value={`${recipe.prepareTime} + ${recipe.cookTime} = ${recipe.overallTime} min`}
                />
                {badges.length > 0 && (
                    <Chip
                        label=""
                        className="full-width-chip"
                        value={<>{badges.map((b) => b)}</>}
                    />
                )}
                <Chip
                    label="źródło"
                    className="full-width-chip"
                    value={recipe.source}
                />
                <Chip
                    className="full-width-chip"
                    valueStyle="normal"
                    value={<>{recipe.author?.name} @ <Moment fromNow={true}>{recipe.createdDate}</Moment></>}
                />
            </div>
            <div className="flex-break" />
            <div className="recipe">
                <h3 className="title">{recipe.name}</h3>
                <div className="description">
                    <TextWithNewLines text={recipe.description} />
                </div>
            </div>
            <div className="ingredients">
                <IonList>
                    <IonInput
                        label="Liczba porcji"
                        labelPlacement="stacked"
                        fill="outline"
                        placeholder={recipe.numberOfEaters.toString()}
                        onIonInput={(e) => {
                            const regExp = /[^0-9.,]/g;
                            e.detail.value = e.detail.value?.replace(regExp, "");
                            e.target.value = (e.target.value as string)?.replace(regExp, "");
                            setSelectedNumberOfEaters((e.currentTarget as any).value);
                        }}
                        type="text"
                        inputMode="decimal"
                        lang="pl"
                        value={selectedNumberOfEaters}
                    />
                    {recipe.allIngredients.map((i) =>
                        i.type === "Ingredient" ? (
                            <IonItem key={i.name}>
                                <IonLabel>
                                    {i.name} <RoundAmount amount={i.amount * amountMultiplier} /> {i.unit}
                                </IonLabel>
                            </IonItem>
                        ) : (
                            <IonListHeader>
                                <IonLabel>{i.name}</IonLabel>
                            </IonListHeader>
                        ),
                    )}
                </IonList>
            </div>
        </div>
    );
};
