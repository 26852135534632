import { Route, RouteComponentProps } from "react-router-dom";
import { RouteGuard } from "../../components/RouteGuard";
import Page from "../Page";
import { MenuAddForm } from "./MenuAddForm";
import { MenuPreviewPage, MenuPreviewPageButtons } from "./MenuPreviewPage";
import { MenusList } from "./MenusList";
import { MenuUpdateForm, MenuUpdatePageButtons } from "./MenuUpdateForm";
import { ShoppingListPage } from "./ShoppingListPage";

export const MenuPaths = {
    menus: "/user/menus",
    menuAdd: "/user/menus/add",
    menuUpdate: "/user/menus/update/:id",
    menuPreview: "/user/menus/preview/:id",
    shoppingList: "/user/menus/shopping-list/:id",
};

export const MenusRoutes = (
    <>
        <Route
            path={MenuPaths.menus}
            key={MenuPaths.menus}
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={MenuPaths.menus}
                >
                    <Page
                        link={`/user/menus`}
                        name="Jadłospisy"
                        {...props}
                    >
                        <MenusList />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={MenuPaths.menuAdd}
            key={MenuPaths.menuAdd}
            exact={true}
            component={(props: RouteComponentProps) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={MenuPaths.menuAdd}
                >
                    <Page
                        link={MenuPaths.menuAdd}
                        name="Dodaj jadłospis"
                        {...props}
                    >
                        <MenuAddForm />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={MenuPaths.menuUpdate}
            key={MenuPaths.menuUpdate}
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/user/menus/update/${props.match.params.id}`}
                >
                    <Page
                        link={`/user/menus/update/${props.match.params.id}`}
                        name="Zmień jadłospis"
                        {...props}
                        rightButtons={<MenuUpdatePageButtons {...props} />}
                    >
                        <MenuUpdateForm {...props} />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={MenuPaths.menuPreview}
            key={MenuPaths.menuPreview}
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/user/menus/preview/${props.match.params.id}`}
                >
                    <Page
                        link={`/user/menus/preview/${props.match.params.id}`}
                        name="Podgląd jadłospisu"
                        {...props}
                        rightButtons={<MenuPreviewPageButtons {...props} />}
                    >
                        <MenuPreviewPage {...props} />
                    </Page>
                </RouteGuard>
            )}
        />
        <Route
            path={MenuPaths.shoppingList}
            key={MenuPaths.shoppingList}
            exact={true}
            component={(props: RouteComponentProps<{ id: string }>) => (
                <RouteGuard
                    roles={["user", "moderator", "admin"]}
                    link={`/user/menus/shopping-list/${props.match.params.id}`}
                >
                    <Page
                        link={`/user/menus/shopping-list/${props.match.params.id}`}
                        name="Lista zakupów"
                        {...props}
                    >
                        <ShoppingListPage {...props} />
                    </Page>
                </RouteGuard>
            )}
        />
    </>
);
