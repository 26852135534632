import { IonCheckbox } from "@ionic/react";
import "./CheckboxList.scss";

export const CheckboxList = ({ items, onChange, value }) => {
    value = value || [];
    const toggleItem = (id) => {
        const newSelectedItems = value.includes(id) ? value.filter((i) => i !== id) : [...value, id];

        // reiterating to preserve ordering
        onChange(items.filter((i) => newSelectedItems.includes(i.id)).map((i) => i.id));
    };

    return (
        <div className="checkbox-list-component">
            {items.map((item) => (
                <IonCheckbox
                    key={item.id}
                    labelPlacement="end"
                    justify="start"
                    slot="start"
                    value={item.id}
                    onIonChange={(e) => toggleItem((e.currentTarget as any).value)}
                    checked={value.includes(item.id)}
                >
                    {item.name}
                </IonCheckbox>
            ))}
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
        </div>
    );
};
