export const RoundAmount = (props: { amount: number }) => {
    const multiplier = Math.pow(10, 1 - GetFirstSignificantIndex(props.amount));
    const x = (props.amount + Number.EPSILON) * multiplier;
    const stringedAmount = (Math.round(x) / multiplier).toLocaleString("pl-PL", {
        useGrouping: false,
    });
    return <>{stringedAmount}</>;
};

export const GetFirstSignificantIndex = (amount: number) => {
    return Math.floor(Math.log10(amount));
};
