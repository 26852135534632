import { RouteComponentProps, useParams } from "react-router-dom";
import { IonButton, IonIcon } from "@ionic/react";
import { cartOutline } from "ionicons/icons";
import { MenuPreview } from "../../components/previews/MenuPreview";
import { DataLoadingWardenForQuery } from "../../components/DataLoadingWardenForQuery";
import { useGetMenuByIdQuery } from "../../store/data/publicSlice";
import { SetDocumentTitle } from "../../components/SetDocumentTitle";

export const MenuPublicPreview: React.FC<{}> = () => {
    const id = (useParams() as any).id;

    const menu = useGetMenuByIdQuery({ id });

    return (
        <DataLoadingWardenForQuery query={menu}>
            {() => (
                <>
                    <SetDocumentTitle title={menu.data.name} />
                    <MenuPreview
                        {...menu.data}
                        linkFactory={(recipeId, menuId, numberOfEaters) =>
                            `/menus/${menuId}/recipe/${recipeId}?numberOfEaters=${numberOfEaters}`
                        }
                    />
                </>
            )}
        </DataLoadingWardenForQuery>
    );
};

export const MenuPreviewButtons: React.FC<RouteComponentProps<{ id: string }>> = ({
    match: {
        params: { id },
    },
}) => {
    return (
        <IonButton routerLink={`/menus/${id}/shopping-list`}>
            <IonIcon
                icon={cartOutline}
                title="Lista zakupów"
                color="dark"
            ></IonIcon>
            Lista zakupów
        </IonButton>
    );
};
