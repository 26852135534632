import { useForm } from "react-hook-form";
import { IonButton, IonList } from "@ionic/react";
import { ControlledInput } from "../ControlledInput";

export const NameOnlyForm = ({ data, submitForm, errors }) => {
    const { register, handleSubmit } = useForm({ defaultValues: data });

    return (
        <form
            noValidate={true}
            onSubmit={handleSubmit(submitForm)}
        >
            <IonList className="menu-list-component">
                <ControlledInput
                    name="name"
                    label="Nazwa"
                    register={register}
                    errors={errors}
                />
                <IonButton type="submit">Zapisz</IonButton>
            </IonList>
        </form>
    );
};
