import { createListenerMiddleware, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authSlice } from "./authSlice";

export const toastSlice = createSlice({
    name: "toast",
    initialState: {
        isOpen: false,
        message: "",
    },
    reducers: {
        openToast: (state, action: PayloadAction<string>) => {
            state.isOpen = true;
            state.message = action.payload;
        },
        destroyToast: (state) => {
            state.isOpen = false;
            state.message = "";
        },
    },
});

export const { openToast, destroyToast } = toastSlice.actions;

export const selectToast: (state: { toast: { isOpen: boolean; message: string } }) => {
    isOpen: boolean;
    message: string;
} = (state) => state.toast;

export const toastListener = createListenerMiddleware();
toastListener.startListening({
    matcher: ((action) => action.type.endsWith("/executeMutation/fulfilled")) as any,
    effect: async (action, listenerApi) => {
        if ((action as any).payload?.status === "Ok")
            if ((action as any).payload?.message)
                listenerApi.dispatch(toastSlice.actions.openToast((action as any).payload?.message));
            else listenerApi.dispatch(toastSlice.actions.openToast("Zapisano pomyślnie"));
        else if ((action as any).payload?.status !== "OkWithNoMessage")
            listenerApi.dispatch(toastSlice.actions.openToast("Coś poszło nie tak..."));
    },
});
toastListener.startListening({
    matcher: ((action) => action.type.endsWith("/executeMutation/rejected")) as any,
    effect: async (action, listenerApi) => {
        if ((action as any).payload?.status === 400)
            listenerApi.dispatch(toastSlice.actions.openToast("Sprawdź poprawność wprowadzonych danych"));
    },
});

export const toastReducer = toastSlice.reducer;
