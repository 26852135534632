import "./RecipeCard.scss";
import { IonButton, IonCard, IonCardTitle, IonIcon, useIonRouter } from "@ionic/react";
import { Chip } from "./Chip";
import { environments } from "../environments/environment";
import { LazyImage } from "./LazyImage";
import { RecipeStatusIcon } from "./RecipeStatusIcon";
import { heart, heartOutline } from "ionicons/icons";
import classNames from "classnames";
import { useToggleLikeMutation } from "../store/data/recipeSlice";
import { useState } from "react";

interface ContainerProps {
    id: number;
    name: string;
    imageUrl: string;
    routerLink: string;
    onChange?: (recipeId: number) => void;
    parentName: string;
    status: string;
    chips: { label: string; value: string }[];
    isLiked: boolean;
}

export const RecipeCard: React.FC<ContainerProps> = ({
    id,
    name,
    imageUrl,
    routerLink,
    parentName,
    status,
    isLiked,
    chips,
    onChange,
}) => {
    const [cachedIsLiked, setCachedIsLiked] = useState<boolean>(isLiked);
    const [toggleLike, result] = useToggleLikeMutation();
    const router = useIonRouter();
    const likeClasses = classNames("like-button", { "like-button-selected": cachedIsLiked });
    const likeClicked = (e) => {
        e.stopPropagation();

        toggleLike({ recipeId: id, like: !cachedIsLiked });
        setCachedIsLiked(!cachedIsLiked);
    };
    return (
        <div className="recipe-card-component">
            <IonCard onClick={onChange ? () => onChange!(id) : () => router.push(routerLink)}>
                <div className="container">
                    {status && (
                        <div className="status-container">
                            <RecipeStatusIcon status={status} />
                        </div>
                    )}
                    <div className="img-wrapper">
                        <div className={likeClasses}>
                            <IonButton
                                fill="clear"
                                onClick={likeClicked}
                            >
                                {!cachedIsLiked && <IonIcon icon={heartOutline} />}
                                {cachedIsLiked && <IonIcon icon={heart} />}
                            </IonButton>
                        </div>
                        <LazyImage src={`${environments.FILE_URL}/images/${imageUrl}/small`} />
                    </div>
                    <div className="chips">
                        {chips.map((chip, index) => (
                            <Chip
                                key={index}
                                label={chip.label}
                                value={chip.value}
                            />
                        ))}
                    </div>
                    <IonCardTitle>{name}</IonCardTitle>
                    {parentName && <div className="parent">{parentName}</div>}
                </div>
            </IonCard>
        </div>
    );
};
